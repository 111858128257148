import React from 'react'

const IconViewDetal = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.00022 0C10.7107 0 12.8833 2.35372 12.8833 5.22944C12.8833 6.91321 12.1385 8.41802 10.977 9.37621C13.7127 10.1404 15.4138 12.0778 15.9878 15.1387C16.0664 15.5583 15.7696 15.9482 15.3569 15.9952L15.2729 16H0.727494C0.272121 16 -0.0712423 15.5863 0.0126777 15.1387C0.5866 12.0778 2.28772 10.1404 5.02334 9.37607C3.86195 8.41803 3.1171 6.91321 3.1171 5.22944C3.1171 2.35372 5.28977 0 8.00022 0ZM7.99973 10.4588C4.66295 10.4588 2.62743 11.6893 1.7497 14.2272L1.68604 14.4199L1.64773 14.5454H14.351L14.3134 14.4199C13.5131 11.8788 11.6129 10.5852 8.47959 10.4676L8.23613 10.4609L7.99973 10.4588ZM7.99959 1.45455C6.11962 1.45455 4.57102 3.1322 4.57102 5.22944C4.57102 7.32668 6.11962 9.00433 7.99959 9.00433C9.87956 9.00433 11.4282 7.32668 11.4282 5.22944C11.4282 3.1322 9.87956 1.45455 7.99959 1.45455Z"
                fill="black"
            />
        </svg>
    )
}

export default IconViewDetal
