export const CustomerPermissions = {
  ALL: [
    "customer_management.list_customers",
    "customer_management.view_customer",
    "customer_management.list_customer_addresses",
    "customer_management.list_customer_orders",
    "customer_management.edit_customer",
  ],
  LIST_CUSTOMERS: "customer_management.list_customers",
  VIEW_CUSTOMER: "customer_management.view_customer",
  LIST_CUSTOMER_ADDRESSES: "customer_management.list_customer_addresses",
  LIST_CUSTOMER_ORDERS: "customer_management.list_customer_orders",
  EDIT_CUSTOMER: "customer_management.edit_customer",
};

export const ProductPermissions = {
  ALL: [
    "products.create_product",
    "products.list_products",
    "products.edit_product",
    "products.view_product",
    "products.delete_product",
    "products.disable_product",
    "products.lock_product",
  ],
  CREATE_PRODUCT: "products.create_product",
  LIST_PRODUCTS: "products.list_products",
  EDIT_PRODUCT: "products.edit_product",
  VIEW_PRODUCT: "products.view_product",
  DELETE_PRODUCT: "products.delete_product",
  DISABLE_PRODUCT: "products.disable_product",
  LOCK_PRODUCT: "products.lock_product",
};

export const ProductCatePermissions = {
  ALL: [
    "product_categories.create_category",
    "product_categories.edit_category",
    "product_categories.list_categories",
  ],
  CREATE_CATEGORY: "product_categories.create_category",
  EDIT_CATEGORY: "product_categories.edit_category",
  LIST_CATEGORIES: "product_categories.list_categories",
};

export const CSticketPermissions = {
  ALL: [
    "cs_tickets.list_cs_tickets",
    "cs_tickets.view_cs_ticket",
    "cs_tickets.comment_cs_ticket",
    "cs_tickets.list_cs_ticket_comments",
    "cs_tickets.edit_cs_ticket",
    "cs_tickets.create_cs_ticket",
  ],
  LIST_CS_TICKETS: "cs_tickets.list_cs_tickets",
  VIEW_CS_TICKET: "cs_tickets.view_cs_ticket",
  COMMENT_CS_TICKET: "cs_tickets.comment_cs_ticket",
  LIST_CS_TICKET_COMMENTS: "cs_tickets.list_cs_ticket_comments",
  EDIT_CS_TICKET: "cs_tickets.edit_cs_ticket",
  CREATE_CS_TICKET: "cs_tickets.create_cs_ticket",
};

export const OrderPermissions = {
  ALL: [
    "orders.list_orders",
    "orders.view_order",
    "orders.edit_order",
    "orders.export_order",
    "cancels.create_cancel",
    "cancels.approve_cancel",
    "returns.approve_return",
    "returns.reject_return",
    "refunds.view_refund_bank_account",
    "refunds.request_refund_bank_account",
    "refunds.approve_refund",
    "refunds.edit_refund",
    "complaints.create_complaint",
    "complaints.process_complaint",
    "complaints.request_evidences",
    "complaints.close_complaint",
    "complaints.comment_complaint",
    "complaints.list_complaint_comment",
  ],
  LIST_ORDERS: "orders.list_orders",
  VIEW_ORDER: "orders.view_order",
  EDIT_ORDER: "orders.edit_order",
  EXPORT_ORDER: "orders.export_order",
  CREATE_CANCEL: "cancels.create_cancel",
  APPROVE_CANCEL: "cancels.approve_cancel",
  APPROVE_RETURN: "returns.approve_return",
  REJECT_RETURN: "returns.reject_return",
  VIEW_REFUND_BANK_ACCOUNT: "refunds.view_refund_bank_account",
  REQUEST_REFUND_BANK_ACCOUNT: "refunds.request_refund_bank_account",
  APPROVE_REFUND: "refunds.approve_refund",
  EDIT_REFUND: "refunds.edit_refund",
  CREATE_COMPLAINT: "complaints.create_complaint",
  PROCESS_COMPLAINT: "complaints.process_complaint",
  REQUEST_EVIDENCES: "complaints.request_evidences",
  CLOSE_COMPLAINT: "complaints.close_complaint",
  COMMENT_COMPLAINT: "complaints.comment_complaint",
  LIST_COMPLAINT_COMMENT: "complaints.list_complaint_comment",
};

export const CouponPermissions = {
  ALL: [
    "coupons.create_coupon",
    "coupons.view_coupon",
    "coupons.edit_coupon",
    "coupons.list_coupons",
    "coupons.disable_coupon",
    "coupons.approve_coupon",
  ],
  CREATE_COUPON: "coupons.create_coupon",
  VIEW_COUPON: "coupons.view_coupon",
  EDIT_COUPON: "coupons.edit_coupon",
  LIST_COUPONS: "coupons.list_coupons",
  DISABLE_COUPON: "coupons.disable_coupon",
  APPROVE_COUPON: "coupons.approve_coupon",
};

export const DisountPermissions = {
  ALL: [
    "discounts.create_discount",
    "discounts.view_discount",
    "discounts.edit_discount",
    "discounts.list_discounts",
    "discounts.disable_discount",
  ],
  CREATE_DISCOUNT: "discounts.create_discount",
  VIEW_DISCOUNT: "discounts.view_discount",
  EDIT_DISCOUNT: "discounts.edit_discount",
  LIST_DISCOUNTS: "discounts.list_discounts",
  DISABLE_DISCOUNT: "discounts.disable_discount",
};

export const TaxPermissions = {
  ALL: [
    "taxes.create_tax",
    "taxes.edit_tax",
    "taxes.disable_tax",
    "taxes.list_taxes",
  ],
  CREATE_TAX: "taxes.create_tax",
  EDIT_TAX: "taxes.edit_tax",
  DISABLE_TAX: "taxes.disable_tax",
  LIST_TAXES: "taxes.list_taxes",
};

export const TenantPermissions = {
  ALL: [
    "tenants.create_tenant",
    "tenants.create_accountant_tax_report",
    "tenants.create_tenant_tax_report",
    "tenants.view_tenant_tax_report",
    "tenants.create_tenant_tos",
    "tenants.view_tenant_tos",
    "tenants.manage_tenant_color",
    "tenants.manage_tenant_banner",
    "tenants.view_warehouse_address",
    "tenants.lock_tenant",
    "tenants.edit_tenant",
    "tenants.list_tenants",
    "tenants.view_tenant_detail",
    "tenants.view_tenant_staffs",
    "tenants.view_tenant_pos",
    "tenants.view_tenant_report",
    "tenants.list_tenant_transactions",
    "tenants.view_tenant_transactions_report",
    "user_accounts.manage_tenant_user",
    "tenants.create_tenant_delivery",
    "tenants.view_tenant_delivery",
    "setting.tenants.view",
    "setting.tenants.crud",
    "staff_recommend"
  ],
  CREATE_TENANT: "tenants.create_tenant",
  CREATE_ACCOUNTANT_TAX_REPORT: "tenants.create_accountant_tax_report",
  CREATE_TENANT_TAX_REPORT: "tenants.create_tenant_tax_report",
  VIEW_TENANT_TAX_REPORT: "tenants.view_tenant_tax_report",
  CREATE_TENANT_TOS: "tenants.create_tenant_tos",
  VIEW_TENANT_TOS: "tenants.view_tenant_tos",
  CREATE_TENANT_DELIVERY: "tenants.create_tenant_delivery",
  VIEW_TENANT_DELIVERY: "tenants.view_tenant_delivery",
  MANAGE_TENANT_COLOR: "tenants.manage_tenant_color",
  MANAGE_TENANT_BANNER: "tenants.manage_tenant_banner",
  VIEW_WAREHOUSE_ADDRESS: "tenants.view_warehouse_address",
  LOCK_TENANT: "tenants.lock_tenant",
  EDIT_TENANT: "tenants.edit_tenant",
  LIST_TENANTS: "tenants.list_tenants",
  VIEW_TENANT_DETAIL: "tenants.view_tenant_detail",
  VIEW_TENANT_STAFFS: "tenants.view_tenant_staffs",
  VIEW_TENANT_POS: "tenants.view_tenant_pos",
  VIEW_TENANT_REPORT: "tenants.view_tenant_report",
  LIST_TENANT_TRANSACTIONS: "tenants.list_tenant_transactions",
  VIEW_TENANT_TRANSACTIONS_REPORT: "tenants.view_tenant_transactions_report",
  MANAGE_ACCOUNT: "user_accounts.manage_tenant_user",
  VIEW_CREATE_UPDATE_ACTIVE_TIME:"tenants.manage_tenant_active_time"
};

export const BrandPermissions = {
  ALL: [
    "brands.list_brands",
    "brands.create_brand",
    "brands.update_brand",
    "user_accounts.manage_brand_user",
  ],
  LIST_BRANDS: "brands.list_brands",
  CREATE_BRAND: "brands.create_brand",
  UPDATE_BRAND: "brands.update_brand",
  MANAGE_ACCOUNT: "user_accounts.manage_brand_user",
};

export const MallPermissions = {
  ALL: [
    "malls.list_malls",
    "malls.create_mall",
    "malls.update_mall",
    "malls.manage_mall_banner",
    "user_accounts.manage_mall_user",
  ],
  LIST_MALLS: "malls.list_malls",
  CREATE_MALL: "malls.create_mall",
  UPDATE_MALL: "malls.update_mall",
  BANNER_MANAGE: "malls.manage_mall_banner",
  MANAGE_ACCOUNT: "user_accounts.manage_mall_user",
};

export const PlatformPermissions = {
  ALL: ["setting.manage_platform_banner", "user_accounts.manage_platform_user"],
  MANAGE_BANNER: "setting.manage_platform_banner",
  MANAGE_ACCOUNT: "user_accounts.manage_platform_user",
};

export const NotificationPermissions = {
  ALL: ["notifications.list_notifications"],
  LIST_NOTIFICATIONS: "notifications.list_notifications",
};

export const StaffRecommendPermissions = {
  ALL: [
    "staff_recommends.create_review",
    "staff_recommends.list_reviews",
    "staff_recommends.view_review",
    "staff_recommends.approve_review",
  ],
  CREATE_REVIEW: "staff_recommends.create_review",
  LIST_REVIEW: "staff_recommends.list_reviews",
  VIEW_REVIEW: "staff_recommends.view_review",
  APPROVE_REVIEW: "staff_recommends.approve_review",
};

export const SubadminRewardsPermissions = {
  ALL: [
    "rewards.customers.view",
    "rewards.customers.crud",
    "rewards.customers.export",
  ],
  CUSTOMERS_VIEW: "rewards.customers.view",
  CUSTOMERS_CRUD: "rewards.customers.crud",
  CUSTOMERS_EXPORT: "rewards.customers.export",
}

export const SubadminTenantPermissions = {
  ALL: [
    "setting.tenants.view",
    "setting.tenants.crud",
    "staff_recommend"
  ],
  TENANTS_VIEW: "setting.tenants.view",
  TENANTS_CRUD: "setting.tenants.crud",
  STAFF_RECOMMEND: "staff_recommend"
}