import React from 'react';
// import PropTypes from 'prop-types';

const IconLock = ({
                   width = 24,
                   height = 24,
                   viewBox = "0 0 24 24",
                   fillSvg = "none",
                   fillPath = "#828282",
                   onClick = () => {
                   }
               }) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.7144 7.14497H12.5001V3.14497C12.5001 1.88247 11.4769 0.859253 10.2144 0.859253H5.78578C4.52328 0.859253 3.50007 1.88247 3.50007 3.14497V7.14497H2.28578C1.96971 7.14497 1.71436 7.40032 1.71436 7.7164V14.5735C1.71436 14.8896 1.96971 15.145 2.28578 15.145H13.7144C14.0304 15.145 14.2858 14.8896 14.2858 14.5735V7.7164C14.2858 7.40032 14.0304 7.14497 13.7144 7.14497ZM4.78578 3.14497C4.78578 2.59318 5.234 2.14497 5.78578 2.14497H10.2144C10.7661 2.14497 11.2144 2.59318 11.2144 3.14497V7.14497H4.78578V3.14497ZM13.0001 13.8593H3.00007V8.43068H13.0001V13.8593ZM7.50007 11.3771V12.3235C7.50007 12.4021 7.56436 12.4664 7.64293 12.4664H8.35721C8.43578 12.4664 8.50007 12.4021 8.50007 12.3235V11.3771C8.64748 11.2713 8.7575 11.1214 8.8143 10.9491C8.87111 10.7767 8.87176 10.5908 8.81618 10.4181C8.76059 10.2453 8.65162 10.0947 8.50497 9.98781C8.35831 9.88095 8.18153 9.82337 8.00007 9.82337C7.81861 9.82337 7.64183 9.88095 7.49517 9.98781C7.34852 10.0947 7.23955 10.2453 7.18397 10.4181C7.12838 10.5908 7.12903 10.7767 7.18584 10.9491C7.24264 11.1214 7.35267 11.2713 7.50007 11.3771Z" fill="#2D2D2D"/>
        </svg>
    );
};

IconLock.propTypes = {};

export default IconLock;
