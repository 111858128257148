import React from 'react'

const IconShare = ({
    width = 24,
    height = 24,
    viewBox = '0 0 24 24',
    fillSvg = 'none',
    fillPath = '#828282',
    onClick = () => {},
}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M14.6667 7.9966L8.58816 1.3335V5.32479H7.88205C6.13289 5.32479 4.48835 6.00574 3.25145 7.24221C2.01456 8.47868 1.33337 10.1227 1.33337 11.8713V14.6668L2.49995 13.3891C4.06045 11.68 6.27556 10.6913 8.58816 10.6688V14.6598L14.6667 7.9966ZM2.11499 12.6582V11.8713C2.11499 10.3314 2.71487 8.88355 3.80411 7.79468C4.89335 6.70581 6.3416 6.10614 7.88205 6.10614H9.36968V3.34979L13.609 7.9966L9.36968 12.6435V9.88717H8.66845C6.20138 9.88717 3.82894 10.8937 2.11499 12.6582Z"
                fill="#2D2D2D"
                stroke="#828282"
                strokeWidth="0.5"
            />
        </svg>
    )
}

export default IconShare
