import React, { memo, useEffect, useRef } from "react";
import styles from "./styles.module.scss";
import OrderedList from "../orderedList";
import { useCustomer } from "components/customer/context/customer";
import useDebounce from "hooks/useDebounce";
import { useParams } from "react-router-dom";
import { handleCallApiOrderedList } from "components/membership/ListOrder";

function OrderedAll() {
  return (
    <div className={styles.wrapper_ordered_all}>
      <SearchBar />
      <OrderedList />
    </div>
  );
}

export default memo(OrderedAll);

export function SearchBar() {
  const {
    setKeyword,
    keyword,
    setHasMore,
    setPage,
    setLoading,
    setOrderedList,
    getOrderedList,
  } = useCustomer();
  const { id } = useParams();
  const debouncedValue = useDebounce(keyword, 500);

  const firstSearch = useRef(true);

  useEffect(() => {
    if (id && (debouncedValue || !firstSearch.current)) {
      if (firstSearch.current) {
        firstSearch.current = false;
      }

      setLoading(true);
      handleCallApiOrderedList(
        id,
        debouncedValue,
        1,
        setHasMore,
        setOrderedList,
        getOrderedList
      );
      setPage(1);
    }
  }, [debouncedValue]);

  const onSearch = (e) => {
    setKeyword(e.target.value);
  };

  return (
    <div className={styles.wrapper_search}>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.41431 3.38286C5.74471 1.09373 9.52305 1.09373 11.8535 3.38286C14.1051 5.59467 14.1812 9.1343 12.0816 11.4358L14.5697 13.8801C14.6222 13.9317 14.623 14.0161 14.5714 14.0686C14.5708 14.0692 14.5702 14.0698 14.5697 14.0703L14.0567 14.5742C14.0048 14.6252 13.9217 14.6252 13.8698 14.5742L11.359 12.1076C9.01602 13.9511 5.58629 13.806 3.41431 11.6725C1.0839 9.3834 1.0839 5.67199 3.41431 3.38286ZM4.11757 4.07367C2.17557 5.98127 2.17557 9.07411 4.11757 10.9817C6.05958 12.8893 9.20819 12.8893 11.1502 10.9817C13.0922 9.07411 13.0922 5.98127 11.1502 4.07367C9.20819 2.16606 6.05958 2.16606 4.11757 4.07367Z"
          fill="#CCCCCC"
        />
      </svg>
      <input
        value={keyword}
        onChange={onSearch}
        placeholder="Tìm kiếm theo tên Shop, ID đơn hàng hoặc Tên sản phẩm"
      />
    </div>
  );
}
