import { DownOutlined, UpOutlined } from '@ant-design/icons'
import { Button, Col, Row } from 'antd'
import moment from 'moment'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import FilterDropdownCheckbox from '../../../common/FilterDropdownCheckbox'
import FilterRangePicker from '../../../common/FilterRangePicker'
import FilterSlider from '../../../common/FilterSlider'
import { ROLE_ADMIN } from '../../../config/const'
import { getMallId, getRole } from '../../../config/function'
import useMalls from '../../../hooks/useMalls'
import useProvinceQuery from '../../../hooks/useProvinceQuery'

import './FilterCustomer.scss'

const FilterCustomer = ({ filters, onChangeFilter }) => {
    const { t } = useTranslation('member')
    const [openFilter, setOpenFilter] = useState(false)

    const { data: provinces } = useProvinceQuery()
    // eslint-disable-next-line no-unused-vars
    const [dates, setDates] = useState([])
    const onOpenFilter = () => {
        setOpenFilter(true)
    }

    const malls = useMalls(true)
    const role = getRole()
    const mallId = getMallId()
    let mallOptions = []
    if (ROLE_ADMIN === parseInt(role)) {
        mallOptions = malls ? [...malls] : []
    } else {
        mallOptions = malls?.filter((item) => item.value === mallId) || []
    }

    const genderOptions = [
        { value: 1, label: t('male') },
        { value: 0, label: t('female') },
        { value: 2, label: t('other') },
    ]

    const monthBirthOptions = []

    const eKYCOptions = [
        {value: 1, label: t('đã eKYC')}
    ]

    for (let i = 1; i <= 12; i++) {
        monthBirthOptions.push({ label: `${t('Tháng')} ${i}`, value: i })
    }

    const accountTypeOptions = [
        { value: 'email', label: t('email') },
        { value: 'facebook', label: t('Facebook') },
        { value: 'google', label: t('Google') },
    ]

    const provinceOptions =
        provinces?.map((province) => ({ label: province.name, value: province.province_id })) || null

    // District options
    let districtOptions = []
    if (Array.isArray(filters.province_id) && filters.province_id.length) {
        provinces?.forEach((province) => {
            if (filters.province_id.indexOf(province.province_id) !== -1) {
                districtOptions = [
                    ...districtOptions,
                    ...province.children?.map((district) => ({ label: district.name, value: district.district_id })),
                ]
            }
        })
    }

    // Ward option
    let wardOptions = []
    if (Array.isArray(filters.district_id) && filters.district_id.length) {
        let districts = []
        provinces?.forEach((province) => {
            if (
                filters.province_id.indexOf(province.province_id) !== -1 &&
                Array.isArray(province.children) &&
                province.children.length
            ) {
                districts = [...districts, ...province.children]
            }
        })

        districts?.forEach((district) => {
            if (filters.district_id.indexOf(district.district_id) !== -1) {
                wardOptions = [
                    ...wardOptions,
                    ...district.children?.map((ward) => ({ label: ward.name, value: ward.ward_id })),
                ]
            }
        })
    }

    const onCloseFilter = () => {
        setOpenFilter(false)
    }

    const markSliderAge = {
        0: `0 ${t('0tuổi')}`,
        60: `60 ${t('tuổi')}`,
    }

    const onChange = (fieldName) => (value) => {
        if (fieldName === 'age_ranger' && Array.isArray(value)) {
            onChangeFilter({ age_start: value[0] || 0, age_end: value[1] || 0 })
            return
        }

        if (fieldName === 'province_id') {
            onChangeFilter({ [fieldName]: value, district_id: [], ward_id: [] })
            return
        }

        if (fieldName === 'district_id') {
            onChangeFilter({ [fieldName]: value, ward_id: [] })
            return
        }

        onChangeFilter({ [fieldName]: Array.isArray(value) ? value.sort() : value })
    }

    let customer_filter_close = (
        <>
            <Col span={2}>
                <Button className="show-filter-btn" type="link" onClick={onOpenFilter}>
                    <span>
                        <DownOutlined className="icon-show" />
                    </span>
                    {t('Mở rộng')}
                </Button>
            </Col>
        </>
    )

    let customer_filter_open = (
        <>
            <Col span={4}>
                <FilterDropdownCheckbox
                    onChange={onChange('ward_id')}
                    className="filter-select"
                    buttonText={t('ward')}
                    options={wardOptions}
                    value={filters.ward_id}
                    virtual
                />
            </Col>
            <Col span={3}>
                <FilterSlider
                    buttonText={t('Độ tuổi')}
                    onChange={onChange('age_ranger')}
                    value={[filters.age_start || 0, filters.age_end || 0]}
                    sliderProps={{
                        marks: markSliderAge,
                        min: 0,
                        max: 60,
                    }}
                    overlayClassName="filter-slider-overlay"
                />
            </Col>
            <Col span={3}>
                <FilterDropdownCheckbox
                    onChange={onChange('month_birth')}
                    className="filter-select"
                    buttonText={t('Tháng sinh')}
                    options={monthBirthOptions}
                    value={filters.month_birth}
                />
            </Col>
            <Col span={3}>
                <FilterRangePicker
                    placeholder={t('Năm sinh')}
                    size="large"
                    picker="year"
                    onCalendarChange={(val) => setDates(val)}
                    onChange={(value) => {
                        if (value !== null) {
                            onChangeFilter({
                                year_birth_begin: value[0]?.format('YYYY') || '',
                                year_birth_end: value[1]?.format('YYYY') || '',
                            })
                        } else {
                            onChangeFilter({
                                year_birth_begin: '',
                                year_birth_end: '',
                            })
                        }
                    }}
                    value={
                        filters.year_birth_begin
                            ? [
                                  moment(filters.year_birth_begin, 'YYYY-MM-DD'),
                                  moment(filters.year_birth_end, 'YYYY-MM-DD'),
                              ]
                            : null
                    }
                />
            </Col>
            <Col span={3}>
                <FilterDropdownCheckbox
                    onChange={onChange('is_ekyc')}
                    className="filter-select"
                    buttonText={t('Trạng thái')}
                    options={eKYCOptions}
                    value={filters.is_ekyc}
                />
            </Col>
            <Col span={3}>
                <FilterDropdownCheckbox
                    onChange={onChange('account_type')}
                    className="filter-select"
                    buttonText={t('account_type')}
                    options={accountTypeOptions}
                    value={filters.account_type}
                />
            </Col>
            <Col span={4}>
                <FilterRangePicker
                    placeholder={t('Ngày đăng ký')}
                    size="large"
                    onCalendarChange={(val) => setDates(val)}
                    onChange={(value) => {
                        if (value !== null) {
                            onChangeFilter({
                                register_day_begin: value[0]?.format('YYYY-MM-DD') || '',
                                register_day_end: value[1]?.format('YYYY-MM-DD') || '',
                            })
                        } else {
                            onChangeFilter({
                                register_day_begin: '',
                                register_day_end: '',
                            })
                        }
                    }}
                    value={
                        filters.register_day_begin
                            ? [
                                  moment(filters.register_day_begin, 'YYYY-MM-DD'),
                                  moment(filters.register_day_end, 'YYYY-MM-DD'),
                              ]
                            : null
                    }
                />
            </Col>
            <Col span={2}>
                <Button className="show-filter-btn" type="link" onClick={onCloseFilter}>
                    <span>
                        <UpOutlined className="icon-show" />
                    </span>
                    {t('Thu gọn')}
                </Button>
            </Col>
        </>
    )

    return (
        <>
            <div className="customer-filter">
                <Row className="title-table-row" gutter={[0, 20]}>
                    <Col span={5}>
                        <FilterDropdownCheckbox
                            onChange={onChange('mall_id')}
                            options={mallOptions}
                            className="filter-select"
                            buttonText={t('TTTM hiện tại')}
                            value={filters.mall_id}
                            virtual
                        />
                    </Col>
                    <Col span={5}>
                        <FilterDropdownCheckbox
                            onChange={onChange('first_mall_id')}
                            options={mallOptions}
                            className="filter-select"
                            buttonText={t('TTTM đăng ký')}
                            value={filters.first_mall_id}
                            virtual
                        />
                    </Col>
                    <Col span={3}>
                        <FilterDropdownCheckbox
                            onChange={onChange('gender')}
                            className="filter-select"
                            buttonText={t('gender')}
                            value={filters.gender}
                            options={genderOptions}
                        />
                    </Col>
                    <Col span={4}>
                        <FilterDropdownCheckbox
                            onChange={onChange('province_id')}
                            options={provinceOptions}
                            className="filter-select"
                            buttonText={t('province')}
                            value={filters.province_id}
                            virtual
                        />
                    </Col>
                    <Col span={4}>
                        <FilterDropdownCheckbox
                            onChange={onChange('district_id')}
                            className="filter-select"
                            buttonText={t('district')}
                            options={districtOptions}
                            value={filters.district_id}
                            virtual
                        />
                    </Col>
                    {openFilter === false ? <>{customer_filter_close}</> : <>{customer_filter_open}</>}
                </Row>
            </div>
        </>
    )
}
export default FilterCustomer
