import React from 'react';

const IconLocation = ({
    width = 24,
    height = 24,
    viewBox = "0 0 24 24",
    fillSvg = "none",
    fillPath = "#828282",
    onClick = () => {
    }
}) => {
    return (
        <svg onClick={onClick} width={width} height={height} viewBox={viewBox} fill={fillSvg} xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M12.576 1.00171L12.7127 1.00181C18.164 1.07903 22.6374 5.1595 22.6374 10.1513C22.6374 13.6756 20.6785 16.7255 17.2771 19.6755C16.7033 20.1733 16.1129 20.6482 15.4383 21.1621L13.2457 22.7935C12.8867 23.0689 12.3874 23.0688 12.0285 22.7933L12.0587 22.8179C12.055 22.8234 11.1497 22.1728 10.5719 21.726C9.49812 20.8954 8.43387 19.9813 7.43598 18.996C4.57248 16.1687 2.78984 13.3181 2.64668 10.5131L2.63774 10.2231L2.63736 10.1513C2.63736 5.15965 7.11072 1.07923 12.576 1.00171ZM12.6354 3.00066L12.5902 3.00161C8.27452 3.0629 4.77694 6.17727 4.64143 9.92567L4.63763 10.2021C4.6611 12.4373 6.22116 14.986 8.84116 17.5728C9.77691 18.4968 10.7815 19.3596 11.7956 20.144L12.1945 20.4462L12.6224 20.762L13.8248 19.8741C14.6427 19.2623 15.3194 18.7261 15.9667 18.1647C18.973 15.5573 20.6374 12.9659 20.6374 10.1513C20.6374 6.37837 17.2244 3.19122 12.9556 3.00902L12.6354 3.00066ZM12.6374 5.99996C14.8465 5.99996 16.6374 7.79082 16.6374 9.99996C16.6374 12.2091 14.8465 14 12.6374 14C10.4282 14 8.63736 12.2091 8.63736 9.99996C8.63736 7.79082 10.4282 5.99996 12.6374 5.99996ZM12.6374 7.99996C11.5328 7.99996 10.6374 8.89539 10.6374 9.99996C10.6374 11.1045 11.5328 12 12.6374 12C13.7419 12 14.6374 11.1045 14.6374 9.99996C14.6374 8.89539 13.7419 7.99996 12.6374 7.99996Z" fill={fillPath} />
        </svg>
    );
};

IconLocation.propTypes = {};

export default IconLocation;