import React from 'react';

const IconVi = () => {
    return (
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M29.1425 9.99883C29.1408 9.99408 29.1394 9.99009 29.1377 9.98579C27.218 4.5729 22.28 0.590414 16.3472 0.0617676C18.6429 1.32034 19.3133 5.30783 19.9008 9.9989C20.302 13.2031 20.3046 16.7337 19.9084 19.942C19.3259 24.6584 18.6526 28.6741 16.3472 29.9379C22.291 29.4083 27.236 25.4121 29.1483 19.9846C29.1533 19.9702 29.1575 19.956 29.1627 19.942C29.7027 18.3942 29.9999 16.7321 29.9999 15.0003C29.9996 13.2458 29.6958 11.5632 29.1425 9.99883Z"
                fill="#C71513"/>
            <path
                d="M20.6729 9.99875C20.0858 5.30762 18.6429 1.32019 16.347 0.0616238C15.9029 0.0222935 15.4539 0 14.9997 0C8.47444 0 2.92526 4.16755 0.861846 9.98571C0.860199 9.98995 0.858743 9.994 0.857096 9.99875C0.303875 11.5632 0 13.2457 0 15.0001C0 16.7321 0.297225 18.394 0.837209 19.9418C0.842212 19.9558 0.846709 19.97 0.851649 19.9844C2.90696 25.8176 8.46373 29.9997 14.9996 29.9997C15.4539 29.9997 15.9029 29.9774 16.347 29.9376C18.6524 28.6739 20.098 24.6582 20.6804 19.9417C21.0767 16.7334 21.0741 13.203 20.6729 9.99875Z"
                fill="#ED3432"/>
            <path
                d="M14.8655 7.31226L16.6473 12.7965H22.4141L17.7486 16.1854L19.5306 21.6702L14.8655 18.2805L10.2002 21.6702L11.9822 16.1854L7.31689 12.7965H13.0836L14.8655 7.31226Z"
                fill="#FDCE0C"/>
        </svg>
    );
};

export default IconVi;