import {
  getNotificationsAPI,
  readAllNotificationsAPI,
  readNotificationAPI,
} from "components/notification/notifications.service";
import {
  ORDER_CANCEL_DETAIL,
  ORDER_COMPLAINT_DETAIL,
  ORDER_DETAIL,
  ORDER_REFUND_DETAIL,
  ORDER_RETURN_DETAIL,
  PRODUCT_DETAIL,
} from "config/path";
import { createContext, useContext, useState } from "react";

export const PER_PAGE = 10;
export const DEFAULT_PAGE = 1;

const NotificationContext = createContext({});

const ORDERED_TYPE = {
  NORMAL: "order_detail",
  RETURN: "return_request",
  CANCEL: "cancel_request",
  COMPLAINT: "complaint_request",
  REFUND: "refund_request",
};

const NotificationProvider = ({ children }) => {
  const [loadingNotification, setLoadingNotification] = useState(false);
  const [page, setPage] = useState(DEFAULT_PAGE);
  const [lastPage, setLastPage] = useState(DEFAULT_PAGE + 1);
  const [loadMoreLoading, setLoadMoreLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [unread_count, setUnreadCount] = useState(0);
  const [notifications, setNotifications] = useState([]);
  const [recentNotifications, setRecentNotifications] = useState([]);

  const updateNotificationByType = (type, data) => {
    switch (type) {
      case "recent":
        return setRecentNotifications(data);
      case "list":
        return setNotifications(data);
      default:
        setRecentNotifications(data);
        setNotifications(data);
    }
  };

  const getNotifications = async (type, page) => {
    if (loadingNotification) return;
    let pageInit = DEFAULT_PAGE;

    type === "list" && setLoadingNotification(true);

    if (type === "list") {
      pageInit = page || DEFAULT_PAGE;
    }

    return getNotificationsAPI({
      page: pageInit,
      per_page: PER_PAGE,
    })
      .then((res) => {
        updateNotificationByType(type, res.data);
        setUnreadCount(res.unread_count);
        res.last_page !== lastPage && setLastPage(res.last_page);
        res.total !== total && setTotal(res.total);
      })
      .finally(() => {
        setLoadingNotification(false);
      });
  };

  const getOrderDetailPath = (orderType, orderDetailId, requestOrderedId) => {
    switch (orderType) {
      case ORDERED_TYPE.RETURN:
        return {
          path: ORDER_RETURN_DETAIL,
          params: { id: orderDetailId, return_id: requestOrderedId },
        };
      case ORDERED_TYPE.CANCEL:
        return {
          path: ORDER_CANCEL_DETAIL,
          params: { id: orderDetailId, cancel_id: requestOrderedId },
        };
      case ORDERED_TYPE.COMPLAINT:
        return {
          path: ORDER_COMPLAINT_DETAIL,
          params: { id: orderDetailId, complaint_id: requestOrderedId },
        };
      case ORDERED_TYPE.REFUND:
        return {
          path: ORDER_REFUND_DETAIL,
          params: { id: orderDetailId, refundId: requestOrderedId },
        };
      default:
        return {
          path: ORDER_DETAIL,
          params: { id: orderDetailId },
        };
    }
  };

  const readNotification = async ({ notificationId }) => {
    setLoadingNotification(true);
    return readNotificationAPI(notificationId)
      .then((res) => res)
      .finally(() => {
        setLoadingNotification(false);
      });
  };

  const readAllNotification = async () => {
    readAllNotificationsAPI().then(() => {
      getNotifications("first-load");
      setPage(DEFAULT_PAGE);
    });
  };

  const isNotificationOfOrder = (source_type) => {
    return [
      ORDERED_TYPE.NORMAL,
      ORDERED_TYPE.CANCEL,
      ORDERED_TYPE.COMPLAINT,
      ORDERED_TYPE.REFUND,
      ORDERED_TYPE.RETURN,
    ].includes(source_type);
  };

  const isNotificationOfSpecialOrder = (source_type) => {
    return [
      ORDERED_TYPE.CANCEL,
      ORDERED_TYPE.COMPLAINT,
      ORDERED_TYPE.REFUND,
      ORDERED_TYPE.RETURN,
    ].includes(source_type);
  };

  const getUrlOfOrdered = ({ bodyDataNotification, bodyDataType }) => {
    let url = null;
    const sourceId = bodyDataNotification.source_id;

    if (isNotificationOfSpecialOrder(bodyDataType)) {
      const orderDetailId = bodyDataNotification.order_detail_id;
      url = getOrderDetailPath(bodyDataType, orderDetailId, sourceId);
    } else {
      url = getOrderDetailPath(bodyDataType, sourceId);
    }

    return url;
  };

  const getUrlOfProduct = ({ bodyDataNotification }) => {
    const sourceId = bodyDataNotification.source_id;

    return {
      path: PRODUCT_DETAIL,
      params: { id: sourceId, },
    };;
  };

  const updateNotifications = (notificationId) => {
    setNotifications((prev) => {
      return prev.map((item) => {
        if (item.id === notificationId && !item.is_read) {
          setUnreadCount((prev) => prev - 1);
          return {
            ...item,
            is_read: true,
          };
        }
        return item;
      });
    });

    setRecentNotifications((prev) => {
      return prev.map((item) => {
        if (item.id === notificationId && !item.is_read) {
          return {
            ...item,
            is_read: true,
          };
        }
        return item;
      });
    });
  };

  const handleViewDetailNotification = async (notification) => {
    let url;
    const notificationId = notification?.id;
    const bodyDataNotification = notification?.body_data;
    const bodyDataType = bodyDataNotification?.source_type;

    const readNotificationParams = {
      notificationId,
      type: bodyDataType,
    };

    // get notification url of order
    if (isNotificationOfOrder(bodyDataType)) {
      url = getUrlOfOrdered({ bodyDataNotification, bodyDataType });
    }
    // get notification url of product
    if (bodyDataType === 'product') {
      url = getUrlOfProduct({ bodyDataNotification });
    }

    const res = await readNotification(readNotificationParams);

    // update read status of notification
    updateNotifications(notificationId);

    return {
      url,
      res,
    };
  };

  return (
    <NotificationContext.Provider
      value={{
        setNotifications,
        setLoadingNotification,
        getNotifications,
        readNotification,
        setLoadMoreLoading,
        readAllNotification,
        setPage,
        notifications,
        loadingNotification,
        page,
        loadMoreLoading,
        lastPage,
        total,
        unread_count,
        setUnreadCount,
        getOrderDetailPath,
        handleViewDetailNotification,
        recentNotifications,
        setRecentNotifications,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export default NotificationProvider;

export const useNotification = () => {
  return useContext(NotificationContext);
};
