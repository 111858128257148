// Base path
export const DASHBOARD = "/";
export const ADMIN = "/admin";
export const AMIN_LOYAL = "/loyalty/admin";
export const WEBSITE = "/wp";
export const REPORT_EC = "/reports_ec";

// Authenticate
export const ADMIN_LOGIN = `${ADMIN}/login`;
export const ADMIN_LOGOUT = `${ADMIN}/logout`;
export const ADMIN_FORGOT_PASSWORD = `${ADMIN}/forgot-password`;
export const ADMIN_RESET_PASSWORD = `${ADMIN}/reset-password`;

// Event
export const EVENT = `${ADMIN}/events`;
export const EVENT_CREATE = `${EVENT}/create`;
export const EVENT_DETAIL = `${EVENT}/:id/detail`;
export const EVENT_EDIT = `${EVENT}/:id/edit`;
export const EVENT_REPORT = `${EVENT}/report`;

// Review
export const REVIEW_PATH = `${ADMIN}/reviews`;
export const REVIEW_APPROVED = `${REVIEW_PATH}/approved`;
export const REVIEW_REJECTED = `${REVIEW_PATH}/rejected`;
export const REVIEW_REPORT_PRODUCT = `${REVIEW_PATH}/:id/products`;
export const REVIEW_REPORT_COMMENT = `${REVIEW_PATH}/:id/comments`;
export const REVIEW_DETAIL = `${REVIEW_PATH}/:id`;

// Fanclub
export const FANCLUB = `${ADMIN}/customer-reviews`;
export const FANCLUB_REPORT = `${ADMIN}/customer-reviews/report`;
export const FANCLUB_APPROVED = `${ADMIN}/customer-reviews/approved`;
export const FANCLUB_REJECTED = `${ADMIN}/customer-reviews/rejected`;
export const FANCLUB_STORES_LIST = `${ADMIN}/customer-reviews/stores`;

// Challenger
export const CHALLENGE = `${ADMIN}/challenges`;
export const CHALLENGE_CREATE = `${CHALLENGE}/create`;
export const CHALLENGE_DETAIL = `${CHALLENGE}/:id/detail`;
export const CHALLENGE_EDIT = `${CHALLENGE}/:id/edit`;
export const CHALLENGE_REPORT = `${CHALLENGE}/report`;
export const TEAM_PATH = `${CHALLENGE}/teams`;
export const IMAGE_PATH = `${CHALLENGE}/images`;

// Banner
export const BANNER_PATH = `${ADMIN}/banners`;
export const ECOM_BANNER_SETTING = `${ADMIN}/banners-manage`;
export const ECOM_BANNER_DETAIL_SETTING = `${ADMIN}/banners-manage/:bannerId`;

// Mall
export const MALL_PATH = `${ADMIN}/malls`;
export const MALL_ADMIN_CREATE = `${MALL_PATH}/:mall_id/admins/create`;
export const MALL_ADMIN_EDIT = `${MALL_PATH}/:mall_id/admins/:id/edit`;
export const MALL_REPORT = `${ADMIN}/reports`;
export const MALL_ADMIN_LIST = `${MALL_PATH}/:id/admins`;
export const MALL_DETAIL_ACCOUNT = `${MALL_PATH}/:mall_id/admins/:accountId`;
export const MALL_CREATE_PATH = `${MALL_PATH}/create`;
export const MALL_EDIT_PATH = `${MALL_PATH}/:id/edit`;
export const MALL_WAREHOUSE_SETTING = `${MALL_PATH}/:id/warehouse`;
export const MALL_BANNER_SETTING = `${MALL_PATH}/:id/banners`;
export const MALL_DETAIL_BANNER_SETTING = `${MALL_PATH}/:id/banners/:bannerId`;
export const MALL_CREATE_BANNER_SETTING = `${MALL_PATH}/:id/banners/new`;
export const MALL_DETAIL_PATH = `${MALL_PATH}/:id`;

// Tenant
export const TENANT_PATH = `${ADMIN}/tenants`;
export const TENANT_CREATE_PATH = `${TENANT_PATH}/create`;
export const TENANT_EDIT_PATH = `${TENANT_PATH}/:id/edit`;
export const TENANT_DETAIL_PATH = `${TENANT_PATH}/:mall_id/:tenant_id/detail`;
export const TENANT_STAFF_PATH = `${TENANT_PATH}/:mall_id/:tenant_id/staffs`;
export const TENANT_STAFF_CREATE_PATH = `${TENANT_STAFF_PATH}/create`;
export const TENANT_STAFF_EDIT_PATH = `${TENANT_STAFF_PATH}/:id/edit`;
export const TENANT_STAFF_DETAIL_PATH = `${TENANT_STAFF_PATH}/:id/detail`;
export const POS_PATH = `${TENANT_PATH}/pos`;
export const LIST_POS = `${ADMIN}/tenants/:mall_id/:tenant_id/pos/list-pos`;
export const POS_LIST_CREATE = `${ADMIN}/tenants/:id/pos/create`;
export const TENANT_STAFF_REPORT_PATH = `${TENANT_STAFF_PATH}/:id/report`;
export const TENANT_REPORT_PATH = `${TENANT_PATH}/:mall_id/:tenant_id/report`;
export const TENANT_CAMPAIGN_MANAGE_PATH = `${ADMIN}/campaign-manage`;
export const TENANT_CAMPAIGN_CREATE_PATH = `${TENANT_CAMPAIGN_MANAGE_PATH}/create`;
export const TENANT_CAMPAIGN_EDIT_PATH = `${TENANT_CAMPAIGN_MANAGE_PATH}/:id/edit`;

// sub admin
export const SUB_ADMIN_PATH = `${ADMIN}/sub_admins`;
export const SUB_ADMIN_CREATE_PATH = `${ADMIN}/sub_admins/create`;
export const SUB_ADMIN_EDIT_PATH = `${ADMIN}/sub_admins/:id/edit`;

//
export const TENANT = `${ADMIN}/loyalty`;
export const TENANT_TRADE_LIST = `${TENANT_PATH}/:mall_id/:tenant_id/trade-list`;
export const TENANT_TRADE_DETAIL = `${ADMIN}/tenants/purchase-transactions/:id`;
export const TENANT_DETAIL = `${TENANT}/:id/detail`;
export const TENANT_LIST_COIN = `${TENANT_PATH}/:mall_id/:tenant_id/list-coin`;
export const TENANT_SETTING_DELIVERY = `${TENANT_PATH}/:mall_id/:tenant_id/setting-delivery`;
export const TENANT_SETTING_TAX = `${TENANT_PATH}/:mall_id/:tenant_id/setting-tax`;
export const FOLLOW_PATH = `${ADMIN}/follows`;
export const TENANT_SETTING_BANNER = `${TENANT_PATH}/:mall_id/:tenant_id/setup-banner`;
export const TENANT_SETTING_DETAIL_BANNER = `${TENANT_PATH}/:mall_id/:tenant_id/setup-banner/:bannerId`;
export const TENANT_SETTING_CREATE_BANNER = `${TENANT_PATH}/:mall_id/:tenant_id/setup-banner/new`;
export const TENANT_SETTING_COLOR = `${TENANT_PATH}/:mall_id/:tenant_id/setup-color`;
export const TENANT_SETTING_WAREHOUSE = `${TENANT_PATH}/:mall_id/:tenant_id/setup-warehouse`;
export const TENANT_SETTING_OPERATING_TIME = `${TENANT_PATH}/:mall_id/:tenant_id/oparating-time`;

export const TENANT_SETTING_TOS = `${TENANT_PATH}/:mall_id/:tenant_id/setting-tos`;
//
export const COMMENT = `${ADMIN}/commments`;

// Redeem Campaign
export const REDEEM_CAMPAIGN_PATH = `${ADMIN}/redeem-campaigns`;
export const REDEEM_CAMPAIGN_CREATE_PATH = `${REDEEM_CAMPAIGN_PATH}/create`;
export const REDEEM_CAMPAIGN_DETAIL_PATH = `${REDEEM_CAMPAIGN_PATH}/:id/detail`;
export const REDEEM_CAMPAIGN_TRANSACTION_PATH = `${REDEEM_CAMPAIGN_PATH}/:id/transactions`;

//Customer
export const CUSTOMER_PATH = `${ADMIN}/customers`;
export const CUSTOMER_DETAIL = `${CUSTOMER_PATH}/:id/:is_exact/detail`;
export const MEMBERSHIP_REGISTER = `${CUSTOMER_PATH}/:id/register`;
export const MEMBER_PATH = `${CUSTOMER_PATH}/members`;
export const MEMBER_UPDATE = `${MEMBER_PATH}/:id/update`;
export const MEMBER_DETAIL = `${MEMBER_PATH}/:id/:is_exact/detail`;
export const MEMBER_REFERRALS = `${MEMBER_PATH}/:id/referrals`;
export const OTHER_POINT_HISTORY = `${MEMBER_PATH}/:id/other-point-history`;
export const OTHER_POINT_DETAIL = `${MEMBER_PATH}/:id/other-point-history/:event_id/detail`;
export const TRADE_HISTORY = `${MEMBER_PATH}/:id/trade-history`;
export const PURCHASE_DETAIL = `${MEMBER_PATH}/:id/purchase-detail/:event_id`;
export const POINT_HISTORY = `${MEMBER_PATH}/:id/point-history`;
export const MEMBER_REDEEMS = `${MEMBER_PATH}/:id/reddems`;
export const USER_ACCOUNTS_PATH = `${ADMIN}/user-accounts`;
export const USER_ACCOUNT_EDIT_PATH = `${ADMIN}/user-accounts/:id/edit`;
export const USER_ACCOUNT_CREATE_PATH = `${ADMIN}/user-accounts/create`;
export const USER_ACCOUNT_DETAIL_PATH = `${ADMIN}/user-accounts/:id`;
export const CUSTOMER_LIST_ADDRESS = `${MEMBER_PATH}/:id/list-address`;
export const CUSTOMER_LIST_ORDER = `${MEMBER_PATH}/:id/list-order`;

// Earning rule
export const EARNINGRULE_PATH = `${ADMIN}/earning-rules`;
export const EARNINGRULE_DETAIL_PATH = `${EARNINGRULE_PATH}/:id/detail`;
export const EARNINGRULE_CREATE_PATH = `${ADMIN}/earning-rules/create`;
export const EARNINGRULE_TRADE_PATH = `${EARNINGRULE_PATH}/:id/purchase-transactions`;

// Report
export const DASHBOARD_ANALYTICS = `${ADMIN}/analytics`;
export const DASHBOARD_STORE = `${DASHBOARD_ANALYTICS}/coin-store`;
export const ANALYTIC_PURCHASE_DETAIL_PATH = `${DASHBOARD_ANALYTICS}/transaction-detail/purchase`;
export const ANALYTIC_REDEEM_POINT_PATH = `${DASHBOARD_ANALYTICS}/transaction-detail/redeem`;
export const ANALYTIC_EARNING_POINT_PATH = `${DASHBOARD_ANALYTICS}/transaction-detail/earning`;
export const ANALYTIC_FOLLOW_POINT_PATH = `${DASHBOARD_ANALYTICS}/point-redeem-campion/`;

export const SMS = `${ADMIN}/sms`;
export const SMS_HISTORY = `${SMS}/transactions`;
export const SMS_SENDING_LIST = `${SMS}/sms-sending`;

// Parking
export const SEARCH_PARKING_PATH = `${ADMIN}/search-parking`;
export const STATUS_PARKING_PATH = `${ADMIN}/status-parking`;

//lucky draw
//campaign path
export const CAMPAIGN = `${ADMIN}/campaigns`;
export const CAMPAIGN_CREATE = `${CAMPAIGN}/create`;
export const CAMPAIGN_EDIT = `${CAMPAIGN}/edit/:id`;
export const DRAW_HISTORY = `${ADMIN}/draw-histories`;
// export const CAMPAIGN_DETAIL = `${CAMPAIGN}/detail`
export const CAMPAIGN_ACTION = `${CAMPAIGN}/edit`;
export const REWARD_CREATE = `${CAMPAIGN}/:id/reward-create`;
export const REWARD_EDIT = `${CAMPAIGN}/:id/reward-edit`;

//request
export const REQUEST = `${ADMIN}/request`;
export const CREATE_MEMBER = `${REQUEST}/:id/create`;

//promotion
export const DISCOUNT_PATH = `${ADMIN}/discounts`;
export const DISCOUNT_CREATE = `${DISCOUNT_PATH}/create`;
export const DISCOUNT_LIST = `${DISCOUNT_PATH}/list`; //all
export const DISCOUNT_APPROVED_LIST = `${DISCOUNT_PATH}/list/approved`;
export const DISCOUNT_WAITING_LIST = `${DISCOUNT_PATH}/list/waiting`;
export const DISCOUNT_REJECTED_LIST = `${DISCOUNT_PATH}/list/rejected`;
export const DISCOUNT_RUNNING_LIST = `${DISCOUNT_PATH}/list/running`;
export const DISCOUNT_UPCOMING_LIST = `${DISCOUNT_PATH}/list/upcoming`;
export const DISCOUNT_END_LIST = `${DISCOUNT_PATH}/list/end`;
export const DISCOUNT_VIOLATE_LIST = `${DISCOUNT_PATH}/list/violate`;
export const DISCOUNT_DETAIL = `${DISCOUNT_PATH}/:id/detail`;
export const DISCOUNT_EDIT = `${DISCOUNT_PATH}/edit/:id`;

//Quiz game
export const QUIZ_GAME = `${ADMIN}/quiz-game`;
export const QUIZ_GAME_CAMPAIGN = `${QUIZ_GAME}-campaign`;
export const QUIZ_GAME_CAMPAIGN_CREATE = `${QUIZ_GAME_CAMPAIGN}/create`;
export const QUIZ_GAME_CAMPAIGN_EDIT = `${QUIZ_GAME_CAMPAIGN}/edit/:id`;
export const QUIZ_GAME_REPORT = `${QUIZ_GAME}-report`;
export const QUIZ_GAME_QUESTION = `${QUIZ_GAME}-question`;
export const QUIZ_GAME_QUESTION_CREATE = `${QUIZ_GAME_QUESTION}/create`;
export const QUIZ_GAME_QUESTION_EDIT = `${QUIZ_GAME_QUESTION}/edit/:id`;
export const TAPU = `${ADMIN}/tapu`;
export const TAPU_QR_LIST = `${TAPU}/qr-codes`;
export const TAPU_QR_LIST_LOGIN = `${TAPU}/login`;

//Coupon
export const COUPON_PATH = `${ADMIN}/coupons`;
export const COUPON_CREATE = `${COUPON_PATH}/create`;
export const COUPON_LIST = `${COUPON_PATH}/list`;
export const COUPON_LIST_APPROVED = `${COUPON_PATH}/list/approved`;
export const COUPON_LIST_WAITING = `${COUPON_PATH}/list/waiting`;
export const COUPON_LIST_REJECTED = `${COUPON_PATH}/list/rejected`;
export const COUPON_LIST_RUNNING = `${COUPON_PATH}/list/running`;
export const COUPON_LIST_INCOMING = `${COUPON_PATH}/list/incoming`;
export const COUPON_LIST_END = `${COUPON_PATH}/list/end`;
export const COUPON_DETAIL = `${COUPON_PATH}/:coupon_id/detail`;
export const COUPON_DETAIL_APPLY = `${COUPON_PATH}/:coupon_id/apply`;
export const COUPON_UPDATE = `${COUPON_PATH}/:coupon_id/update`;

// Product
export const PRODUCT_PATH = `${ADMIN}/products`;
export const PRODUCT_CREATE = `${PRODUCT_PATH}/create`;
export const PRODUCT_UPDATE = `${PRODUCT_PATH}/:id/update`;
export const PRODUCT_COPY = `${PRODUCT_PATH}/:id/copy`;
export const PRODUCT_DETAIL = `${PRODUCT_PATH}/:id/detail`;
export const PRODUCT_LIST = `${PRODUCT_PATH}/list`;

// Product Category
export const PRODUCT_CATEGORY_LIST_PATH = `${ADMIN}/product-category`;
export const PRODUCT_CATEGORY_CREATE_PATH = `${PRODUCT_CATEGORY_LIST_PATH}/create`;
export const PRODUCT_CATEGORY_EDIT_PATH = `${PRODUCT_CATEGORY_LIST_PATH}/:id/edit`;
export const PRODUCT_CATEGORY_DETAIL_PATH = `${PRODUCT_CATEGORY_LIST_PATH}/:id/detail`;

//setting
export const TAX_PATH = `${ADMIN}/tax`;
export const TAX_CREATE_PATH = `${TAX_PATH}/create`;
export const TAX_EDIT_PATH = `${TAX_PATH}/edit/:id`;

//setting revenue
export const REVENUE_PATH = `${ADMIN}/revenue`;
export const REVENUE_PATH_DETAIL = `${ADMIN}/revenue/:id`;
// transaction
export const TRANSACTION_PATH = `${ADMIN}/transaction`;
export const TRANSACTION_DETAIL_PATH = `${TRANSACTION_PATH}/:id/detail`;

// CS Tickets
export const CSTICKET_PATH = `${ADMIN}/cs-tickets`;
export const CSTICKET_CREATE = `${CSTICKET_PATH}/create`;
export const CSTICKET_UPDATE = `${CSTICKET_PATH}/:id/edit`;
export const CSTICKET_DETAIL = `${CSTICKET_PATH}/:id/detail`;
export const CSTICKET_LIST = `${CSTICKET_PATH}/list`; // new
export const CSTICKET_PENDING_LIST = `${CSTICKET_PATH}/list/pending`;
export const CSTICKET_CANCELED_LIST = `${CSTICKET_PATH}/list/canceled`;
export const CSTICKET_COMPLETE_LIST = `${CSTICKET_PATH}/list/complete`;

// Customer ecommerce
export const CUSTOMER_ECOM_LIST_PATH = `${ADMIN}/ecom/customers`;
export const CUSTOMER_ECOM_DETAIL_PATH = `${ADMIN}/ecom/customers/:id/detail`;

//orders
export const ORDER_PATH = `${ADMIN}/order`;
export const ORDER_CREATE = `${ORDER_PATH}/create`;

export const ORDER_LIST = `${ORDER_PATH}/list`; //all
export const ORDER_WAIT_CONFIRM_LIST = `${ORDER_PATH}/list/wait-confirm`;
export const ORDER_WAIT_PICKUP_LIST = `${ORDER_PATH}/list/wait-pickup`;
export const ORDER_DELIVERY_LIST = `${ORDER_PATH}/list/delivery`;
export const ORDER_DELIVERED_LIST = `${ORDER_PATH}/list/delivered`;
export const ORDER_CANCEL_LIST = `${ORDER_PATH}/list/cancel`;
export const ORDER_COMPLAINT_LIST = `${ORDER_PATH}/list/complaint`;

export const ORDER_RETURN_LIST = `${ORDER_PATH}/list/return`;
export const ORDER_REFUND_LIST = `${ORDER_PATH}/list/refund`;
export const ORDER_DETAIL = `${ORDER_PATH}/:id/detail`;
export const ORDER_RETURN_DETAIL = `${ORDER_PATH}/return/:id/:return_id/detail`;
export const ORDER_COMPLAINT_DETAIL = `${ORDER_PATH}/complaint/:id/:complaint_id/detail`;
export const ORDER_CANCEL_DETAIL = `${ORDER_PATH}/cancel/:id/:cancel_id/detail`;
export const ORDER_DELIVERY_FAIL_LIST = `${ORDER_PATH}/list/delivery-fail`;
export const ORDER_REFUND_DETAIL = `${ORDER_PATH}/refund/:id/:refundId/detail`;

//USER TRACKING & GEO VISUALIZATION
export const REPORT_GEO_PROVINCE = `${ADMIN}/report-geo-province`;
export const REPORT_GEO_DISTRICT = `${ADMIN}/report-geo-district`;
export const REPORT_GEO_WARD = `${ADMIN}/report-geo-ward`;
export const REPORT_RETENTION_TIME = `${ADMIN}/report-retention-time`;
export const REPORT_RETENTION_GROUP = `${ADMIN}/report-retention-group`;
export const CHECK_OTP = `${ADMIN}/report-geo-check-otp`;
export const USER_OVERLAP = `${ADMIN}/report-geo-user-overlap`;
export const COMPARATIVE_COHORT = `${ADMIN}/report-geo-comparative-cohort`;
export const REPORT_GEO_DISTANCE = `${ADMIN}/report-geo-ditance`;

// FACILITY BOOKING
export const FACILITY_LIST = `${ADMIN}/tags`;
export const BOOKING_LIST = `${ADMIN}/bookings`;
export const BOOKING_REPORT = `${ADMIN}/report`;
export const FACILITY_DETAIL = `${ADMIN}/facility/:id`;

// NOTIFICATION
export const NOTIFICATION_LIST = `${ADMIN}/notifications`;
export const NOTIFICATIONS = `${ADMIN}/list/notification`;
export const NOTIFICATION_DETAIL_PATH = `${ADMIN}/notification/:id/detail`;
export const NOTIFICATION_CREATE_PATH = `${ADMIN}/notification/create`;

//E-SURVEY
export const ESURVEY = `${ADMIN}/e-surveys`;
export const CAMPAIGN_DETAIL_PATH = `${ESURVEY}/campaign/:id/detail`;
export const ESURVEY_CREATE_PATH = `${ADMIN}/e-survey/create`;
export const ESURVEY_DETAIL_PATH = `${ADMIN}/e-survey/:id/detail`;
export const PARTICIPANT_DETAIL_PATH = `${ESURVEY}/participant/:id/detail`;
// WEBSITE MANAGEMENT
export const WP_EVENT = `${WEBSITE}/events`;
export const WP_EVENT_CREATE = `${WEBSITE}/events/create`;
export const WP_EVENT_DETAIL = `${WEBSITE}/events/:id/detail`;
export const WP_EVENT_EDIT = `${WEBSITE}/events/:id/edit`;

export const WP_NEWS = `${WEBSITE}/news`;
export const WP_NEWS_CREATE = `${WEBSITE}/news/create`;
export const WP_NEWS_DETAIL = `${WEBSITE}/news/:id/detail`;
export const WP_NEWS_EDIT = `${WEBSITE}/news/:id/edit`;

export const WP_PROMOTION = `${WEBSITE}/promotions`;
export const WP_PROMOTION_CREATE = `${WEBSITE}/promotions/create`;
export const WP_PROMOTION_EDIT = `${WEBSITE}/promotions/:id/edit`;

export const WP_SERVICE = `${WEBSITE}/services`;
export const WP_SERVICE_CREATE = `${WEBSITE}/services/create`;
export const WP_SERVICE_EDIT = `${WEBSITE}/services/:id/edit`;

export const WP_POPUP = `${WEBSITE}/popups`;
export const WP_POPUP_CREATE = `${WEBSITE}/popups/create`;
export const WP_POPUP_EDIT = `${WEBSITE}/popups/:id/edit`;

export const WP_BANNER = `${WEBSITE}/banners`;
export const WP_BANNER_CREATE = `${WEBSITE}/banners/create`;
export const WP_BANNER_EDIT = `${WEBSITE}/banners/:id/edit`;

export const WP_TENANT = `${WEBSITE}/tenants`;
export const WP_TENANT_CREATE = `${WEBSITE}/tenants/create`;
export const WP_TENANT_EDIT = `${WEBSITE}/tenants/:id/edit`;
export const WP_TENANT_GALLERY_PATH = `${WEBSITE}/:mall_id/:tenant_id/gallery`;
export const WP_TENANT_COLLECTION_PATH = `${WEBSITE}/:mall_id/:tenant_id/collection`;

export const WP_CATEGORY = `${WEBSITE}/categories`;
export const WP_CATEGORY_CREATE = `${WEBSITE}/categories/create`;
export const WP_CATEGORY_EDIT = `${WEBSITE}/categories/:id/edit`;

export const WP_LOCATION = `${WEBSITE}/locations`;
export const WP_LOCATION_CREATE = `${WEBSITE}/locations/create`;
export const WP_LOCATION_EDIT = `${WEBSITE}/locations/:id/edit`;

export const WP_FLOORMAP = `${WEBSITE}/floormaps`;
export const WP_FLOORMAP_CREATE = `${WEBSITE}/floormaps/create`;
export const WP_FLOORMAP_EDIT = `${WEBSITE}/floormaps/:id/edit`;

export const WP_FACILITY = `${WEBSITE}/facilities`;
export const WP_FACILITY_CREATE = `${WEBSITE}/facilities/create`;
export const WP_FACILITY_EDIT = `${WEBSITE}/facilities/:id/edit`;

export const WP_TAG = `${WEBSITE}/tags`;
export const WP_TAG_CREATE = `${WEBSITE}/tags/create`;
export const WP_TAG_EDIT = `${WEBSITE}/tags/:id/edit`;

export const WP_RECRUITMENT = `${WEBSITE}/recruitments`;
export const WP_RECRUITMENT_CREATE = `${WEBSITE}/recruitments/create`;
export const WP_RECRUITMENT_EDIT = `${WEBSITE}/recruitments/:id/edit`;

export const WP_COLLECTION = `${WEBSITE}/collections`;
export const WP_COLLECTION_CREATE = `${WEBSITE}/collections/create`;
export const WP_COLLECTION_EDIT = `${WEBSITE}/collections/:id/edit`;

export const WP_DISHES = `${WEBSITE}/dishes`;
export const WP_DISHES_CREATE = `${WEBSITE}/dishes/create`;
export const WP_DISHES_EDIT = `${WEBSITE}/dishes/:id/edit`;
export const WP_TIPS = `${WEBSITE}/dishes/:dish_id/tips`;
export const WP_TIPS_CREATE = `${WEBSITE}/dishes/:dish_id/tips/create`;
export const WP_TIPS_EDIT = `${WEBSITE}/dishes/:dish_id/tips/:id/edit`;
export const WP_APPLICANT_EDIT = `${WEBSITE}/applicant/:id/edit`;
export const WP_APPLICANT = `${WEBSITE}/applicants`;

export const WP_NOTI = `${WEBSITE}/notifications`;

export const REPORT_ORDER_DETAIL = `${REPORT_EC}/order-detail`;
export const REPORT_ORDER_PAYMENT = `${REPORT_EC}/order-payment`;
export const REPORT_ORDER_REFUND = `${REPORT_EC}/order-refund`;
export const REPORT_ORDER_RETURN = `${REPORT_EC}/order-returned`;
export const REPORT_ORDER_TRANSPORT = `${REPORT_EC}/order-transport`;
export const REPORT_ORDER_COMPLAINT = `${REPORT_EC}/order-complaint`;
export const REPORT_ORDER_DAILY = `${REPORT_EC}/order-daily`;
export const REPORT_ORDER_MONTHLY = `${REPORT_EC}/order-monthly`;
export const REPORT_ORDER_SETTLEMENT = `${REPORT_EC}/order-settlement`;
export const REPORT_PRODUCT_PHYSIC = `${REPORT_EC}/product-physic`;
export const REPORT_PRODUCT_EGIFT = `${REPORT_EC}/product-egift`;
export const REPORT_COUPON_CAMPAIGN = `${REPORT_EC}/coupon-campaign`;
export const REPORT_TAX_010 = `${REPORT_EC}/tax-010`;
export const REPORT_TAX_011 = `${REPORT_EC}/tax-011`;
export const REPORT_TAX_012 = `${REPORT_EC}/tax-012`;
export const REPORT_TAX_013 = `${REPORT_EC}/tax-013`;
export const REPORT_PAYOO_TOKEN = `${REPORT_EC}/payoo-token`;
export const REPORT_TICKET = `${REPORT_EC}/ticket`;
export const REPORT_DAILY_SALES = `${REPORT_EC}/daily-sales`;
export const REPORT_SETTLEMENT_PERIOD = `${REPORT_EC}/settlement-period`;
export const REPORT_COD_ASPIRE = `${REPORT_EC}/cod-aspire`;
export const REPORT_COD_ASPIRE_UPLOAD = `${REPORT_EC}/cod-aspire-upload`;
// brand path
export const BRAND_PATH = `${ADMIN}/brands`;
export const BRAND_CREATE = `${BRAND_PATH}/create`;
export const BRAND_EDIT = `${BRAND_PATH}/edit/:id`;
export const BRAND_ACCOUNT_LIST_PATH = `${BRAND_PATH}/:id/accounts`;
export const BRAND_ACCOUNT_CREATE_PATH = `${BRAND_PATH}/:id/accounts/create`;
export const BRAND_ACCOUNT_EDIT_PATH = `${BRAND_PATH}/:id/accounts/:accountId/edit`;
export const BRAND_ACCOUNT_DETAIL_PATH = `${BRAND_PATH}/:id/accounts/:accountId`;

// user profile
export const USER_PROFILE_PATH = `${ADMIN}/my-account`;
export const USER_PROFILE_EDIT_PATH = `${ADMIN}/my-account/edit`;
