import React, { useState } from "react";
import { Button, Modal, Form, Upload, Row } from "antd";
import "./Modal.scss"
const ModalNoti = ({
    wrapClassName,
    width,
    visible,
    message,
    onSubmit,
    onCancel,
}) => {

  return (
    <Modal
      wrapClassName={wrapClassName}
      centered
      width={width}
      visible={visible}
      footer={null}
      onCancel={onCancel}
      className="modal-customer-custom"
    >
     <p>
        {message}
     </p>
     <Button
     onClick={onCancel}
     className="btn btn-common btn-ok"
     >
        OK
     </Button>
    </Modal>
  );
};

export default ModalNoti;
