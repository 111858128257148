import React from "react";

const IconThreeDot = ({ height = 4, cursor="default" }) => {
  return (
    <svg
      width="16"
      height={`${height}`}
      viewBox={`0 0 16 ${height}`}
      cursor={`${cursor}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.00012 0C0.900122 0 0.00012207 0.9 0.00012207 2C0.00012207 3.1 0.900122 4 2.00012 4C3.10012 4 4.00012 3.1 4.00012 2C4.00012 0.9 3.10012 0 2.00012 0ZM14.0001 0C12.9001 0 12.0001 0.9 12.0001 2C12.0001 3.1 12.9001 4 14.0001 4C15.1001 4 16.0001 3.1 16.0001 2C16.0001 0.9 15.1001 0 14.0001 0ZM8.00012 0C6.90012 0 6.00012 0.9 6.00012 2C6.00012 3.1 6.90012 4 8.00012 4C9.10012 4 10.0001 3.1 10.0001 2C10.0001 0.9 9.10012 0 8.00012 0Z"
        fill="#828282"
      />
    </svg>
  );
};

export default IconThreeDot;
