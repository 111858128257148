import React, { useState } from "react";
import { Button, Modal, Form, Upload, Row } from "antd";
import "./Modal.scss"
import { useTranslation } from "react-i18next";
const ModalConfirmLockUnlock = ({
    wrapClassName,
    width,
    visible,
    message,
    onSubmit,
    onCancel,
}) => {
  const { t } = useTranslation();
  return (
    <Modal
      wrapClassName={wrapClassName}
      centered
      width={width}
      visible={visible}
      footer={null}
      onCancel={onCancel}
      className="modal-customer-custom"
    >
     <h2>
        {message}
     </h2>
     <Row style={{justifyContent:"center"}}>
     <Button
     onClick={onCancel}
     className="btn btn-cancel"
     >
       {t("common:btn_cancel")}
     </Button>
     <Button
     onClick={onSubmit}
     className="btn btn-common"
     >
       {t("orders:btn_agree")}
     </Button>
     </Row>
    </Modal>
  );
};

export default ModalConfirmLockUnlock;
