import React from 'react';

const IconTarget = ({
    width = 24,
    height = 24,
    viewBox = "0 0 24 24",
    fillSvg = "none",
    fillPath = "#828282",
    onClick = () => {
    }
}) => {
    return (
        <svg onClick={onClick} width={width} height={height} viewBox={viewBox} fill={fillSvg} xmlns="http://www.w3.org/2000/svg">
            <path d="M12.6374 18C9.88036 18 7.63736 15.757 7.63736 13C7.63736 10.243 9.88036 8 12.6374 8C15.3944 8 17.6374 10.243 17.6374 13C17.6374 15.757 15.3944 18 12.6374 18ZM12.6374 9C10.4314 9 8.63736 10.794 8.63736 13C8.63736 15.206 10.4314 17 12.6374 17C14.8434 17 16.6374 15.206 16.6374 13C16.6374 10.794 14.8434 9 12.6374 9Z" fill={fillPath} />
            <path fillRule="evenodd" clipRule="evenodd" d="M12.6374 8.4001C10.1013 8.4001 8.03737 10.464 8.03737 13.0001C8.03737 15.5362 10.1013 17.6001 12.6374 17.6001C15.1735 17.6001 17.2374 15.5362 17.2374 13.0001C17.2374 10.464 15.1735 8.4001 12.6374 8.4001ZM7.23737 13.0001C7.23737 10.0222 9.65945 7.6001 12.6374 7.6001C15.6153 7.6001 18.0374 10.0222 18.0374 13.0001C18.0374 15.978 15.6153 18.4001 12.6374 18.4001C9.65945 18.4001 7.23737 15.978 7.23737 13.0001ZM8.23737 13.0001C8.23737 10.5732 10.2105 8.6001 12.6374 8.6001C15.0643 8.6001 17.0374 10.5732 17.0374 13.0001C17.0374 15.427 15.0643 17.4001 12.6374 17.4001C10.2105 17.4001 8.23737 15.427 8.23737 13.0001ZM12.6374 9.4001C10.6523 9.4001 9.03737 11.015 9.03737 13.0001C9.03737 14.9852 10.6523 16.6001 12.6374 16.6001C14.6225 16.6001 16.2374 14.9852 16.2374 13.0001C16.2374 11.015 14.6225 9.4001 12.6374 9.4001Z" fill={fillPath} />
            <path d="M12.6374 23C7.12336 23 2.63736 18.514 2.63736 13C2.63736 7.486 7.12336 3 12.6374 3C18.1514 3 22.6374 7.486 22.6374 13C22.6374 18.514 18.1514 23 12.6374 23ZM12.6374 4C7.67436 4 3.63736 8.038 3.63736 13C3.63736 17.962 7.67436 22 12.6374 22C17.6004 22 21.6374 17.962 21.6374 13C21.6374 8.038 17.6004 4 12.6374 4Z" fill={fillPath} />
            <path fillRule="evenodd" clipRule="evenodd" d="M12.6374 3.4001C7.34428 3.4001 3.03737 7.70701 3.03737 13.0001C3.03737 18.2932 7.34428 22.6001 12.6374 22.6001C17.9305 22.6001 22.2374 18.2932 22.2374 13.0001C22.2374 7.70701 17.9305 3.4001 12.6374 3.4001ZM2.23737 13.0001C2.23737 7.26518 6.90245 2.6001 12.6374 2.6001C18.3723 2.6001 23.0374 7.26518 23.0374 13.0001C23.0374 18.735 18.3723 23.4001 12.6374 23.4001C6.90245 23.4001 2.23737 18.735 2.23737 13.0001ZM3.23737 13.0001C3.23737 7.81721 7.45342 3.6001 12.6374 3.6001C17.8213 3.6001 22.0374 7.81721 22.0374 13.0001C22.0374 18.183 17.8213 22.4001 12.6374 22.4001C7.45342 22.4001 3.23737 18.183 3.23737 13.0001ZM12.6374 4.4001C7.89531 4.4001 4.03737 8.25898 4.03737 13.0001C4.03737 17.7412 7.89531 21.6001 12.6374 21.6001C17.3794 21.6001 21.2374 17.7412 21.2374 13.0001C21.2374 8.25898 17.3794 4.4001 12.6374 4.4001Z" fill={fillPath} />
            <path d="M12.6374 6C12.3614 6 12.1374 5.776 12.1374 5.5V1.5C12.1374 1.224 12.3614 1 12.6374 1C12.9134 1 13.1374 1.224 13.1374 1.5V5.5C13.1374 5.776 12.9134 6 12.6374 6Z" fill={fillPath} />
            <path fillRule="evenodd" clipRule="evenodd" d="M12.6374 1.4001C12.5823 1.4001 12.5374 1.44501 12.5374 1.5001V5.5001C12.5374 5.55518 12.5823 5.6001 12.6374 5.6001C12.6925 5.6001 12.7374 5.55518 12.7374 5.5001V1.5001C12.7374 1.44501 12.6925 1.4001 12.6374 1.4001ZM11.7374 1.5001C11.7374 1.00318 12.1405 0.600098 12.6374 0.600098C13.1343 0.600098 13.5374 1.00318 13.5374 1.5001V5.5001C13.5374 5.99701 13.1343 6.4001 12.6374 6.4001C12.1405 6.4001 11.7374 5.99701 11.7374 5.5001V1.5001Z" fill={fillPath} />
            <path d="M12.6374 25C12.3614 25 12.1374 24.776 12.1374 24.5V20.5C12.1374 20.224 12.3614 20 12.6374 20C12.9134 20 13.1374 20.224 13.1374 20.5V24.5C13.1374 24.776 12.9134 25 12.6374 25Z" fill={fillPath} />
            <path fillRule="evenodd" clipRule="evenodd" d="M12.6374 20.4001C12.5823 20.4001 12.5374 20.445 12.5374 20.5001V24.5001C12.5374 24.5552 12.5823 24.6001 12.6374 24.6001C12.6925 24.6001 12.7374 24.5552 12.7374 24.5001V20.5001C12.7374 20.445 12.6925 20.4001 12.6374 20.4001ZM11.7374 20.5001C11.7374 20.0032 12.1405 19.6001 12.6374 19.6001C13.1343 19.6001 13.5374 20.0032 13.5374 20.5001V24.5001C13.5374 24.997 13.1343 25.4001 12.6374 25.4001C12.1405 25.4001 11.7374 24.997 11.7374 24.5001V20.5001Z" fill={fillPath} />
            <path d="M5.13736 13.5H1.13736C0.86136 13.5 0.63736 13.276 0.63736 13C0.63736 12.724 0.86136 12.5 1.13736 12.5H5.13736C5.41336 12.5 5.63736 12.724 5.63736 13C5.63736 13.276 5.41336 13.5 5.13736 13.5Z" fill={fillPath} />
            <path fillRule="evenodd" clipRule="evenodd" d="M1.13737 12.9001C1.08228 12.9001 1.03737 12.945 1.03737 13.0001C1.03737 13.0552 1.08228 13.1001 1.13737 13.1001H5.13737C5.19245 13.1001 5.23737 13.0552 5.23737 13.0001C5.23737 12.945 5.19245 12.9001 5.13737 12.9001H1.13737ZM0.237366 13.0001C0.237366 12.5032 0.640452 12.1001 1.13737 12.1001H5.13737C5.63428 12.1001 6.03737 12.5032 6.03737 13.0001C6.03737 13.497 5.63428 13.9001 5.13737 13.9001H1.13737C0.640452 13.9001 0.237366 13.497 0.237366 13.0001Z" fill={fillPath} />
            <path d="M24.1374 13.5H20.1374C19.8614 13.5 19.6374 13.276 19.6374 13C19.6374 12.724 19.8614 12.5 20.1374 12.5H24.1374C24.4134 12.5 24.6374 12.724 24.6374 13C24.6374 13.276 24.4134 13.5 24.1374 13.5Z" fill={fillPath} />
            <path fillRule="evenodd" clipRule="evenodd" d="M20.1374 12.9001C20.0823 12.9001 20.0374 12.945 20.0374 13.0001C20.0374 13.0552 20.0823 13.1001 20.1374 13.1001H24.1374C24.1925 13.1001 24.2374 13.0552 24.2374 13.0001C24.2374 12.945 24.1925 12.9001 24.1374 12.9001H20.1374ZM19.2374 13.0001C19.2374 12.5032 19.6405 12.1001 20.1374 12.1001H24.1374C24.6343 12.1001 25.0374 12.5032 25.0374 13.0001C25.0374 13.497 24.6343 13.9001 24.1374 13.9001H20.1374C19.6405 13.9001 19.2374 13.497 19.2374 13.0001Z" fill={fillPath} />
        </svg>
    );
};

IconTarget.propTypes = {};

export default IconTarget;