import React, { memo } from "react";
import { SearchBar } from "../orderedAll";
import OrderedList from "../orderedList";

function ReturnComplain() {
  return (
    <div>
      <SearchBar />
      <OrderedList />
    </div>
  );
}

export default memo(ReturnComplain);
