import React from 'react';
// import PropTypes from 'prop-types';

const IconDelete = ({
                   width = 24,
                   height = 24,
                   viewBox = "0 0 24 24",
                   fillSvg = "none",
                   fillPath = "#828282",
                   onClick = () => {
                   }
               }) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0)">
                <path d="M6.80615 0.96582H9.48022V1.41577H10.4459V0.902832C10.446 0.405029 10.0413 0 9.5437 0H6.74268C6.24512 0 5.84033 0.405029 5.84033 0.902832V1.41577H6.80615V0.96582Z" fill="#333333"/>
                <path d="M5.8087 15.0506L5.80862 15.0506C5.7977 15.0513 5.78655 15.0517 5.77534 15.0517C5.4959 15.0517 5.26137 14.8342 5.24399 14.5515C5.24399 14.5515 5.24399 14.5515 5.24399 14.5515L4.76901 6.85757L4.76901 6.85756C4.75096 6.56378 4.97442 6.31092 5.26812 6.29287L5.26815 6.29287C5.56081 6.27509 5.81475 6.49792 5.83281 6.79198M5.8087 15.0506L5.83281 6.79198M5.8087 15.0506C6.10235 15.0325 6.32585 14.7797 6.30766 14.4859M5.8087 15.0506L6.30766 14.4859M5.83281 6.79198L6.30766 14.4859M5.83281 6.79198L6.30766 14.4859M6.30766 14.4859L6.30766 14.4859L6.25776 14.489C6.27424 14.7552 6.07172 14.9843 5.80561 15.0007L6.30766 14.4859ZM11.5178 6.85608L11.5178 6.85609L11.0643 14.55L11.0643 14.5501L11.0144 14.5471L11.5178 6.85608ZM11.5178 6.85608C11.535 6.5623 11.3109 6.30999 11.0171 6.29274M11.5178 6.85608L11.0171 6.29274M11.0171 6.29274C10.7225 6.27536 10.4711 6.49957 10.4538 6.79332M11.0171 6.29274L10.4538 6.79332M10.4538 6.79332C10.4538 6.79333 10.4538 6.79333 10.4538 6.79334L10.4538 6.79332ZM3.99424 15.0702L3.9444 15.0742L3.99424 15.0702L3.23631 5.69812C3.23631 5.69812 3.23631 5.69812 3.23631 5.69812C3.21864 5.47955 3.3912 5.29255 3.61042 5.29255H12.6763C12.8956 5.29255 13.0681 5.47955 13.0503 5.6981L13.0503 5.69812L12.2924 15.0701L12.2924 15.0701C12.2523 15.5675 11.8375 15.9504 11.3393 15.9504H4.94733C4.44914 15.9504 4.03439 15.5675 3.99424 15.0702ZM8.14875 15.0516C8.44308 15.0516 8.68166 14.813 8.68166 14.5188V6.82471C8.68166 6.53036 8.44296 6.2918 8.14875 6.2918C7.85441 6.2918 7.61584 6.53037 7.61584 6.82471V14.5188C7.61584 14.813 7.85442 15.0516 8.14875 15.0516Z" fill="#333333" stroke="white" strokeWidth="0.1"/>
                <path d="M14.299 3.76802L14.299 3.76803C14.3524 3.92792 14.2832 4.09152 14.1528 4.17335L14.1528 4.1734C14.0999 4.20671 14.0373 4.22661 13.9685 4.22661H2.31859C2.24978 4.22661 2.18727 4.20672 2.13423 4.17347C2.00389 4.09165 1.93471 3.92805 1.98809 3.76802C1.98809 3.76802 1.98809 3.76802 1.98809 3.76802L2.30523 2.81734L2.30523 2.81732C2.38192 2.58715 2.59754 2.43184 2.84019 2.43184H13.4468C13.6895 2.43184 13.905 2.58714 13.9819 2.81734L14.299 3.76802Z" fill="#333333" stroke="white" strokeWidth="0.1"/>
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="16" height="16" fill="white"/>
                </clipPath>
            </defs>
        </svg>

    );
};

IconDelete.propTypes = {};

export default IconDelete;
