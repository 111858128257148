import React from 'react';
import PropTypes from 'prop-types';
import logoBinhduong from "../../assets/img/aeon-mall-hai-phong-logo.png"
const IconHaiPhong = props => {
    const {onClick} = props
    return <img src={logoBinhduong} alt={''} onClick={onClick}/>
};

IconHaiPhong.defaultProps = {
}

IconHaiPhong.propTypes = {
    onClick: PropTypes.func
};

export default IconHaiPhong;