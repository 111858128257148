import { getAxios } from "../Http";
import { API_CUSTOMER_DETAIL } from "../config/endpointApi";
import { useQuery } from "react-query";
import { bindParams } from "../config/function";

const getCustomerById = async (id, is_exact) => {
  // console.log(bindParams(API_CUSTOMER_DETAIL, { id, is_exact }));
  return await getAxios(bindParams(API_CUSTOMER_DETAIL, { id }), { is_exact });
};

function useCustomerDetail(id, is_exact) {
  return useQuery(
    ["customers", id, is_exact],
    () => getCustomerById(id, is_exact),
    {
      enabled: !!id,
      refetchOnWindowFocus: false,
    }
  );
}

export default useCustomerDetail;
