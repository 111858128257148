import React from 'react';
import PropTypes from 'prop-types';
import logoBinhduong from "../../assets/img/logo-aeonmall-hadong.png"
const IconHaDong = props => {
    const {onClick} = props
    return <img src={logoBinhduong} alt={''} onClick={onClick}/>
};

IconHaDong.defaultProps = {
}

IconHaDong.propTypes = {
    onClick: PropTypes.func
};

export default IconHaDong;