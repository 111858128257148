import React from 'react'
// import PropTypes from 'prop-types';

const IconEdit = ({
    width = 24,
    height = 24,
    viewBox = '0 0 24 24',
    fillSvg = 'none',
    fillPath = '#828282',
    onClick = () => {},
}) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0)">
                <path
                    d="M1.99501 1.46287L1.9949 1.46287C0.83845 1.46418 -0.0986906 2.40132 -0.1 3.55777V3.55788L-0.1 13.9556L-0.0999999 13.9557C-0.0986906 15.1122 0.83845 16.0493 1.9949 16.0506H1.99501H13.1906H13.1907C14.3472 16.0493 15.2843 15.1122 15.2856 13.9557V13.9556V10.4128C15.2856 10.1372 15.0622 9.91377 14.7866 9.91377C14.511 9.91377 14.2876 10.1372 14.2876 10.4128V13.9554C14.2869 14.5611 13.7962 15.0519 13.1905 15.0525H1.99511C1.38947 15.0519 0.898772 14.5611 0.898005 13.9555V3.5579C0.898772 2.95241 1.38941 2.46164 1.99503 2.46087H5.53772C5.81333 2.46087 6.03672 2.23748 6.03672 1.96187C6.03672 1.68643 5.81336 1.46287 5.53772 1.46287L1.99501 1.46287Z"
                    fill="#333333"
                    stroke="#333333"
                    strokeWidth="0.2"
                />
                <path
                    d="M3.88599 12.299L3.88588 12.2989C3.75888 12.1717 3.7099 11.9862 3.75803 11.8129L3.88599 12.299ZM3.88599 12.299C4.0132 12.4261 4.19874 12.475 4.37203 12.427L4.37206 12.427L7.75158 11.4908C7.8346 11.4678 7.91025 11.4237 7.97125 11.3627L7.90298 11.2945L7.97125 11.3627L15.0896 4.24412L15.0896 4.24404C15.8287 3.50332 15.8287 2.30422 15.0896 1.5635L15.0896 1.56342L14.6213 1.09522C13.8811 0.354948 12.6809 0.354948 11.9406 1.09522L4.82219 8.21367C4.76119 8.27467 4.71713 8.35032 4.69413 8.43334L3.75805 11.8128L3.88599 12.299ZM14.0315 3.89093L14.3838 3.53857C14.7337 3.18784 14.7338 2.62002 14.3839 2.26907L14.0315 3.89093ZM14.0315 3.89093L12.2938 2.15331L12.6464 1.80086L12.6464 1.80085C12.9969 1.45041 13.5651 1.45041 13.9156 1.80085L13.9156 1.80086L14.3838 2.26896L14.0315 3.89093ZM7.64103 10.2812L5.90371 8.54373L11.5882 2.85909L13.3257 4.59657L7.64103 10.2812ZM4.95523 11.2298L5.43755 9.48886L6.69609 10.7475L4.95523 11.2298Z"
                    fill="#333333"
                    stroke="#333333"
                    strokeWidth="0.2"
                />
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

IconEdit.propTypes = {}

export default IconEdit
