import React from 'react'

const IconPost = ({
    width = 24,
    height = 24,
    viewBox = '0 0 24 24',
    fillSvg = 'none',
    fillPath = '#828282',
    onClick = () => {},
}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M13.2119 4.40134L9.93069 1.12018C9.8541 1.04356 9.74956 1 9.64062 1H3.89844C3.21996 1 2.66797 1.55199 2.66797 2.23047V13.7695C2.66797 14.448 3.21996 15 3.89844 15H12.1016C12.78 15 13.332 14.448 13.332 13.7695V4.69141C13.332 4.57957 13.2851 4.47454 13.2119 4.40134ZM10.0508 2.40036L11.9317 4.28125H10.4609C10.2348 4.28125 10.0508 4.09725 10.0508 3.87109V2.40036ZM12.1016 14.1797H3.89844C3.67228 14.1797 3.48828 13.9957 3.48828 13.7695V2.23047C3.48828 2.00431 3.67228 1.82031 3.89844 1.82031H9.23047V3.87109C9.23047 4.54957 9.78246 5.10156 10.4609 5.10156H12.5117V13.7695C12.5117 13.9957 12.3277 14.1797 12.1016 14.1797Z"
                fill="#828282"
                stroke="#828282"
                strokeWidth="0.5"
            />
            <path
                d="M10.4609 6.79688H5.53906C5.31255 6.79688 5.12891 6.98052 5.12891 7.20703C5.12891 7.43355 5.31255 7.61719 5.53906 7.61719H10.4609C10.6875 7.61719 10.8711 7.43355 10.8711 7.20703C10.8711 6.98052 10.6875 6.79688 10.4609 6.79688Z"
                fill="#828282"
                stroke="#828282"
                strokeWidth="0.5"
            />
            <path
                d="M10.4609 8.4375H5.53906C5.31255 8.4375 5.12891 8.62114 5.12891 8.84766C5.12891 9.07417 5.31255 9.25781 5.53906 9.25781H10.4609C10.6875 9.25781 10.8711 9.07417 10.8711 8.84766C10.8711 8.62114 10.6875 8.4375 10.4609 8.4375Z"
                fill="#828282"
                stroke="#828282"
                strokeWidth="0.5"
            />
            <path
                d="M10.4609 10.0781H5.53906C5.31255 10.0781 5.12891 10.2618 5.12891 10.4883C5.12891 10.7148 5.31255 10.8984 5.53906 10.8984H10.4609C10.6875 10.8984 10.8711 10.7148 10.8711 10.4883C10.8711 10.2618 10.6875 10.0781 10.4609 10.0781Z"
                fill="#828282"
                stroke="#828282"
                strokeWidth="0.5"
            />
            <path
                d="M8.82031 11.7188H5.53906C5.31255 11.7188 5.12891 11.9024 5.12891 12.1289C5.12891 12.3554 5.31255 12.5391 5.53906 12.5391H8.82031C9.04683 12.5391 9.23047 12.3554 9.23047 12.1289C9.23047 11.9024 9.04683 11.7188 8.82031 11.7188Z"
                fill="#828282"
                stroke="#828282"
                strokeWidth="0.5"
            />
        </svg>
    )
}

export default IconPost
