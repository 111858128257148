import { Checkbox, Col, Input, Modal, Radio, Row } from "antd";

const { useTranslation } = require("react-i18next");

const CustomerDetailAddress = ({ data, isModalOpen, handleCancel }) => {
  const { t } = useTranslation("member");
  return (
    <Modal
      visible={isModalOpen}
      width={572}
      onCancel={handleCancel}
      cancelText={t("Đóng")}
      okButtonProps={{ style: { display: "none" } }}
      className="modal-common"
      title={t("Thông tin địa chỉ")}
      cancelButtonProps={{ className: "btn-cancel" }}
    >
      <Row gutter="24">
        <Col span={12}>
          <p className="text-bold--black">{t("Họ và tên")}</p>
          <Input
            disabled
            defaultValue={data?.contact_name}
            className="bd-r-8"
            bordered
          />
        </Col>
        <Col span={12}>
          <p className="text-bold--black">{t("Số điện thoại")}</p>
          <Input
            disabled
            defaultValue={data?.contact_phone}
            className="bd-r-8"
            bordered
          />
        </Col>
        <Col span={24}>
          <p className="text-bold--black mg-t-20">{t("Tỉnh/Thành phố")}</p>
          <Input
            disabled
            defaultValue={data?.province?.name}
            className="bd-r-8"
            bordered
          />
        </Col>
        <Col span={24}>
          <p className="text-bold--black mg-t-20">{t("Quận/Huyện")}</p>
          <Input
            disabled
            defaultValue={data?.district?.name}
            className="bd-r-8"
            bordered
          />
        </Col>
        <Col span={24}>
          <p className="text-bold--black mg-t-20">{t("Phường/Xã")}</p>
          <Input
            disabled
            defaultValue={data?.ward?.name}
            className="bd-r-8"
            bordered
          />
        </Col>
        <Col span={24}>
          <p className="text-bold--black mg-t-20">{t("Địa chỉ cụ thể")}</p>
          <Input
            disabled
            defaultValue={data?.address}
            className="bd-r-8"
            bordered
          />
        </Col>
        <Col span={24}>
          <p className="text-bold--black mg-t-20">{t("Loại địa chỉ")}</p>
          <Radio.Group defaultValue={data.office_type ? data.office_type : 0}>
            <Radio value={0} disabled>
              {t("Nhà riêng")}
            </Radio>
            <Radio value={1} disabled>
              {t("Văn phòng")}
            </Radio>
          </Radio.Group>
        </Col>
        <Col span={24}>
          <Checkbox checked={data?.type} disabled className="mg-t-20">
            {t("Đặt làm địa chỉ mặc đinh")}
          </Checkbox>
        </Col>
      </Row>
    </Modal>
  );
};

export default CustomerDetailAddress;
