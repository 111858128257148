import { Button, Divider, Tabs } from "antd";
import { CUSTOMER_KEY } from "components/customer/CustomerDetail";
import { useCustomer } from "components/customer/context/customer";
import OrderedAll from "components/customer/orderedTab/orderedAll";
import ReturnComplain from "components/customer/orderedTab/returnComplain";
import CancelRefund from "components/customer/orderedTab/cancelRefund";
import { CUSTOMER_PATH } from "config/path";
import qs from "qs";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import PrivateLayout from "../../layout/PrivateLayout";
import MemberTab from "./MemberTab";
import "./PurchaseHistory.scss";

const DividerCustomMargin = ({ size = 0 }) => (
  <Divider style={{ margin: `${size}px 0px` }} />
);
const Dot = ({ color = "#52C41A" }) => (
  <svg
    width="7"
    height="6"
    viewBox="0 0 7 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_32074_43565)">
      <rect
        x="0.997559"
        width="6.0025"
        height="6.0015"
        rx="3.00075"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0_32074_43565">
        <rect
          x="0.997559"
          width="6.0025"
          height="6.0015"
          rx="3.00075"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
);

export const handleCallApiOrderedList = async (
  id,
  valueDebounceSearch,
  page,
  setHasMore,
  setOrderedList,
  getOrderedList
) => {
  await getOrderedList(id, valueDebounceSearch, page).then(
    ({ data, last_page, current_page }) => {
      if (current_page >= last_page) {
        setHasMore(false);
      } else {
        setHasMore(true);
      }

      setOrderedList((prev) =>
        current_page !== 1 ? [...prev, ...data] : data
      );
    }
  );
};

const ListOrder = () => {
  const { t, i18n } = useTranslation("member");
  const history = useHistory();
  const { id } = useParams();

  const [keyword, setKeyWord] = useState("");
  const location = useLocation();
  const initialFilter = qs.parse(location.search.substr(1));
  Object.keys(initialFilter).forEach((key) => {
    if (["page", "per_page"].indexOf(key) !== -1) {
      initialFilter[key] = Number(initialFilter[key]);
    }
  });

  const baseParams = useMemo(
    () => ({
      ...initialFilter,
    }),
    []
  );

  const [params, setParams] = useState(baseParams);

  const {
    valueDebounceSearch,
    getOrderedList,
    setOrderedList,
    page,
    setCurrentTab,
    setPage,
    currentTab,
    setHasMore,
    setLoading,
    loading,
  } = useCustomer();

  const tabContentRef = useRef(null);

  useEffect(() => {
    if (id) {
      setLoading(true);
      handleCallApiOrderedList(
        id,
        "",
        1,
        setHasMore,
        setOrderedList,
        getOrderedList
      );
      setPage(1);
    }
  }, [id, currentTab]);

  const handleChangeTab = (key) => {
    setOrderedList([]);
    setCurrentTab(key);
  };

  const renderForm = () => {
    return (
      <>
        <MemberTab defaultActiveKey="list_order" />
        <div
          className="site-layout-background pos-relative"
          style={{ width: "95%", padding: "24px 232px" }}
        >
          <div style={{ backgroundColor: "#F5F5F5", padding: "24px" }}>
            <div
              style={{
                backgroundClip: "content-box",
                borderRadius: "8px",
                marginBottom: "8px",
              }}
            >
              <div>
                <Tabs
                  destroyInactiveTabPane
                  tabBarStyle={{
                    padding: "0 10px",
                    backgroundColor: "#fff",
                    borderRadius: 8,
                  }}
                  defaultActiveKey={CUSTOMER_KEY.ALL}
                  onChange={(key) => handleChangeTab(key)}
                >
                  <Tabs.TabPane
                    tab={
                      <div className="flex-center">
                        <span className="mg-r-4">{t("Tất cả")}</span>
                      </div>
                    }
                    key={CUSTOMER_KEY.ALL}
                  >
                    <div ref={tabContentRef}>
                      <OrderedAll />
                    </div>
                  </Tabs.TabPane>
                  <Tabs.TabPane
                    tab={
                      <div className="flex-center">
                        <span className="mg-r-4">{t("Huỷ đơn/Hoàn Tiền")}</span>
                      </div>
                    }
                    key={CUSTOMER_KEY.CANCEL_REFUND}
                  >
                    <CancelRefund />
                  </Tabs.TabPane>
                  <Tabs.TabPane
                    tab={
                      <div className="flex-center">
                        <span className="mg-r-4">
                          {t("Trả hàng/Khiếu nại/Hoàn tiền")}
                        </span>
                      </div>
                    }
                    key={CUSTOMER_KEY.RETURN_COMPLAIN}
                  >
                    <ReturnComplain />
                  </Tabs.TabPane>
                </Tabs>
              </div>
            </div>
          </div>
          <Button
            htmlType="button"
            id="btn-solid"
            style={{ position: "absolute", right: "6vw", bottom: "-60px" }}
            onClick={() => {
              history.push(CUSTOMER_PATH);
            }}
          >
            {t("Quay lại")}
          </Button>
        </div>
      </>
    );
  };
  return (
    <PrivateLayout breadcrumbs={[t("customer_title"), t("breadcum_detail")]}>
      {renderForm()}
    </PrivateLayout>
  );
};

export default ListOrder;
