import { Redirect, Route, useLocation } from "react-router-dom";
import NotFound from "../components/permission/NotFound";
import Unauthorized from "../components/permission/Unauthorized";
import { ROLE_ADMIN, ROLE_HEAD_SHOP } from "../config/const";
import {
    bindParams,
    getEcPermissions,
    getMallId,
    getPermissions,
    getRole,
    getUserTenantId,
    isLogin,
} from "../config/function";
import {
    ADMIN,
    ADMIN_LOGIN,
    CHALLENGE,
    DASHBOARD,
    ESURVEY_CREATE_PATH,
    ESURVEY_DETAIL_PATH,
    TENANT_DETAIL_PATH,
    USER_PROFILE_PATH
} from "../config/path";
import ChallengeFormContextProvider from "../context/challenges/ChallengeFormContext";

const PrivateRoute = ({
    component: Component,
    isAdminMall,
    isTenantHead,
    isBrand,
    isCS,
    allowRoles,
    allowPermissions,
    path,
    ...rest
}) => {
    const role = getRole();
    const mallId = getMallId();
    const tenantId = getUserTenantId();
    const permissons = [...getEcPermissions(), ...getPermissions()];

    const checkPermission = (allowPermissions) => {
        return allowPermissions.some((permission) => permissons.includes(permission))
    }

    const pathname = useLocation()?.pathname;

    return (
        <Route
            {...rest}
            render={(props) =>
                !isLogin() ? (
                    <Redirect to={ADMIN_LOGIN} />
                ) : (isAdminMall && role === ROLE_ADMIN) ? (
                    <Unauthorized />
                ):([ DASHBOARD].includes(pathname)) ? 
                    <Redirect to={USER_PROFILE_PATH} />
                 : ([ ADMIN, `${ADMIN}/`].includes(pathname)) ? (
                    <Redirect to={USER_PROFILE_PATH} />
                ) : (!isTenantHead && role === ROLE_HEAD_SHOP) ? (
                    <Redirect to={bindParams(TENANT_DETAIL_PATH, { mall_id: mallId, tenant_id: tenantId })} />
                ) : path && (path?.includes(CHALLENGE) || path?.includes(ESURVEY_CREATE_PATH) || path?.includes(ESURVEY_DETAIL_PATH)) ? (
                    <ChallengeFormContextProvider>
                        <Component {...props} />
                    </ChallengeFormContextProvider>
                ) : (allowRoles?.length > 0 && !allowRoles.includes(role)) || (allowPermissions?.length > 0 && !checkPermission(allowPermissions)) ? (<Unauthorized />) : path ? (
                    <Component {...props} />
                ) : (
                    <NotFound />
                )
            }
        />
    )
}

export default PrivateRoute;
