import React, { useState, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Layout,
  Menu,
  Breadcrumb,
  Avatar,
  Space,
  Dropdown,
  Tooltip,
  Badge,
} from "antd";
import {
  RobotOutlined,
  SettingOutlined,
  SolutionOutlined,
  GiftOutlined,
  PlayCircleOutlined,
  FileDoneOutlined,
  MoneyCollectOutlined,
  DropboxOutlined,
  ToolOutlined,
  UserOutlined,
  BellOutlined,
  GlobalOutlined,
  FileSyncOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import Sider from "antd/es/layout/Sider";
import { IconEng, IconLogo, IconVi } from "../common/Icon/Icon";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import {
  ADMIN_LOGIN,
  CHALLENGE,
  BANNER_PATH,
  EVENT,
  FANCLUB,
  MALL_PATH,
  MALL_REPORT,
  TENANT_PATH,
  REVIEW_PATH,
  EARNINGRULE_PATH,
  REDEEM_CAMPAIGN_PATH,
  CUSTOMER_PATH,
  COMMENT,
  DASHBOARD_ANALYTICS,
  FOLLOW_PATH,
  SMS_HISTORY,
  SEARCH_PARKING_PATH,
  STATUS_PARKING_PATH,
  CAMPAIGN,
  DRAW_HISTORY,
  REQUEST,
  QUIZ_GAME_CAMPAIGN,
  QUIZ_GAME_REPORT,
  QUIZ_GAME_QUESTION,
  PRODUCT_LIST,
  BRAND_PATH,
  COUPON_LIST,
  TAX_PATH,
  PRODUCT_CATEGORY_LIST_PATH,
  CSTICKET_LIST,
  DISCOUNT_APPROVED_LIST,
  ORDER_WAIT_CONFIRM_LIST,
  TENANT_CAMPAIGN_MANAGE_PATH,
  REPORT_GEO_DISTRICT,
  REPORT_GEO_WARD,
  REPORT_RETENTION_TIME,
  REPORT_RETENTION_GROUP,
  CHECK_OTP,
  FACILITY_LIST,
  BOOKING_LIST,
  USER_OVERLAP,
  COMPARATIVE_COHORT,
  REPORT_GEO_PROVINCE,
  SUB_ADMIN_PATH,
  REPORT_GEO_DISTANCE,
  NOTIFICATION_LIST,
  ESURVEY,
  WP_EVENT,
  WP_BANNER,
  WP_NEWS,
  WP_POPUP,
  WP_PROMOTION,
  WP_SERVICE,
  WP_TENANT,
  WP_CATEGORY,
  WP_LOCATION,
  WP_FLOORMAP,
  WP_FACILITY,
  WP_TAG,
  WP_RECRUITMENT,
  WP_COLLECTION,
  NOTIFICATIONS,
  USER_ACCOUNTS_PATH,
  MALL_DETAIL_PATH,
  ECOM_BANNER_SETTING,
  REPORT_ORDER_DETAIL,
  REPORT_ORDER_PAYMENT,
  REPORT_ORDER_REFUND,
  REPORT_ORDER_RETURN,
  REPORT_ORDER_TRANSPORT,
  REPORT_ORDER_COMPLAINT,
  REPORT_ORDER_DAILY,
  REPORT_ORDER_MONTHLY,
  REPORT_ORDER_SETTLEMENT,
  REPORT_PRODUCT_PHYSIC,
  REPORT_PRODUCT_EGIFT,
  REPORT_COUPON_CAMPAIGN,
  REPORT_TAX_010,
  REPORT_TAX_011,
  REPORT_TAX_012,
  REPORT_TAX_013,
  REPORT_PAYOO_TOKEN,
  REPORT_TICKET,
  REPORT_DAILY_SALES,
  REPORT_SETTLEMENT_PERIOD,
  REPORT_COD_ASPIRE,
  REPORT_COD_ASPIRE_UPLOAD,
  USER_ACCOUNT_DETAIL_PATH,
  MALL_DETAIL_ACCOUNT,
  TENANT_STAFF_DETAIL_PATH,
  DISCOUNT_LIST,
  REVENUE_PATH,
  USER_PROFILE_PATH,
  TENANT_DETAIL_PATH,
  WP_DISHES,
  WP_APPLICANT,
} from "../config/path";
import { useTranslation } from "react-i18next";
import {
  AUTH_TOKEN,
  EN,
  KEY_LANGUAGE,
  MALL_BINHDUONG_ID,
  MALL_BT_ID,
  MALL_HD_ID,
  MALL_HP_ID,
  MALL_LB_ID,
  MALL_TP_ID,
  MALL_HU_ID,
  PAGE_LIMIT,
  ROLE_ADMIN,
  ROLE_MALL,
  ROLE_HEAD_SHOP,
  ROLE_PARKING_ADMIN,
  USER_INFO,
  VI,
  MENU_KEY,
  ROLE_STAFF,
  ROLE_BRAND,
  ROLE_CS,
  ROLE_SUB_ADMIN,
  ROLE_MALL_ACCOUNTING,
  ROLE_PLATFORM_ACCOUNTING,
  JOIN_EC,
} from "../config/const";
import {
  bindParams,
  checkIsJoinEc,
  getAccountId,
  getCurrentLanguage,
  getEcPermissions,
  getMallId,
  getPermissions,
  getRole,
  getUserName,
  getUserTenantId,
} from "../config/function";
import i18n from "i18next";
import avatar from "../assets/img/avatar.png";
import { postAxios } from "../Http";
import { API_LOGOUT } from "../config/endpointApi";
import IconBinhDuong from "../common/Icon/IconBinhDuong";
import IconHaDong from "../common/Icon/IconHaDong";
import IconHaiPhong from "../common/Icon/IconHaiPhong";
import IconBinhTan from "../common/Icon/IconBinhTan";
import IconLB from "../common/Icon/IconLB";
import IconTanPhu from "../common/Icon/IconTanPhu";
import { AppContext } from "../context/AppContext";
import { useNotification } from "context/notifications/NotificationContext";
import DropDownNotification from "components/manualNotification/DropDownNotification";
import {
  BrandPermissions,
  CSticketPermissions,
  CouponPermissions,
  CustomerPermissions,
  DisountPermissions,
  MallPermissions,
  OrderPermissions,
  PlatformPermissions,
  ProductCatePermissions,
  ProductPermissions,
  TaxPermissions,
  TenantPermissions,
} from "config/permissionsList";
import TenantTabData from "components/tenants/dataTabTenant/TenantTabData";
import { clsx } from "clsx";

const LIST_MALL_ID = [
  MALL_HD_ID,
  MALL_BINHDUONG_ID,
  MALL_HP_ID,
  MALL_BT_ID,
  MALL_TP_ID,
  MALL_LB_ID,
];
const { SubMenu } = Menu;

const PrivateLayout = (props) => {
  const { breadcrumbs, children } = props;
  const { handleSelectLanguage, openedMenus, setOpenMenus } =
    useContext(AppContext);
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation("common");
  const role = getRole();
  const join_ec = checkIsJoinEc();
  const permissions = getPermissions();
  const userPermissions = getEcPermissions();
  const mallId = getMallId();
  const accountId = getAccountId();
  const tenantId = getUserTenantId();
  const [visible, setVisible] = useState(false);
  const [visibleLogout, setVisibleLogout] = useState(false);
  const tenantTab = TenantTabData();
  const adminName = getUserName();
  const { unread_count, getNotifications, page, lastPage } = useNotification();

  useEffect(() => {
    if (page <= lastPage) {
      getNotifications("list", page);
    }
  }, [page]);

  const falicityBooking = {
    key: "Facility Booking",
    id: "facility",
    name: t("Quản lý mượn xe nôi - xe lăn"),
    roles: [ROLE_MALL],
    rolesOnly: true,
    children: [
      { key: FACILITY_LIST, name: t("Quản lý thiết bị"), id: "device" },
      { key: BOOKING_LIST, name: t("Quản lý booking"), id: "booking" },
      // { key: BOOKING_REPORT, name: t("Báo cáo") },
    ],
    icon: <FileDoneOutlined />,
  };

  const wpConvert =
    [MALL_BT_ID, MALL_TP_ID, MALL_BINHDUONG_ID, MALL_HU_ID].indexOf(mallId) >=
      0 && [ROLE_MALL, ROLE_HEAD_SHOP, ROLE_SUB_ADMIN].indexOf(role) >= 0
      ? {
          key: "Wordpress Convert",
          id: "wpconvert",
          name: t("Quản lý website"),
          roles: [ROLE_MALL, ROLE_HEAD_SHOP, ROLE_SUB_ADMIN],
          icon: <GlobalOutlined />,
          rolesOnly: true,
          children: [],
        }
      : undefined;

  const commonWpConvert = [
    {
      key: WP_BANNER,
      name: t("banners"),
      id: "banners",
    },
    {
      key: WP_POPUP,
      name: t("popups"),
      id: "popups",
    },
    {
      key: WP_SERVICE,
      name: t("services"),
      id: "services",
    },
    {
      key: WP_FACILITY,

      name: t("Free services & facilities"),
      id: "free_services",
    },
    {
      key: WP_CATEGORY,
      name: t("common:category"),
      id: "wp_category",
    },
    {
      key: WP_TENANT,
      name: t("tenants"),
      id: "wp_tenants",
    },
    {
      key: WP_COLLECTION,
      name: t("galleries"),
      id: "collections",
    },
    {
      key: WP_FLOORMAP,
      name: t("floorMap"),
      id: "floormaps",
    },
    {
      key: WP_LOCATION,
      name: t("locations"),
      id: "locations",
    },
    {
      key: WP_EVENT,
      name: t("events"),
      id: "events",
    },
    {
      key: WP_PROMOTION,
      name: t("promotions"),
      id: "promotions",
    },
    { key: WP_NEWS, name: t("news"), id: "news", roles: [ROLE_MALL] },
    { key: WP_TAG, name: t("tag"), id: "tag", roles: [ROLE_MALL] },
  ];

  const tenantWpConvert = [
    {
      key: WP_RECRUITMENT,
      name: t("recruitments"),
      id: "recruitment",
    },
    ...(mallId === MALL_HU_ID
      ? [
          { key: WP_APPLICANT, name: t("applicant"), id: "appliants" },
          { key: WP_DISHES, name: t("dish"), id: "dishes" },
        ]
      : []),
  ];

  const commonReport = [
    {
      key: REPORT_ORDER_DAILY,
      name: t("reportByStore:report_order_daily"),
      id: "order_daily",
    },
    {
      key: REPORT_ORDER_MONTHLY,
      name: t("reportByStore:report_order_monthly"),
      id: "order_daily",
    },
    {
      key: REPORT_ORDER_REFUND,
      name: t("reportByStore:report_order_refund"),
      id: "order_refund",
    },
    {
      key: REPORT_ORDER_RETURN,
      name: t("reportByStore:report_order_return"),
      id: "order_returned",
    },
    {
      key: REPORT_ORDER_TRANSPORT,
      name: t("reportByStore:report_order_transport"),
      id: "order_transport",
    },
    {
      key: REPORT_PRODUCT_PHYSIC,
      name: t("reportByStore:report_product_physic"),
      id: "report_product_physic",
    },
    {
      key: REPORT_PRODUCT_EGIFT,
      name: t("reportByStore:report_product_egift"),
      id: "report_product_egift",
    },
    {
      key: REPORT_COUPON_CAMPAIGN,
      name: t("reportByStore:report_coupon_campaign"),
      id: "coupon_campaign",
    },
  ];

  const reportMallMenu = [
    {
      key: REPORT_ORDER_DETAIL,
      name: t("reportByStore:report_order_detail"),
      id: "order_detail",
    },
    {
      key: REPORT_ORDER_PAYMENT,
      name: t("reportByStore:report_order_payment"),
      id: "order_payment",
    },
    {
      key: REPORT_ORDER_COMPLAINT,
      name: t("reportByStore:report_order_complaint"),
      id: "order_complaint",
    },
    {
      key: REPORT_TICKET,
      name: t("reportByStore:report_ticket"),
      id: "rp_ticket",
    },
    {
      key: REPORT_ORDER_SETTLEMENT,
      name: t("reportByStore:report_order_settlement"),
      id: "order_settlement",
    },
    ...commonReport,
  ];

  const reportTax = [
    {
      key: REPORT_TAX_010,
      name: t("reportByStore:report_TAX_010"),
      id: "TAX_010",
    },
    {
      key: REPORT_TAX_011,
      name: t("reportByStore:report_TAX_011"),
      id: "TAX_011",
    },
    {
      key: REPORT_TAX_012,
      name: t("reportByStore:report_TAX_012"),
      id: "TAX_012",
    },
    {
      key: REPORT_TAX_013,
      name: t("reportByStore:report_TAX_013"),
      id: "TAX_013",
    },
  ];

  const reportPlatformAdminMenu = [
    {
      key: REPORT_ORDER_DETAIL,
      name: t("reportByStore:report_order_detail"),
      id: "order_detail",
    },
    {
      key: REPORT_ORDER_PAYMENT,
      name: t("reportByStore:report_order_payment"),
      id: "order_payment",
    },
    {
      key: REPORT_PAYOO_TOKEN,
      name: t("reportByStore:report_payoo_token"),
      id: "payoo_token",
    },
    {
      key: REPORT_TICKET,
      name: t("reportByStore:report_ticket"),
      id: "rp_ticket",
    },
  ];

  const reportMallAccountingMenu = [
    {
      key: REPORT_ORDER_SETTLEMENT,
      name: t("reportByStore:report_order_settlement"),
      id: "order_settlement",
    },
  ];

  const reportMenu = {
    key: "report_ec",
    id: "report_ec",
    name: t("report_ec"),
    icon: <FileSyncOutlined />,
    roles: [
      ROLE_MALL,
      ROLE_ADMIN,
      ROLE_HEAD_SHOP,
      ROLE_PLATFORM_ACCOUNTING,
      ROLE_BRAND,
      // ROLE_MALL_ACCOUNTING,
    ],
    rolesOnly: true,
    children: [],
  };

  const locale = getCurrentLanguage();

  const isHeadShop = role === ROLE_HEAD_SHOP;

  let menu_sub_admin;
  let menus = [
    {
      key: "lifestyle",
      id: "lifestyle",
      name: t("Lifestyle"),
      children: [
        { key: FANCLUB, name: t("Customer reviews"), id: "customer_reviews" },
        { key: EVENT, name: t("event"), id: "events" },
        { key: CHALLENGE, name: t("challenge"), id: "challenges" },
        { key: BANNER_PATH, name: t("banner-title"), id: "banners" },
      ],
      icon: <RobotOutlined />,
    },
    {
      key: "rewards",
      name: t("Rewards"),
      id: "rewards",
      children: [
        { key: CUSTOMER_PATH, name: t("list_customers"), id: "customers" },
        {
          key: EARNINGRULE_PATH,
          name: t("coin_accumulation_rules"),
          id: "earning_rules",
        },
        {
          key: REDEEM_CAMPAIGN_PATH,
          name: t("Quản lý Quy tắc Đổi xu"),
          id: "redeem_rules",
        },
        {
          key: DASHBOARD_ANALYTICS,
          name: t("Báo cáo hệ thống"),
          id: "reports",
        },
      ],
      icon: <GiftOutlined />,
    },
    {
      key: "staff",
      type: "all",
      name: t("Staff recommend"),
      id: "staff_recommend",
      children: [
        { key: TENANT_PATH, name: t("tenant_manage"), id: "tenant_manage" },
      ],
      icon: <SolutionOutlined />,
    },
    {
      key: "general",
      name: t("Thiết lập chung"),
      id: "setting",
      children: [
        { key: TENANT_PATH, name: t("tenant_manage"), id: "tenants" },
        { key: SUB_ADMIN_PATH, name: t("Quản lý Sub admin"), id: "sub_admins" },
      ],
      icon: <SettingOutlined />,
    },
    {
      key: "request",
      type: "all",
      id: "request_management",
      name: t("member:Quản lý yêu cầu"),
      children: [{ key: REQUEST, name: t("member:Danh sách yêu cầu"), id: "" }],
      icon: <FileDoneOutlined />,
    },
    {
      key: "manual_notis",
      name: t("Manual Notification"),
      id: "manual_notis",
      children: [
        { key: NOTIFICATION_LIST, name: t("push noti"), id: "send_noti" },
        { key: NOTIFICATIONS, name: t("notification"), id: "notification" },
      ],
      icon: <BellOutlined />,
    },
    {
      key: "survey",
      name: t("E-Survey"),
      id: "survey",
      type: "all",
      children: [{ key: ESURVEY, name: t("E-Survey"), id: "survey" }],
      icon: <FileDoneOutlined />,
    },
    {
      key: "product",
      name: t("productCategory:products"),
      children: [{ key: PRODUCT_LIST, name: t("productCategory:products") }],
      icon: <DropboxOutlined />,
    },
    {
      key: "orders",
      name: t("orders:order"),
      children: [{ key: ORDER_WAIT_CONFIRM_LIST, name: t("orders:order") }],
      icon: <DropboxOutlined />,
    },
  ];

  let luckyMenu = {
    key: "luckydraw",
    name: t("Lucky Draw"),
    id: "lucky_draw",
    roles: [ROLE_MALL],
    rolesOnly: true,
    children: [
      { key: CAMPAIGN, name: t("campaign_setting"), id: "campaigns" },
      { key: DRAW_HISTORY, name: t("shake_history"), id: "shake_history" },
    ],
    icon: <PlayCircleOutlined />,
  };

  let userTrackingMenu = {
    id: "user_tracking",
    key: "User tracking & Geo visualization",
    name: t("User tracking & Geo visualization"),
    roles: [ROLE_MALL, ROLE_ADMIN],
    rolesOnly: true,
    children: [
      {
        key: REPORT_GEO_DISTANCE,
        name: t("report_by_distance"),
        id: "reports",
      },
      {
        key: REPORT_GEO_PROVINCE,
        name: t("geo_visualization_by_province"),
        id: "reports",
      },
      {
        key: REPORT_GEO_DISTRICT,
        name: t("geo_visualization_by_district"),
        id: "reports",
      },
      {
        key: REPORT_GEO_WARD,
        name: t("geo_visualization_by_ward"),
        id: "reports",
      },
      {
        key: REPORT_RETENTION_TIME,
        name: t("monthly_retention"),
        id: "reports",
      },
      { key: REPORT_RETENTION_GROUP, name: t("monthly_cohort"), id: "reports" },
      { key: USER_OVERLAP, name: t("user_overlap"), id: "reports" },
      { key: COMPARATIVE_COHORT, name: t("comparative_cohort"), id: "reports" },
      { key: CHECK_OTP, name: t("check_otp"), id: "otp" },
    ],
    icon: <FileDoneOutlined />,
  };

  // Mall Admin
  if (role === ROLE_MALL) {
    menus[2].children = [
      { key: REVIEW_PATH, name: t("post_manage") },
      { key: TENANT_PATH, name: t("tenant_manage") },
      { key: COMMENT, name: t("cmt_manage") },
      { key: FOLLOW_PATH, name: t("follow_list") },
      { key: MALL_REPORT, name: t("report_by_store") },
      { key: TENANT_CAMPAIGN_MANAGE_PATH, name: t("campaign_manage") },
    ];
    //menus[3].children.push({ key: TRANSACTION_PATH, name: t('common:transaction') })
    menus[3].children.push([
      {
        key: bindParams(MALL_DETAIL_PATH, { id: mallId }),
        name: t("mall_manage"),
      },
      {
        key: REVENUE_PATH,
        name: t("revenue_dashboard"),
        id: "revenue_dashboard",
      },
    ]);
    menus.push({
      key: "promotion",
      name: t("promotion"),
      children: [
        { key: COUPON_LIST, name: t("coupon:coupon") },
        { key: DISCOUNT_LIST, name: t("discount") },
      ],
      icon: <MoneyCollectOutlined />,
    });
    menus.splice(4, 0, luckyMenu);
    // menus.splice(5, 0, luckyMenu);
    menus.push(userTrackingMenu);
    menus.push(falicityBooking);

    if (wpConvert) {
      wpConvert.children = [...commonWpConvert, ...tenantWpConvert];
      menus.push(wpConvert);
    }
    
    // add report for mall admin
    reportMenu.children = [...reportMenu.children, ...reportMallMenu];
    menus.push(reportMenu);
  }

  if (role === ROLE_MALL_ACCOUNTING) {
    reportMenu.children = [...reportMenu.children, ...reportPlatformAdminMenu];
    menus[3].children.push({
      key: bindParams(MALL_DETAIL_ACCOUNT, {
        mall_id: mallId,
        accountId: accountId,
      }),
      name: t("mall_manage"),
    });
    menus[3].children.push({
      key: REVENUE_PATH,
      name: t("revenue_dashboard"),
      id: "revenue_dashboard",
    });

    menus.splice(4, 0, luckyMenu);
  }

  // Tenant Admin, missing brand permission
  if (isHeadShop || role === ROLE_STAFF) {
    if (wpConvert) wpConvert.children = [...tenantWpConvert];
    reportMenu.children = [
      ...commonReport,
      {
        key: REPORT_ORDER_SETTLEMENT,
        name: t("reportByStore:report_order_settlement"),
        id: "order_settlement",
      },
    ];

    menus = [
      {
        key: "staff",
        name: t("Staff recommend"),
        children:
          role === ROLE_STAFF
            ? [
                {
                  key: bindParams(TENANT_STAFF_DETAIL_PATH, {
                    mall_id: mallId,
                    tenant_id: tenantId,
                    id: accountId,
                  }),
                  name: t("tenant_manage"),
                },
              ]
            : [{ key: TENANT_PATH, name: t("tenant_manage") }],
        icon: <SolutionOutlined />,
      },
      {
        key: "promotion",
        name: t("promotion"),
        children: [
          { key: COUPON_LIST, name: t("coupon:coupon") },
          { key: DISCOUNT_APPROVED_LIST, name: t("discount") },
        ],
        icon: <MoneyCollectOutlined />,
      },
      {
        key: "product",
        name: t("Product"),
        children: [{ key: PRODUCT_LIST, name: t("product_list") }],
        icon: <DropboxOutlined />,
      },
      {
        key: "orders",
        name: t("orders:order"),
        children: [{ key: ORDER_WAIT_CONFIRM_LIST, name: t("orders:order") }],
        icon: <DropboxOutlined />,
      },
      {
        key: "cs-ticket",
        name: t("CS Tickets"),
        children: [{ key: CSTICKET_LIST, name: t("CS Tickets") }],
        icon: <ToolOutlined />,
      },
      {
        key: "manual_notis",
        name: t("Manual Notification"),
        id: "manual_notis",
        children: [
          { key: NOTIFICATIONS, name: t("notification"), id: "notification" },
        ],
        icon: <BellOutlined />,
      },
    ];

    if (isHeadShop) {
      menus.push({
        key: "general",
        name: t("Thiết lập chung"),
        children:
          role === ROLE_STAFF
            ? [
                {
                  key: bindParams(TENANT_STAFF_DETAIL_PATH, {
                    mall_id: mallId,
                    tenant_id: tenantId,
                    id: accountId,
                  }),
                  name: t("tenant_manage"),
                },
                {
                  key: REVENUE_PATH,
                  name: t("revenue_dashboard"),
                  id: "revenue_dashboard",
                },
              ]
            : [
                { key: TENANT_PATH, name: t("tenant_manage") },
                {
                  key: REVENUE_PATH,
                  name: t("revenue_dashboard"),
                  id: "revenue_dashboard",
                },
              ],
        icon: <SettingOutlined />,
      });
    }
  }

  if (role === ROLE_BRAND) {
    menus = [
      {
        key: "promotion",
        name: t("Promotion"),
        children: [
          { key: COUPON_LIST, name: t("coupon:coupon") },
          { key: DISCOUNT_APPROVED_LIST, name: t("discount") },
        ],
        icon: <FileDoneOutlined />,
      },
      {
        key: "product",
        name: t("Product"),
        children: [{ key: PRODUCT_LIST, name: t("product_list") }],
        icon: <DropboxOutlined />,
      },
      {
        key: "general",
        name: t("Thiết lập chung"),
        id: "setting",
        children: [
          { key: TENANT_PATH, name: t("tenant_manage"), id: "tenants" },
          { key: BRAND_PATH, name: t("Brands") },
        ],
        icon: <SettingOutlined />,
      },
      {
        key: "manual_notis",
        name: t("Manual Notification"),
        id: "manual_notis",
        children: [
          { key: NOTIFICATIONS, name: t("notification"), id: "notification" },
        ],
        icon: <BellOutlined />,
      },
      (reportMenu.children = [...commonReport]),
    ];
  }

  if (role === ROLE_CS) {
    menus = [
      {
        key: "cs-ticket",
        name: t("CS Tickets"),
        children: [{ key: CSTICKET_LIST, name: t("CS Tickets") }],
        icon: <ToolOutlined />,
      },
      {
        key: "orders",
        name: t("orders:order"),
        children: [{ key: ORDER_WAIT_CONFIRM_LIST, name: t("orders:order") }],
        icon: <DropboxOutlined />,
      },
      {
        key: "manual_notis",
        name: t("Manual Notification"),
        id: "manual_notis",
        children: [
          { key: NOTIFICATIONS, name: t("notification"), id: "notification" },
        ],
        icon: <BellOutlined />,
      },
      {
        key: "general",
        name: t("Thiết lập chung"),
        id: "setting",
        children: [
          {
            key: bindParams(MALL_DETAIL_ACCOUNT, {
              mall_id: mallId,
              accountId: accountId,
            }),
            name: t("mall_manage"),
          },
        ],
        icon: <SettingOutlined />,
      },
    ];
  }

  // Super Admin
  if (role === ROLE_ADMIN) {
    reportMenu.children = [...commonReport, ...reportPlatformAdminMenu];
    menus[2].children = [
      ...menus[2].children,
      { key: MALL_REPORT, name: t("report_by_store") },
    ];
    menus[3].children = [
      { key: MALL_PATH, name: t("mall_manage") },
      { key: BRAND_PATH, name: t("Brands") },
      ...menus[3].children,
      { key: TAX_PATH, name: t("tax_manage") },
      // { key: TRANSACTION_PATH, name: t('common:transaction') },
      { key: SMS_HISTORY, name: t("Quản lý SMS") },
      { key: ECOM_BANNER_SETTING, name: t("banner_manage") },
      {
        key: USER_ACCOUNTS_PATH,
        name: t("user_accounts"),
        id: "user_accounts",
      },
      // {
      //   key: REVENUE_PATH,
      //   name: t("revenue_dashboard"),
      //   id: "revenue_dashboard",
      // },
    ];
    menus[7].children = [
      {
        key: PRODUCT_CATEGORY_LIST_PATH,
        name: t("productCategory:category_list_manage"),
      },
      ...menus[7].children,
    ];
    menus.push(userTrackingMenu);
  }
  let quizGameMenu = {
    key: "quizgame",
    id: "quizgame",
    name: t("quiz_game"),
    roles: [ROLE_MALL],
    rolesOnly: true, //Only check for roles
    children: [
      {
        key: QUIZ_GAME_CAMPAIGN,
        name: t("quiz_game_campaigns"),
        id: "campaigns",
      },
      {
        key: QUIZ_GAME_QUESTION,
        name: t("quiz_game_questions"),
        id: "questions",
      },
      { key: QUIZ_GAME_REPORT, name: t("quiz_game_report"), id: "report" },
    ],
    icon: <RobotOutlined />,
  };

  if (role === ROLE_PLATFORM_ACCOUNTING) {
    reportMenu.children = [
      ...reportMenu.children,
      ...reportTax,
      ...reportMallAccountingMenu,
    ];
    menus[3].children = [
      ...menus[3].children,
      {
        key: bindParams(USER_ACCOUNT_DETAIL_PATH, { id: accountId }),
        name: t("user_accounts"),
        id: "user_accounts",
      },
      {
        key: REVENUE_PATH,
        name: t("revenue_dashboard"),
        id: "revenue_dashboard",
      },
    ];
  }

  // Parking lot Admin
  if (role === ROLE_PARKING_ADMIN) {
    menus = [
      { key: SEARCH_PARKING_PATH, name: t("search_parking") },
      { key: STATUS_PARKING_PATH, name: t("status_parking") },
    ];
  }

  // Mall Admin of mall "Tan Phu"
  if (role === ROLE_MALL && LIST_MALL_ID.includes(mallId)) {
    menus.splice(5, 0, quizGameMenu);
  }

  // Sub-Admin
  if (role === ROLE_SUB_ADMIN) {
    if (wpConvert) wpConvert.children = [...commonWpConvert, ...tenantWpConvert];
    menus[2].children = [
      { key: REVIEW_PATH, name: t("post_manage"), id: "post_manage" },
      { key: TENANT_PATH, name: t("tenant_manage"), id: "tenant_manage" },
      { key: COMMENT, name: t("cmt_manage"), id: "cmt_manage" },
      { key: FOLLOW_PATH, name: t("follow_list"), id: "follow_list" },
      { key: MALL_REPORT, name: t("report_by_store"), id: "report_by_store" },
      {
        key: TENANT_CAMPAIGN_MANAGE_PATH,
        name: t("campaign_manage"),
        id: "campaign_manage",
      },
    ];
    const all_menu = [
      ...menus,
      quizGameMenu,
      luckyMenu,
      userTrackingMenu,
      falicityBooking,
      wpConvert,
    ];
    
    let filter_menu = all_menu.filter((item) =>
      JSON.stringify(permissions)?.includes(item?.id)
    );

    filter_menu.map((sub_menu, index) => {
      if (sub_menu?.type !== "all") {
        const sub_menu_edit_index = index;
        const new_sub_children = [];
        sub_menu?.children.map((item, sub_index) => {
          if (
            JSON.stringify(permissions)?.indexOf(
              `${sub_menu.id}.${item?.id}`
            ) !== -1
          ) {
            new_sub_children.push(item);
          }
        });
        filter_menu[sub_menu_edit_index].children = new_sub_children;
      }
    });
    menu_sub_admin = filter_menu;
  }

  let master_menus = [
    {
      key: "lifestyle",
      id: "lifestyle",
      name: t("Lifestyle"),
      roles: [ROLE_ADMIN, ROLE_MALL],
      rolesOnly: true,
      children: [
        { key: FANCLUB, name: t("Customer reviews"), id: "customer_reviews" },
        { key: EVENT, name: t("event"), id: "events" },
        { key: CHALLENGE, name: t("challenge"), id: "challenges" },
        { key: BANNER_PATH, name: t("banner-title"), id: "banners" },
      ],
      icon: <RobotOutlined />,
    },
    {
      key: "rewards",
      name: t("Rewards"),
      id: "rewards",
      children: [
        {
          key: CUSTOMER_PATH,
          name: t("list_customers"),
          id: "customers",
          permissions: CustomerPermissions.ALL,
        },
        {
          key: EARNINGRULE_PATH,
          name: t("coin_accumulation_rules"),
          id: "earning_rules",
          roles: [ROLE_ADMIN, ROLE_MALL],
        },
        {
          key: REDEEM_CAMPAIGN_PATH,
          name: t("Quản lý Quy tắc Đổi xu"),
          id: "redeem_rules",
          roles: [ROLE_ADMIN, ROLE_MALL],
        },
        {
          key: DASHBOARD_ANALYTICS,
          name: t("Báo cáo hệ thống"),
          id: "reports",
          roles: [ROLE_ADMIN, ROLE_MALL],
        },
      ],
      icon: <GiftOutlined />,
    },
    {
      key: "staff",
      type: "all",
      name: t("Staff recommend"),
      id: "staff_recommend",
      children: [
        {
          key: REVIEW_PATH,
          name: t("post_manage"),
          roles: [ROLE_MALL],
        },
        {
          key: TENANT_PATH,
          name: t("tenant_manage"),
          id: "tenants",
          permissions: [TenantPermissions.LIST_TENANTS],
        },
        tenantId && {
          key: bindParams(
            tenantTab?.length > 0 ? tenantTab[0]?.path : TENANT_DETAIL_PATH,
            {
              mall_id: mallId,
              tenant_id: tenantId,
            }
          ),
          name: t("my_tenant_manage"),
          id: "my-tenants",
          permissions: TenantPermissions.ALL,
        },
        { key: COMMENT, name: t("cmt_manage"), roles: [ROLE_MALL] },
        {
          key: FOLLOW_PATH,
          name: t("follow_list"),
          roles: [ROLE_MALL],
        },
        {
          key: MALL_REPORT,
          name: t("report_by_store"),
          roles: [ROLE_ADMIN, ROLE_MALL],
        },
        {
          key: TENANT_CAMPAIGN_MANAGE_PATH,
          name: t("campaign_manage"),
          roles: [ROLE_MALL],
        },
      ],
      icon: <SolutionOutlined />,
    },
    {
      key: "ecomerce",
      name: "AEON MALL PLUS",
      id: "ecomerce",
      icon: <RobotOutlined />,
      children: [
        {
          key: TENANT_PATH,
          name: t("tenant_manage"),
          id: "tenants",
          permissions: [TenantPermissions.LIST_TENANTS],
        },
        tenantId && {
          key: bindParams(
            tenantTab?.length > 0 ? tenantTab[0]?.path : TENANT_DETAIL_PATH,
            {
              mall_id: mallId,
              tenant_id: tenantId,
            }
          ),
          name: t("my_tenant_manage"),
          id: "my-tenants",
          permissions: TenantPermissions.ALL,
        },
        {
          key: PRODUCT_LIST,
          name: t("products_list"),
          permissions: ProductPermissions.ALL,
        },
        {
          key: PRODUCT_CATEGORY_LIST_PATH,
          name: t("productCategory:category_list_manage"),
          permissions: ProductCatePermissions.ALL,
        },
        {
          key: COUPON_LIST,
          name: t("label_coupon"),
          permissions: CouponPermissions.ALL,
        },
        {
          key: DISCOUNT_LIST,
          name: t("label_discount"),
          permissions: DisountPermissions.ALL,
        },
        {
          key: ORDER_WAIT_CONFIRM_LIST,
          name: t("list_orders"),
          permissions: OrderPermissions.ALL,
        },
        {
          key: CSTICKET_LIST,
          name: t("CS Tickets"),
          permissions: CSticketPermissions.ALL,
        },
        {
          key: CUSTOMER_PATH,
          name: t("list_customers"),
          id: "customers",
          permissions: CustomerPermissions.ALL,
        },
        {
          key: TAX_PATH,
          name: t("tax_manage"),
          permissions: TaxPermissions.ALL,
        },
        {
          key: `${process.env.REACT_APP_TENANT_POLICY_STATIC_PAGE_URL}`,
          name: t("seller_rules"),
          isStaticLink: true,
        },
        mallId && {
          key: bindParams(MALL_DETAIL_PATH, { id: mallId }),
          name: t("my_mall_manage"),
          permissions: MallPermissions.ALL,
          id: "my_mall_manage",
        },
        {
          key: MALL_PATH,
          name: t("mall_manage"),
          permissions: [MallPermissions.LIST_MALLS],
        },
        {
          key: ECOM_BANNER_SETTING,
          name: t("banner_manage"),
          permissions: [PlatformPermissions.MANAGE_BANNER],
        },
        {
          key: REVENUE_PATH,
          name: t("revenue_dashboard"),
          roles: [ROLE_STAFF, ROLE_HEAD_SHOP, ROLE_MALL_ACCOUNTING, ROLE_BRAND],
        },
      ],
    },
    reportMenu,
    {
      key: "report_ec_mall_accounting",
      id: "report_ec_mall_accounting",
      name: t("report_ec"),
      icon: <FileSyncOutlined />,
      roles: [ROLE_MALL_ACCOUNTING],
      rolesOnly: true,
      children: [
        {
          key: REPORT_ORDER_SETTLEMENT,
          name: t("reportByStore:report_order_settlement"),
          id: "order_settlement",
        },
        {
          key: REPORT_DAILY_SALES,
          name: t("reportByStore:REPORT_DAILY_SALES"),
          id: "TAX_013",
        },
        {
          key: REPORT_SETTLEMENT_PERIOD,
          name: t("reportByStore:REPORT_SETTLEMENT_PERIOD"),
          id: "TAX_013",
        },
        {
          key: REPORT_COD_ASPIRE,
          name: t("reportByStore:REPORT_COD_ASPIRE"),
          id: "TAX_013",
        },
        {
          key: REPORT_COD_ASPIRE_UPLOAD,
          name: t("reportByStore:REPORT_COD_ASPIRE_UPLOAD"),
          id: "TAX_013",
        },
      ],
    },
    {
      key: "general",
      name: t("Thiết lập chung"),
      id: "setting",
      children: [
        // mallId && {
        //   key: bindParams(MALL_DETAIL_PATH, { id: mallId }),
        //   name: t("my_mall_manage"),
        //   permissions: MallPermissions.ALL,
        //   id: "my_mall_manage",
        // },
        {
          key: TENANT_PATH,
          name: t("tenant_manage"),
          id: "tenants",
          permissions: [TenantPermissions.LIST_TENANTS],
        },
        tenantId && {
          key: bindParams(
            tenantTab?.length > 0 ? tenantTab[0]?.path : TENANT_DETAIL_PATH,
            {
              mall_id: mallId,
              tenant_id: tenantId,
            }
          ),
          name: t("my_tenant_manage"),
          id: "my-tenants",
          permissions: TenantPermissions.ALL,
        },
        {
          key: BRAND_PATH,
          name: t("brands_manage"),
          permissions: BrandPermissions.ALL,
        },
        {
          key: USER_ACCOUNTS_PATH,
          name: t("user_accounts"),
          id: "user_accounts",
          permissions: [PlatformPermissions.MANAGE_ACCOUNT],
        },
        {
          key: SUB_ADMIN_PATH,
          name: t("Quản lý Sub admin"),
          id: "sub_admins",
          roles: [ROLE_MALL, ROLE_ADMIN],
        },
        {
          key: SMS_HISTORY,
          name: t("Quản lý SMS"),
          roles: [ROLE_ADMIN],
        },
      ],
      icon: <SettingOutlined />,
    },
    luckyMenu,
    quizGameMenu,
    {
      key: "request",
      type: "all",
      id: "request_management",
      name: t("member:Quản lý yêu cầu"),
      children: [
        {
          key: REQUEST,
          name: t("member:Danh sách yêu cầu"),
          id: "",
          roles: [ROLE_ADMIN, ROLE_MALL],
        },
      ],
      icon: <FileDoneOutlined />,
    },
    {
      key: "manual_notis",
      name: t("Manual Notification"),
      id: "manual_notis",
      children: [
        {
          key: NOTIFICATION_LIST,
          name: t("push noti"),
          id: "send_noti",
          roles: [ROLE_MALL, ROLE_ADMIN],
        },
      ],
      icon: <BellOutlined />,
    },
    {
      key: "survey",
      name: t("E-Survey"),
      id: "survey",
      type: "all",
      children: [
        {
          key: ESURVEY,
          name: t("E-Survey"),
          id: "survey",
          roles: [ROLE_MALL, ROLE_ADMIN],
        },
      ],
      icon: <FileDoneOutlined />,
    },
    userTrackingMenu,
    falicityBooking,
    wpConvert,
    {
      key: SEARCH_PARKING_PATH,
      name: t("search_parking"),
      roles: [ROLE_PARKING_ADMIN],
      rolesOnly: true,
    },
    {
      key: STATUS_PARKING_PATH,
      name: t("status_parking"),
      roles: [ROLE_PARKING_ADMIN],
      rolesOnly: true,
    },
  ];

  if ([ROLE_HEAD_SHOP, ROLE_STAFF].includes(role) && join_ec !== 1) {
    master_menus = master_menus?.filter(
      (item) => !["report_ec", "ecomerce"]?.includes(item?.key)
    );
  }
  // Function to check if a menu item should be rendered based on user permissions
  const shouldRenderMenuItem = (permissions, roles) => {
    // If no permissions or roles are defined, always render the menu item
    if (
      (!permissions || permissions.length === 0) &&
      (!roles || roles.length === 0)
    ) {
      return true;
    }
    // Check if the user has at least one required permission
    const hasPermission = permissions
      ? permissions.some((permission) => userPermissions.includes(permission))
      : true;
    // Check if the user has the required role
    const hasRole = roles ? roles.includes(role) : true;
    // Return true if user has at least one permission and the required role
    return hasPermission && hasRole;
  };

  // Function to render the menu recursively
  const renderMenu = (menuItems) => {
    return menuItems
      .map((menuItem) => {
        if (menuItem?.rolesOnly) {
          if (shouldRenderMenuItem(menuItem?.permissions, menuItem?.roles)) {
            return menuItem;
          } else {
            return null;
          }
        } else if (menuItem?.children) {
          const filteredChildren = menuItem.children.filter((child) =>
            shouldRenderMenuItem(child?.permissions, child?.roles)
          );
          if (filteredChildren.length === 0) {
            return null; // If no children to render, return null
          }
          return {
            ...menuItem,
            children: renderMenu(filteredChildren),
          };
        } else if (
          shouldRenderMenuItem(menuItem?.permissions, menuItem?.roles)
        ) {
          return menuItem;
        } else {
          return null;
        }
      })
      .filter(Boolean); // Filter out null values
  };

  // Render the menu
  const renderedMenu = renderMenu(master_menus);

  const handleChangeLanguage = (e) => {
    localStorage.setItem(KEY_LANGUAGE, e.key);
    i18n.changeLanguage(e.key).then(() => {
      setVisible(false);
    });
    handleSelectLanguage(e.key);
  };

  const handleLogout = () => {
    postAxios(API_LOGOUT, {}).then(() => {
      localStorage.removeItem(AUTH_TOKEN);
      localStorage.removeItem(USER_INFO);
      localStorage.removeItem(PAGE_LIMIT);
      localStorage.removeItem(MENU_KEY);
      localStorage.removeItem(JOIN_EC);
      history.push(ADMIN_LOGIN);
    });
  };

  const handleOpenChange = (open) => {
    if (open) {
      getNotifications("recent");
    }
  };

  const menu = (
    <Menu onClick={handleChangeLanguage} selectedKeys={locale}>
      <Menu.Item key={VI}>
        <Avatar src={<IconVi />} />
      </Menu.Item>
      <Menu.Item key={EN}>
        <Avatar src={<IconEng />} />
      </Menu.Item>
    </Menu>
  );

  const menuUser = (
    <Menu>
      <Menu.Item
        key={"my-account"}
        onClick={() => history.push(USER_PROFILE_PATH)}
        icon={<UserOutlined />}
      >
        {t("my_account")}
      </Menu.Item>
      <Menu.Item
        onClick={handleLogout}
        key={"logout"}
        icon={<LogoutOutlined />}
      >
        {t("logout")}
      </Menu.Item>
    </Menu>
  );

  const notificationMenu = (
    <Dropdown
      trigger={["click"]}
      onVisibleChange={handleOpenChange}
      overlay={
        <Menu className="notification">
          <Menu.Item>
            <DropDownNotification />
          </Menu.Item>
        </Menu>
      }
      placement="bottomRight"
    >
      <Badge count={unread_count} size={"small"}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
          }}
        >
          <svg
            width="17"
            height="19"
            viewBox="0 0 12 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.75 10.998H10.375V5.68555C10.375 3.48086 8.74531 1.65898 6.625 1.35586V0.748047C6.625 0.402734 6.34531 0.123047 6 0.123047C5.65469 0.123047 5.375 0.402734 5.375 0.748047V1.35586C3.25469 1.65898 1.625 3.48086 1.625 5.68555V10.998H1.25C0.973438 10.998 0.75 11.2215 0.75 11.498V11.998C0.75 12.0668 0.80625 12.123 0.875 12.123H4.25C4.25 13.0887 5.03438 13.873 6 13.873C6.96563 13.873 7.75 13.0887 7.75 12.123H11.125C11.1938 12.123 11.25 12.0668 11.25 11.998V11.498C11.25 11.2215 11.0266 10.998 10.75 10.998ZM6 12.873C5.58594 12.873 5.25 12.5371 5.25 12.123H6.75C6.75 12.5371 6.41406 12.873 6 12.873ZM2.75 10.998V5.68555C2.75 4.8168 3.0875 4.00117 3.70156 3.38711C4.31563 2.77305 5.13125 2.43555 6 2.43555C6.86875 2.43555 7.68438 2.77305 8.29844 3.38711C8.9125 4.00117 9.25 4.8168 9.25 5.68555V10.998H2.75Z"
              fill="#262626"
            />
          </svg>
        </div>
      </Badge>
    </Dropdown>
  );

  const genLogo = () => {
    if (role === ROLE_ADMIN) return <IconLogo />;
    switch (mallId) {
      case MALL_BINHDUONG_ID:
        return <IconBinhDuong />;
      case MALL_HD_ID:
        return <IconHaDong />;
      case MALL_HP_ID:
        return <IconHaiPhong />;
      case MALL_BT_ID:
        return <IconBinhTan />;
      case MALL_LB_ID:
        return <IconLB />;
      case MALL_TP_ID:
        return <IconTanPhu />;
      default:
        return <IconLogo />;
    }
  };

  const selectedKey = `/${location.pathname.split("/")[1]}/${
    location.pathname.split("/")[2]
  }`;
  const openMenu = menus.find((menu) => {
    const childIndex = menu?.children?.findIndex((item) => {
      return item?.key?.includes(selectedKey);
    });

    // const childIndex = menu.children.findIndex((item) => {
    //     return item.key.includes(selectedKey)
    // })

    return childIndex !== -1;
  });
  const openKey = openMenu ? openMenu.key : "lifestyle";

  let defaultOpenKeys = openedMenus;
  if (openedMenus.indexOf(openKey) === -1) {
    defaultOpenKeys = [...openedMenus, openKey];
  }

  return (
    <Layout style={{ minHeight: "100vh" }} hasSider={true}>
      {true && (
        <Sider
          theme={"dark"}
          breakpoint="xl"
          collapsedWidth="0"
          width={272}
          defaultCollapsed={window.innerWidth < "1200"}
        >
          <div
            className="logo"
            onClick={() => {
              let pathNavigate = CUSTOMER_PATH;
              if (role === ROLE_PARKING_ADMIN) {
                pathNavigate = SEARCH_PARKING_PATH;
              }
              if (role === ROLE_SUB_ADMIN) {
                pathNavigate = menu_sub_admin[0]?.children[0].key;
              }
              history.push(pathNavigate);
            }}
            // onClick={() => history.push(DASHBOARD)}
          >
            {genLogo()}
          </div>
          {role === ROLE_PARKING_ADMIN ? (
            <Menu
              defaultSelectedKeys={[
                `/${location.pathname.split("/")[1]}/${
                  location.pathname.split("/")[2]
                }`,
              ]}
              mode="inline"
            >
              {/* {menuItems.map((item) => ( */}
              {menus.map((item) => (
                <Menu.Item key={item.key}>
                  <NavLink to={item.key}>{item.name}</NavLink>
                </Menu.Item>
              ))}
            </Menu>
          ) : role === ROLE_SUB_ADMIN ? (
            <Menu
              defaultSelectedKeys={[
                `/${location.pathname.split("/")[1]}/${
                  location.pathname.split("/")[2]
                }`,
              ]}
              mode="inline"
              defaultOpenKeys={defaultOpenKeys}
              className="main-menu"
              onOpenChange={(openKeys) => setOpenMenus(openKeys)}
            >
              {menu_sub_admin.map((menu) => {
                return (
                  <SubMenu
                    key={menu.key}
                    icon={menu.icon}
                    title={
                      <Tooltip
                        title={menu.name?.length > 30 ? menu.name : undefined}
                      >
                        {menu.name}
                      </Tooltip>
                    }
                  >
                    {menu.children.map((item) => {
                      const linkSplitted = item.key.split("/");
                      return (
                        <Menu.Item
                          key={[
                            linkSplitted[0],
                            linkSplitted[1],
                            linkSplitted[2],
                          ].join("/")}
                        >
                          <Tooltip
                            title={
                              item.name?.length > 35 ? item.name : undefined
                            }
                          >
                            <NavLink to={item.key}>{item.name}</NavLink>
                          </Tooltip>
                        </Menu.Item>
                      );
                    })}
                  </SubMenu>
                );
              })}
            </Menu>
          ) : (
            <Menu
              defaultSelectedKeys={[
                `/${location.pathname.split("/")[1]}/${
                  location.pathname.split("/")[2]
                }`,
              ]}
              mode="inline"
              defaultOpenKeys={defaultOpenKeys}
              className="main-menu"
              onOpenChange={(openKeys) => setOpenMenus(openKeys)}
            >
              {renderedMenu.map((menu) => {
                return (
                  menu && (
                    <SubMenu
                      key={menu.key}
                      icon={menu.icon}
                      title={
                        <Tooltip
                          title={menu.name?.length > 30 ? menu.name : undefined}
                        >
                          {menu.name}
                        </Tooltip>
                      }
                    >
                      {menu.children.map((item) => {
                        let linkSplitted = "";
                        let navLink = "";
                        if (
                          item.requiredPermissions &&
                          !userPermissions.includes(item.requiredPermissions)
                        ) {
                          linkSplitted = item?.subkey?.split("/");
                          navLink = item?.subkey;
                        } else {
                          linkSplitted = item?.key?.split("/");
                          navLink = item?.key;
                        }

                        if (item.subMenu) {
                          return (
                            <SubMenu
                              key={item?.key}
                              icon={item.icon}
                              title={
                                <Tooltip
                                  title={
                                    item.name?.length > 30
                                      ? item.name
                                      : undefined
                                  }
                                >
                                  {item.name}
                                </Tooltip>
                              }
                            >
                              {item.children.map((subMN) => {
                                const linkSplitted = subMN.key.split("/");
                                return (
                                  <Menu.Item
                                    key={[
                                      linkSplitted && linkSplitted[0],
                                      linkSplitted && linkSplitted[1],
                                      linkSplitted && linkSplitted[2],
                                    ].join("/")}
                                  >
                                    <Tooltip
                                      title={
                                        subMN.name?.length > 35
                                          ? subMN.name
                                          : undefined
                                      }
                                    >
                                      <NavLink to={subMN.key}>
                                        {subMN.name}
                                      </NavLink>
                                    </Tooltip>
                                  </Menu.Item>
                                );
                              })}
                            </SubMenu>
                          );
                        }

                        return (
                          <Menu.Item
                            key={[
                              linkSplitted[0],
                              linkSplitted[1],
                              linkSplitted[2],
                            ].join("/")}
                            className={clsx({
                              not_highlight: item?.isStaticLink,
                            })}
                          >
                            <Tooltip
                              title={
                                item.name?.length > 35 ? item.name : undefined
                              }
                            >
                              {!item?.isStaticLink ? (
                                <NavLink to={navLink}>{item.name}</NavLink>
                              ) : (
                                <a
                                  href={navLink}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {item.name}
                                </a>
                              )}
                            </Tooltip>
                          </Menu.Item>
                        );
                      })}
                    </SubMenu>
                  )
                );
              })}
            </Menu>
          )}
        </Sider>
      )}

      <Layout className="site-layout">
        {role && (
          <Layout.Header className="site-layout-header">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div>
                <Breadcrumb style={{ margin: "16px 0" }}>
                  {breadcrumbs.map((item, key) => (
                    <Breadcrumb.Item
                      key={key}
                      className={
                        key === breadcrumbs.length - 2
                          ? "breadcrumb-separator-before-last"
                          : ""
                      }
                    >
                      {item}
                    </Breadcrumb.Item>
                  ))}
                </Breadcrumb>
              </div>
              <div>
                <Space size={"large"}>
                  {notificationMenu}
                  <Dropdown
                    overlay={menu}
                    trigger={["click"]}
                    onVisibleChange={(flag) => setVisible(flag)}
                    visible={visible}
                  >
                    <Avatar src={locale === VI ? <IconVi /> : <IconEng />} />
                  </Dropdown>
                  <Dropdown
                    overlay={menuUser}
                    trigger={["click"]}
                    onVisibleChange={(flag) => setVisibleLogout(flag)}
                    visible={visibleLogout}
                  >
                    <div className="d-flex align-items-center">
                      <div className="mg-r-8 poiter-cusor">{adminName}</div>
                      <Avatar width={30} height={30} src={avatar} />
                    </div>
                  </Dropdown>
                </Space>
              </div>
            </div>
          </Layout.Header>
        )}
        <Layout.Content
          style={{ margin: 16 }}
          className={isHeadShop ? "layout-no-sider" : ""}
        >
          {children}
        </Layout.Content>
      </Layout>
    </Layout>
  );
};

PrivateLayout.defaultProps = {
  breadcrumbs: [],
};

PrivateLayout.propTypes = {
  breadcrumbs: PropTypes.array.isRequired,
};
export default PrivateLayout;
