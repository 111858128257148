import React from 'react'

const IconReaction = ({
    width = 24,
    height = 24,
    viewBox = '0 0 24 24',
    fillSvg = 'none',
    fillPath = '#828282',
    onClick = () => {},
}) => {
    return (
        <svg width="56" height="24" viewBox="0 0 56 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_11174:71832)">
                <path
                    d="M50.7002 18.6994C54.4001 14.9995 54.4001 9.00077 50.7002 5.30089C47.0004 1.601 41.0017 1.601 37.3018 5.30089C33.6019 9.00077 33.6019 14.9995 37.3018 18.6994C41.0017 22.3992 47.0004 22.3992 50.7002 18.6994Z"
                    fill="url(#paint0_linear_11174:71832)"
                />
                <path
                    d="M48.9574 3.91455C49.9011 5.38726 50.4479 7.13895 50.4479 9.01735C50.4479 14.2491 46.2065 18.4916 40.9737 18.4916C39.1564 18.4916 37.4592 17.9792 36.0176 17.0922C37.7015 19.7209 40.648 21.4626 44.0013 21.4626C49.2331 21.4626 53.4756 17.2212 53.4756 11.9883C53.4745 8.57387 51.6672 5.58177 48.9574 3.91455Z"
                    fill="#FDAF53"
                />
                <path
                    d="M35.8138 12.9998C35.8138 8.14153 39.7518 4.20356 44.6101 4.20356C47.4499 4.20356 49.9741 5.54956 51.5824 7.63803C50.0641 5.00049 47.2165 3.22546 43.9554 3.22546C39.0971 3.22546 35.1592 7.16343 35.1592 12.0217C35.1592 14.0401 35.8394 15.8996 36.9831 17.3835C36.2395 16.093 35.8138 14.5959 35.8138 12.9998Z"
                    fill="#FFC38E"
                />
                <path
                    d="M41.8914 10.6192C42.0836 9.43519 41.2795 8.3196 40.0955 8.12744C38.9115 7.93529 37.7959 8.73933 37.6038 9.92333C37.4116 11.1073 38.2157 12.2229 39.3997 12.4151C40.5837 12.6072 41.6993 11.8032 41.8914 10.6192Z"
                    fill="white"
                />
                <path
                    d="M41.27 8.72389C40.8776 8.33821 40.3408 8.09924 39.7473 8.09924C39.346 8.09924 38.9726 8.20928 38.6502 8.39934C38.5847 8.48382 38.5424 8.57829 38.5324 8.68055C38.4902 9.13403 39.0748 9.55862 39.8384 9.63086C40.602 9.702 41.2555 9.39301 41.2978 8.93952C41.3045 8.86505 41.2933 8.79392 41.27 8.72389Z"
                    fill="#303030"
                />
                <path
                    d="M49.7907 11.8066C50.6388 10.9584 50.6388 9.58332 49.7907 8.73517C48.9425 7.88703 47.5674 7.88703 46.7193 8.73517C45.8711 9.58332 45.8711 10.9584 46.7193 11.8066C47.5674 12.6547 48.9425 12.6547 49.7907 11.8066Z"
                    fill="white"
                />
                <path
                    d="M49.7778 8.72389C49.3855 8.33821 48.8486 8.09924 48.2551 8.09924C47.8538 8.09924 47.4804 8.20928 47.1581 8.39934C47.0925 8.48381 47.0502 8.57829 47.0402 8.68055C46.998 9.13403 47.5826 9.55861 48.3462 9.63086C49.1098 9.70199 49.7634 9.393 49.8056 8.93952C49.8134 8.86505 49.8023 8.79392 49.7778 8.72389Z"
                    fill="#303030"
                />
                <path
                    d="M47.5265 14.1712C47.0741 13.7233 46.455 13.3943 45.7359 13.2187C45.079 13.0609 44.3732 13.0486 43.693 13.1809C43.0283 13.3098 42.3803 13.5788 41.8168 13.9589C41.2188 14.3669 40.7553 14.8548 40.4386 15.4083C40.3897 15.4928 40.403 15.5995 40.4697 15.6706C40.513 15.7162 40.5719 15.7406 40.632 15.7406C40.6653 15.7406 40.6986 15.7329 40.7298 15.7173L40.9376 15.615C41.4256 15.3739 41.8857 15.146 42.3536 14.9626C42.8883 14.7492 43.424 14.5903 43.9464 14.4902C44.4899 14.3858 45.0423 14.338 45.5869 14.3491C46.0927 14.3569 46.6306 14.418 47.3308 14.5469C47.4264 14.5647 47.5231 14.518 47.5687 14.4313C47.612 14.3457 47.5954 14.2401 47.5265 14.1712Z"
                    fill="#5E4939"
                />
            </g>
            <g clip-path="url(#clip1_11174:71832)">
                <path
                    d="M34.7002 18.6994C38.4001 14.9995 38.4001 9.00077 34.7002 5.30089C31.0004 1.601 25.0017 1.601 21.3018 5.30089C17.6019 9.00077 17.6019 14.9995 21.3018 18.6994C25.0017 22.3992 31.0004 22.3992 34.7002 18.6994Z"
                    fill="#FFD05D"
                />
                <path
                    d="M32.9574 3.91455C33.9011 5.38726 34.4479 7.13895 34.4479 9.01735C34.4479 14.2491 30.2065 18.4916 24.9737 18.4916C23.1564 18.4916 21.4592 17.9792 20.0176 17.0922C21.7015 19.7209 24.648 21.4626 28.0013 21.4626C33.2331 21.4626 37.4756 17.2212 37.4756 11.9883C37.4745 8.57387 35.6672 5.58177 32.9574 3.91455Z"
                    fill="#FDC453"
                />
                <path
                    d="M19.8138 12.9998C19.8138 8.14153 23.7518 4.20356 28.6101 4.20356C31.4499 4.20356 33.9741 5.54956 35.5824 7.63803C34.0641 5.00049 31.2165 3.22546 27.9554 3.22546C23.0971 3.22546 19.1592 7.16343 19.1592 12.0217C19.1592 14.0401 19.8394 15.8996 20.9831 17.3835C20.2395 16.093 19.8138 14.5959 19.8138 12.9998Z"
                    fill="#FFD77F"
                />
                <path
                    d="M24.1173 12.9764C23.8728 12.7397 23.4827 13.002 23.6083 13.3176C24.3085 15.0671 26.018 16.3019 28.0175 16.3019C29.9837 16.3019 31.6698 15.1071 32.3901 13.4043C32.5212 13.0942 32.1278 12.8252 31.8855 13.0598C30.4995 14.4047 27.5563 16.3175 24.1173 12.9764Z"
                    fill="#5E4939"
                />
                <path
                    d="M30.4464 9.92771C30.7143 10.5979 31.3545 11.0314 32.0758 11.0314C32.7816 11.0314 33.4163 10.6113 33.6919 9.95995C33.7442 9.83546 33.7031 9.6943 33.5908 9.61761C33.4785 9.54092 33.3329 9.55315 33.2351 9.64762C32.7094 10.1578 31.878 10.5668 30.9044 9.61983C30.8499 9.56648 30.7788 9.5387 30.7076 9.5387C30.6532 9.5387 30.5987 9.55426 30.5498 9.5876C30.4398 9.66207 30.3964 9.80212 30.4464 9.92771Z"
                    fill="#303030"
                />
                <path
                    d="M22.3069 9.9277C22.5748 10.5979 23.215 11.0314 23.9364 11.0314C24.6422 11.0314 25.2768 10.6113 25.5525 9.96105C25.6058 9.83656 25.5636 9.6954 25.4524 9.61871C25.3402 9.54202 25.1946 9.55424 25.0967 9.64872C24.571 10.1589 23.7407 10.5679 22.766 9.62093C22.7115 9.56758 22.6404 9.53979 22.5692 9.53979C22.5148 9.53979 22.4603 9.55536 22.4114 9.5887C22.2992 9.66206 22.2569 9.8021 22.3069 9.9277Z"
                    fill="#303030"
                />
                <path
                    d="M21.0357 14.9727C21.9491 14.9727 22.6896 14.2322 22.6896 13.3188C22.6896 12.4054 21.9491 11.6649 21.0357 11.6649C20.1223 11.6649 19.3818 12.4054 19.3818 13.3188C19.3818 14.2322 20.1223 14.9727 21.0357 14.9727Z"
                    fill="#F5A6AC"
                />
                <path
                    d="M34.9654 14.9727C35.8788 14.9727 36.6193 14.2322 36.6193 13.3188C36.6193 12.4054 35.8788 11.6649 34.9654 11.6649C34.052 11.6649 33.3115 12.4054 33.3115 13.3188C33.3115 14.2322 34.052 14.9727 34.9654 14.9727Z"
                    fill="#F5A6AC"
                />
            </g>
            <path
                d="M21.7582 13.7569C22.6077 8.54767 19.0534 3.63943 13.8195 2.79401C8.58556 1.94859 3.65405 5.48614 2.80463 10.6953C1.9552 15.9045 5.50951 20.8128 10.7434 21.6582C15.9773 22.5036 20.9088 18.9661 21.7582 13.7569Z"
                fill="#FFD05D"
            />
            <path
                d="M17.3029 4.07153C18.2591 5.55685 18.8133 7.32354 18.8133 9.21802C18.8133 14.4945 14.5153 18.7734 9.21264 18.7734C7.37112 18.7734 5.65125 18.2566 4.19043 17.3621C5.89679 20.0132 8.88263 21.7698 12.2807 21.7698C17.5822 21.7698 21.8813 17.4921 21.8813 12.2144C21.8802 8.77075 20.0488 5.75303 17.3029 4.07153Z"
                fill="#FDC453"
            />
            <path
                d="M3.98468 13.2345C3.98468 8.33463 7.97519 4.36294 12.8983 4.36294C15.776 4.36294 18.3338 5.72047 19.9636 7.82682C18.4251 5.16669 15.5395 3.37646 12.2349 3.37646C7.3118 3.37646 3.32129 7.34815 3.32129 12.248C3.32129 14.2837 4.01059 16.1592 5.16956 17.6557C4.41606 16.3542 3.98468 14.8442 3.98468 13.2345Z"
                fill="#FFD77F"
            />
            <path
                d="M9.80179 10.2269C9.53035 9.55096 8.88159 9.11377 8.15062 9.11377C7.43541 9.11377 6.79229 9.53751 6.51297 10.1944C6.46003 10.32 6.50171 10.4623 6.61546 10.5397C6.72922 10.617 6.87677 10.6047 6.97588 10.5094C7.50862 9.99487 8.3511 9.58235 9.33775 10.5374C9.39294 10.5912 9.46502 10.6193 9.5371 10.6193C9.59229 10.6193 9.64748 10.6036 9.69704 10.5699C9.80967 10.4937 9.85247 10.3536 9.80179 10.2269Z"
                fill="#303030"
            />
            <path
                d="M18.0494 10.2269C17.7779 9.55096 17.1292 9.11377 16.3982 9.11377C15.683 9.11377 15.0399 9.53751 14.7606 10.1933C14.7065 10.3188 14.7493 10.4612 14.8619 10.5386C14.9757 10.6159 15.1232 10.6036 15.2223 10.5083C15.7551 9.99375 16.5964 9.58123 17.5842 10.5363C17.6394 10.5901 17.7115 10.6181 17.7836 10.6181C17.8388 10.6181 17.8939 10.6025 17.9435 10.5688C18.0573 10.4937 18.1001 10.3536 18.0494 10.2269Z"
                fill="#303030"
            />
            <path
                d="M7.98866 14.0271C7.98866 14.0215 7.98866 14.017 7.98866 14.0114C7.98866 13.0574 7.29598 12.1853 6.25415 12.1853C5.42631 12.1853 4.9465 12.7772 4.78206 13.3321C4.61875 12.7783 4.13781 12.1853 3.30998 12.1853C2.26927 12.1864 1.57659 13.0586 1.57659 14.0125C1.57659 14.0181 1.57659 14.0226 1.57659 14.0282C1.57096 14.2356 1.57659 16.3464 4.78094 18.0672C7.98866 16.3464 7.99429 14.2356 7.98866 14.0271Z"
                fill="#D83E3E"
            />
            <path
                d="M12.2807 17.5403C11.4078 17.5403 10.6982 16.8341 10.6982 15.9653C10.6982 15.5876 10.8323 15.2288 11.0733 14.9475C10.8323 14.665 10.6982 14.3074 10.6982 13.9296C10.6982 13.0608 11.4078 12.3546 12.2807 12.3546C12.8123 12.3546 13.3056 12.618 13.5996 13.0597C13.683 13.1842 13.648 13.3512 13.5242 13.4341C13.3991 13.5171 13.2313 13.4823 13.148 13.359C12.9542 13.0687 12.6299 12.8949 12.2807 12.8949C11.7074 12.8949 11.24 13.359 11.24 13.9307C11.24 14.2457 11.3819 14.5406 11.6308 14.7378C11.695 14.7894 11.7322 14.8668 11.7322 14.9486C11.7322 15.0304 11.695 15.1078 11.6308 15.1593C11.383 15.3578 11.24 15.6515 11.24 15.9665C11.24 16.537 11.7063 17.0023 12.2807 17.0023C12.6265 17.0023 12.9497 16.8319 13.1435 16.546C13.2268 16.4227 13.3958 16.3902 13.5196 16.4731C13.6435 16.5561 13.6762 16.7243 13.5929 16.8476C13.2978 17.2803 12.8067 17.5403 12.2807 17.5403Z"
                fill="#5E4939"
                stroke="#5E4939"
                strokeWidth="0.5"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_11174:71832"
                    x1="44.0004"
                    y1="2.2105"
                    x2="44.0004"
                    y2="21.7895"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color="#FFA05C" />
                    <stop offset="1" stop-color="#FFD05C" />
                </linearGradient>
                <clipPath id="clip0_11174:71832">
                    <rect width="18.9474" height="18.9474" fill="white" transform="translate(34.5264 2.52637)" />
                </clipPath>
                <clipPath id="clip1_11174:71832">
                    <rect width="18.9474" height="18.9474" fill="white" transform="translate(18.5264 2.52637)" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default IconReaction
