import { EN, VI } from "./const";

export const languages = [VI, EN];

export const translationFiles = [
    'event',
    'common',
    'login',
    'forgotPassword',
    'resetPassword',
    'fanclub',
    'challenge',
    'banner',
    'redeemCampaign',
    'member',
    'earningrule',
    'analytic',
    'follows',
    'review',
    'staff',
    'reportByStore',
    'sms',
    'parking',
    'campaign',
    'quizGame',
    'coupon',
    'promotion',
    'product',
    'productCategory',
    "tax",
    "brand",
    "ticket",
    "customers",
    "orders",
    "transaction",
    "facilityBooking",
    "subAdmin",
    "notification",
    "news",
    "promotion",
    'esurvey',
    "permissions",
]

const translation = { languages, translationFiles };

export default translation;
