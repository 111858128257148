export const API_BASE = `${process.env.REACT_APP_BASE_URL}/api/lifestyles/admin`;
export const API_BASE_REVIEW = `${process.env.REACT_APP_BASE_URL}/api/review`;
export const API_GAME_BASE = `${process.env.REACT_APP_BASE_URL}/api/games/admin`;
export const API_BASE_LOYALTY = `${process.env.REACT_APP_BASE_URL}/api/loyalty/admin`;
export const API_BASE_QUIZ_GAME = `${process.env.REACT_APP_BASE_URL}/api/quiz/admin`;
export const API_PROVINCE = `${process.env.REACT_APP_BASE_URL}/api/province`;
export const API_WEBSITE_MANAGEMENT = `${process.env.REACT_APP_BASE_URL}/api`;
export const API_REPORT = `${process.env.REACT_APP_BASE_URL}/api/report`;

export const API_EVENT = `${API_BASE}/events`;
export const API_NEWS = `${API_BASE}/news`;
export const API_EVENT_CAMPAIGNS = `${API_EVENT}/campaigns`;
export const API_EVENT_DETAIL = `${API_BASE}/events/:id`;
export const API_EVENT_GET_CHECKIN_CODE = `${API_BASE}/events/generate-checkin-code`;
export const API_ADMIN_LOGIN = `${API_BASE}/login`;
export const API_FORGOT_PASSWORD = `${API_BASE}/forgot-password`;
export const API_RESET_PASSWORD = `${API_BASE}/reset-password`;
export const API_LOGOUT = `${API_BASE}/logout`;
export const API_GET_LANGUAGES = `${process.env.REACT_APP_BASE_URL}/api/customers/languages`;
export const API_TOP_EVENT = `${API_EVENT}/rankings`;
export const API_CHECKIN = `${API_EVENT}/checkin`;
export const API_EVENT_OVERVIEW = `${API_EVENT}/overviews`;
export const API_EVENT_GET_USERS = `${API_EVENT}/:id/users`;
export const API_EVENT_OVERVIEW_USER = `${API_EVENT}/overview-users`;
export const API_EVENT_EXPORT_USER_EVENT = `${API_EVENT}/:id/export`;
export const API_EVENT_SHOW_OUTDATED = `${API_EVENT}/:id/show-outdated`;
export const API_MALL = `${process.env.REACT_APP_BASE_URL}/api/malls?version=10000000000`;
// export const API_CAMPAIGN = 'https://aeon-api.rikkei.org/api/lifestyles/admin/events/campaigns'
// export const URL_STORAGE = `${process.env.REACT_APP_BASE_URL}/storage`
export const API_MALL_APPLY_POINT = `${process.env.REACT_APP_BASE_URL}/api/loyalty/malls/apply-point`;
// api fanclub
export const API_FANCLUB = `${API_BASE}/fanclub`;
export const API_FANCLUB_UPDATE = `${API_BASE}/fanclub/update`;
export const API_FANCLUB_RATING = `${API_BASE}/fanclub/rating`;
export const API_FANCLUB_UPDATE_STORE = `${API_BASE}/fanclub/stores`;
export const API_FANCLUB_OVERVIEW = `${API_BASE}/fanclub/overviews`;
export const API_FANCLUB_STORE_OVERVIEW = `${API_BASE}/fanclub/overviews/stores`;
export const API_TOP_FANCLUB = `${API_FANCLUB}/rankings`;
export const API_FANCLUB_ASSIGNEE_VOUCHER = `${API_FANCLUB}/vouchers/assignee`;

// api challenges
export const API_CHALLENGE = `${API_BASE}/challenge`;
export const API_CHALLENGE_DETAIL = `${API_CHALLENGE}/:id`;
export const API_CHALLENGE_PARTICIPANTS = `${API_CHALLENGE}/:id/participants`;
export const API_CHALLENGE_EXPORT_PARTICIPANTS = `${API_CHALLENGE}/:id/export`;
export const API_BANNER = `${API_BASE}/banners`;
export const API_UPDATE_BANNER = `${API_BANNER}/:id`;
export const API_CHALLENGE_TEAMS = `${API_CHALLENGE}/teams`;
export const API_CHALLENGE_IMAGES = `${API_CHALLENGE}/uploaded-images`;
export const API_CHALLENGE_SEND_VOUCHER = `${API_CHALLENGE}/send-voucher`;
// challenge statistic
export const API_CHALLENGE_STATISITC_OVERVIEW = `${API_CHALLENGE}/statistic/overview`;
export const API_CHALLENGE_STATISITC_TOP = `${API_CHALLENGE}/statistic/top-many-participants`;
export const API_CHALLENGE_STATISITC_CHART = `${API_CHALLENGE}/statistic/chart-data`;

//api search source
export const API_SEARCH_SOURCE = `${API_BASE}/banners/search-sources`;

//api malls
export const API_GET_MALL = `${API_BASE}/malls`;
export const API_GET_REPORT_MALL = `${API_GET_MALL}/:id/reports`;
export const API_GET_TENANT_MALL = `${API_GET_MALL}/:id/tenants`;
export const API_GET_TENANT_LIST_MALL = `${API_GET_MALL}/:id/tenant-list`;
export const API_UPDATE_MALL_PASSWORD = `${API_GET_MALL}/admins/:id/change-password`;
export const API_GET_MALL_ADMIN_ACTIVE = `${API_BASE_LOYALTY}/malls/:id/accounts/:admin_id/active`;
export const API_GET_MALL_ACTIVE = `${API_BASE_LOYALTY}/malls/:id/active`;
export const API_GET_MALL_ADMIN = `${API_GET_MALL}/:id/admins`;
export const API_UPDATE_WAREHOUSE = `${API_BASE}/ware-house/:id`;

//api tenants
export const API_GET_TENANT = `${API_BASE}/tenants`;
export const API_GET_DETAIL_TENANT = `${API_GET_TENANT}/:id`;
export const API_GET_CATEGORY_TENANT = `${API_GET_TENANT}/categories`;
export const API_GET_TENANT_STAFF = `${API_GET_TENANT}/:tenant_id/staffs`;
export const API_UPDATE_PASSWORD_STAFF = `${API_GET_TENANT_STAFF}/:staff_id/change-password`;
export const API_LIST_POS = `${API_BASE_LOYALTY}/tenants/:id/pos`;
export const API_TENANT_CREATE = `${API_BASE}/tenants`;
export const API_TENANT_ACTIVE = `${API_BASE_LOYALTY}/tenants/:id/active`;
export const API_EXPORT_LIST_TENANTS = `${API_GET_TENANT}/export-all`;
export const API_TENANT_CAMPAIGN_LIST = `${API_GET_TENANT}/campaign-reward`;
export const API_TENANT_CAMPAIGN_CREATE = `${API_TENANT_CAMPAIGN_LIST}/create`;
export const API_TENANT_CAMPAIGN_EDIT = `${API_TENANT_CAMPAIGN_LIST}/:id/edit`;
export const API_TENANT_CAMPAIGN_ACTIVE = `${API_TENANT_CAMPAIGN_LIST}/:id/active`;
export const API_TENANT_COLLECTION = `${API_GET_TENANT}/collection`;
export const API_TENANT_GALLERY = `${API_GET_TENANT}/gallery`;
export const API_TENANT_DETAIL = `${API_BASE}/tenants`;
export const API_TENANT_INTERNAL_INFO = `${API_BASE}/tenants/internal-tenant-info`;
export const API_TENANT_UPDATE_TAX_ACCOUNTANT = `${API_BASE}/tenants/update-tax-info-accountant`;
export const API_TENANT_LOCKED_UNLOCK = `${API_BASE}/tenants/lock-tenant`;

export const API_TENANT_LIST_DOC_TYPE = `${API_BASE}/tenants/document-type`;
export const API_TENANT_LIST_BUSINESS_TYPE = `${API_BASE}/tenants/business-type`;
export const API_TENANT_LIST_USER_FOR_ACCOUNTING = `${API_BASE}/tenants/list-user-info-accountant`;
export const API_TENANT_LIST_COUNTRY = `${process.env.REACT_APP_BASE_URL}/api/lifestyles/contries`;
export const API_TENANT_LIST_BANKS = `${process.env.REACT_APP_BASE_URL}/api/ecommerce/tenant-banks`;

//api create pos
export const API_TENANT_POS = `${API_BASE_LOYALTY}/tenants/:tenantId/pos`;
export const API_TRADE_LIST = `${API_BASE_LOYALTY}/purchase-transactions?mall_id=:mall_id&tenant_id=:tenant_id`;
export const API_TENANT_POS_CREATE = `${API_BASE_LOYALTY}/tenants/:tenantId/pos/create`;
export const API_TRADE_DETAIL = `${API_BASE_LOYALTY}/purchase-transactions/:id`;
export const API_TENANT_EVOUCHER = `${API_BASE_LOYALTY}/tenants-eVoucher?mall_id=:mall_id`;
export const API_TENANT_EARN_POINT = `${API_BASE_LOYALTY}/tenants/:id/earn-points`;

//api review
export const API_GET_REVIEWS = `${API_BASE}/reviews`;
export const API_GET_REVIEW_STAFFS = `${API_GET_REVIEWS}/staffs`;
export const API_GET_TENANT_STAFFS = `${API_GET_REVIEWS}/tenants`;
export const API_PIN_REVIEW = `${API_GET_REVIEWS}/pin`;
export const API_SET_EXPIRATION_REVIEW = `${API_GET_REVIEWS}/update`;

// api follow list
export const API_GET_FOLLOW_LIST = `${API_GET_REVIEWS}/follow/list`;
export const API_TOGGLE_CHECK_FOLLOW = `${API_GET_REVIEWS}/follow/toggle`;
export const API_EXPORT_LIST_FOLLOW = `${API_GET_REVIEWS}/follow/export`;

//api comment
export const API_GET_COMMENTS = `${API_BASE}/tenants/comments`;
export const API_REPLY_COMMENT = `${API_BASE}/reviews/comment`;
export const API_HIDE_COMMENT = `${API_BASE}/reviews/comment/toggle`;
export const API_EXPORT_LIST_COMMENTS = `${API_GET_TENANT}/comments/export`;

//api review detail
export const API_GET_REVIEW_DETAIL = `${API_BASE}/reviews/detail`;
export const API_GET_REVIEW_COMMENTS = `${API_BASE_REVIEW}/comment/list`;
// api redeem campaign
export const API_GET_REDEEM_CAMPAIGN = `${API_BASE_LOYALTY}/redeem-campaigns`;
export const API_REDEEM_CAMPAIGN_ACTIVE = `${API_BASE_LOYALTY}/redeem-campaigns/:id/active`;
export const API_REDEEM_CAMPAIGN_CREATE = `${API_BASE_LOYALTY}/redeem-campaigns/create`;
export const API_REDEEM_CAMPAIGN_EXTEND_EXPIRED = `${API_BASE_LOYALTY}/redeem-campaigns/:id/extend-date`;
export const API_GET_EVOUCHER = `${API_BASE_LOYALTY}/evoucher-campaigns`;
export const API_REDEEM_CAMPAIGN_DETAIL = `${API_BASE_LOYALTY}/redeem-campaigns/:id`;
export const API_GET_REDEEM_CAMPAIGN_DETAIL = `${API_BASE_LOYALTY}/redeem-campaigns/:id`;
export const API_GET_REDEEM_CAMPAIGN_TRANSACTION = `${API_BASE_LOYALTY}/redeem-campaigns/:id/transactions`;
export const API_REDEEM_CAMPAIGN_MOVE = `${API_BASE_LOYALTY}/redeem-campaigns/:id/move`;
export const API_REDEEM_CAMPAIGN_DRAG = `${API_BASE_LOYALTY}/redeem-campaigns/:idStart/drag`;
export const API_REDEEM_CAMPAIGN_RESET_ORDER = `${API_BASE_LOYALTY}/redeem-campaigns/reset-ordering`;
//api customers

//api customers
export const API_CUSTOMER = `${API_BASE_LOYALTY}/customers`;
export const API_CUSTOMER_DETAIL = `${API_CUSTOMER}/:id`;
export const API_CUSTOMER_DETAIL_UPDATE = `${API_CUSTOMER_DETAIL}/update`;
export const API_MEMBERSHIP_REGISTER = `${API_BASE_LOYALTY}/memberships/register`;
export const API_MEMBER = `${API_BASE_LOYALTY}/memberships`;
export const API_MEMBER_DETAIL = `${API_MEMBER}/:customer_id`;
export const API_PURCHASE_TRANSACTIONS_RETURN = `${API_BASE_LOYALTY}/purchase-transactions/:purchase_transaction_id/return`;
export const API_MANUAL_CREATE_TRANSACTION = `${API_BASE_LOYALTY}/purchase-transactions/manual-create`;
export const API_PURCHASE_HISTORY = `${API_MEMBER}/:id/purchase-history`;
export const API_POINT_TRANSFER = `${API_MEMBER}/:id/point-transfers`;
export const API_PURCHASE_DETAIL = `${API_MEMBER_DETAIL}/purchase-detail/:event_id`;
export const API_OTHER_POINT_TRANSFER = `${API_MEMBER}/:id/other-point-history`;
export const API_OTHER_POINT_DETAIL = `${API_MEMBER}/:id/other-point-detail/:event_id`;
export const API_REFERRALS_LIST = `${API_MEMBER}/:id/referrals`;
export const API_MEMBER_REDEEMS = `${API_CUSTOMER_DETAIL}/redeems`;
export const API_MEMBER_ACTIVE = `${API_MEMBER}/:id/active`;
export const API_CUSTOMER_ADDRESS_LIST = `${API_BASE}/address/list/:id`;
export const API_CUSTOMER_LOCK = `${API_CUSTOMER}/:id/lock`;

export const API_EARNING_RULE = `${API_BASE_LOYALTY}/earning-rules`;
export const API_EARNING_RULE_DETAIL = `${API_EARNING_RULE}/:id`;
export const API_EARNING_RULE_TRADE = `${API_EARNING_RULE}/:id/purchase-transactions`;
export const API_EARNING_RULE_ACTIVE = `${API_EARNING_RULE}/:id/active`;

export const API_DASHBOARD = `${API_BASE_LOYALTY}/analytics`;
export const API_DASHBOARD_REPORT = `${API_DASHBOARD}/dashboard`;
export const API_DASHBOARD_POINT = `${API_DASHBOARD}/purchase-point`;

//report
export const API_REPORT_PURCHASE_TRANSACTION = `${API_BASE_LOYALTY}/analytics/event/purchase-transactions`;
export const API_REPORT_REDEEM_TRANSACTION = `${API_BASE_LOYALTY}/analytics/event/redeems-transactions`;
export const API_REPORT_EARNING_POIN_TRANSACTION = `${API_BASE_LOYALTY}/analytics/event/earning-transactions`;
export const API_EARNING_RULE_EXPORT = `${API_EARNING_RULE}/:id/customers/export`;
export const API_REPORT_PUCHASE_REDEEM_CAMPAIGN = `${API_DASHBOARD}/redeem-point`;
export const API_REPORT_RETURN_TRANSACTION_DETAIL = `${API_BASE_LOYALTY}/analytics/event/purchase-return/:purchase_transaction_id/detail`;

//api staff report
export const API_GET_STAFF_REPORT = `${API_BASE}/tenants/:tenant_id/staffs/:id/stats`;

//api export staff
export const API_GET_STAFF_EXPORT = `${API_BASE}/tenants/:tenant_id/staffs/:id/export`;

//api tenant report
export const API_GET_TENANT_REPORT = `${API_BASE}/tenants/:tenant_id/stats`;

//api export tenant
export const API_GET_TENANT_EXPORT = `${API_BASE}/tenants/:tenant_id/export`;

export const API_SMS_SENDING_COUNT = `${API_BASE_LOYALTY}/analytics/sms-sending`;
export const API_SMS_HISTORY = `${API_BASE_LOYALTY}/sms-sendings/transactions`;

export const API_GET_PROVINCE_LIST = `${process.env.REACT_APP_BASE_URL}/api/province`;

export const API_TEMPLATE_FILE = `${process.env.REACT_APP_BASE_URL}/loyalty/earning-rules/template`;
// api parking
export const API_GET_PARKING_QR_LIST = `${API_BASE}/parking/qr/list`;
export const API_PARKING_LOTS = `${API_BASE}/parking/lots`;

//luckydraw
export const API_CAMPAIGN = `${API_GAME_BASE}/campaign`;
export const API_GIFT = `${API_GAME_BASE}/reward`;
export const API_QR_CODE = `${API_GAME_BASE}/campaign/qr-code`;
export const API_QR_CODE_GEN = `${API_QR_CODE}/generate`;
export const API_GIFT_DEL_MULTI = `${API_GIFT}/delete`;
export const API_QR_CODE_EXPORT = `${API_GAME_BASE}/campaign/qr-code/export`;
export const API_CAMPAIGN_BASE = `${API_GAME_BASE}/campaign`;
export const API_REWARD = `${API_GAME_BASE}/reward`;

//roll history
export const API_CAMPAIGN_ROLL_HISTORY = `${API_GAME_BASE}/campaign`;
export const API_ROLL_HISTORY = `${API_GAME_BASE}/campaign/roll-history`;
export const API_ROLL_HISTORY_EXPORT = `${API_GAME_BASE}/campaign/roll-history/export`;

//request
export const API_CREATE_CUSTOMER = `${API_CUSTOMER}/create`;
export const API_REQUEST = `${API_BASE_LOYALTY}/account-requests`;
export const API_REQUEST_DETAIL = `${API_REQUEST}/:id`;
export const API_REQUEST_NOTE = `${API_REQUEST_DETAIL}/note`;
//export mail
export const DOWNLOADABLE_RECORD = `${process.env.REACT_APP_BASE_URL}/api/loyalty/number-downloadable-records`;

// Quiz Game
// Campaigns
export const API_QUIZ_CAMPAIGN = `${API_BASE_QUIZ_GAME}/campaign`;
export const API_QUIZ_CAMPAIGN_DETAIL = `${API_QUIZ_CAMPAIGN}/:id`;
export const API_QUIZ_CAMPAIGN_CURRENT = `${API_QUIZ_CAMPAIGN}/current`;
// QR codes
export const API_QUIZ_CAMPAIGN_QR = `${API_QUIZ_CAMPAIGN}/qr-code`;
export const API_QUIZ_GENERATE_QR = `${API_QUIZ_CAMPAIGN_QR}/generate`;
export const API_QUIZ_EXPORT_QR = `${API_QUIZ_CAMPAIGN_QR}/export`;
// Questions
export const API_QUIZ_QUESTION = `${API_BASE_QUIZ_GAME}/question`;
export const API_QUIZ_QUESTION_DETAIL = `${API_QUIZ_QUESTION}/:id`;
export const API_QUIZ_REMOVE_QUESTIONS = `${API_QUIZ_QUESTION}/delete`;
export const API_QUIZ_IMPORT_QUESTIONS = `${API_BASE_QUIZ_GAME}/question-import`;
export const API_QUIZ_QUESTION_PIN = `${API_BASE_QUIZ_GAME}/question/pin`;
// Report (get & export history)
export const API_QUIZ_HISTORY = `${API_QUIZ_CAMPAIGN}/history`;

//Tabu quiz game for PG
export const API_GET_QR_CODE_TAPU = `${process.env.REACT_APP_BASE_URL}/api/quiz/web/pg/qr-codes`;
export const API_PG_LOGIN = `${process.env.REACT_APP_BASE_URL}/api/quiz/web/pg/login-code`;

//brand
export const API_BASE_ECOMMERCE = `${process.env.REACT_APP_BASE_URL}/api/ecommerce`;
export const API_BRAND = `${API_BASE_ECOMMERCE}/brands/list`;
export const API_BRAND_DETAIL = `${API_BASE_ECOMMERCE}/brands/:id`;
export const API_BRAND_CREATE = `${API_BASE_ECOMMERCE}/brands/create`;
export const API_BRAND_EDIT = `${API_BASE_ECOMMERCE}/brands/update`;
export const API_BRAND_ACTIVE = `${API_BASE_ECOMMERCE}/brands/:id/active`;

export const API_BASE_ECOMMERCE_NO_ADMIN = `${process.env.REACT_APP_BASE_URL}/api/ecommerce`;

//tax
export const API_GET_TAX = `${API_BASE_ECOMMERCE}/tax/list`;
export const API_GET_TAX_All = `${API_BASE_ECOMMERCE_NO_ADMIN}/tax/list-all`;
export const API_TAX_CREATE = `${API_BASE_ECOMMERCE}/tax/create`;
export const API_TAX_EDIT = `${API_BASE_ECOMMERCE}/tax/update`;
export const API_GET_TAX_DETAIL = `${API_BASE_ECOMMERCE}/tax/:id/edit`;
export const API_GET_TAX_ACTIVE = `${API_BASE_ECOMMERCE}/tax/:id/active`;

//product
export const API_UPDATE_STOCK = `${API_BASE_ECOMMERCE}/products/update-stock`;
export const API_PRODUCT_CREATE = `${API_BASE_ECOMMERCE_NO_ADMIN}/products/create`;
export const API_PRODUCT_DETAIL = `${API_BASE_ECOMMERCE_NO_ADMIN}/products/:id`;
export const API_PRODUCT_LIST = `${API_BASE_ECOMMERCE_NO_ADMIN}/products/list`;
export const API_PRODUCT_DELETE = `${API_BASE_ECOMMERCE_NO_ADMIN}/products/delete/:id`;
export const API_EGIFT_CODE_LIST = `${API_BASE_ECOMMERCE_NO_ADMIN}/products/:id/e-gifts`;

export const API_PRODUCT_CATEGORY_All = `${API_BASE_ECOMMERCE}/categories/list-all-category`;

export const API_TAX_MOVE = `${API_BASE_ECOMMERCE}/tax/:id/move`;
export const API_TAX_RESET_ORDER = `${API_BASE_ECOMMERCE}/tax/reset-ordering`;

export const API_UPLOAD = `${API_BASE_ECOMMERCE_NO_ADMIN}/upload/upload-media`;
export const API_UPLOAD_DELETE = `${API_BASE_ECOMMERCE_NO_ADMIN}/upload/delete-media`;
export const API_PRODUCT_ACTIVE = `${API_BASE_ECOMMERCE_NO_ADMIN}/products/active/:id`;
export const API_PRODUCT_LOCK = `${API_BASE_ECOMMERCE_NO_ADMIN}/products/lock/:id`;
export const API_PRODUCT_APPROVE = `${API_BASE_ECOMMERCE_NO_ADMIN}/products/approve/:id`;

export const API_BANNER_UPLOAD = `${API_BASE_ECOMMERCE_NO_ADMIN}/upload/upload-media-banner`;

export const API_TENANT_LIST = `${API_BASE_ECOMMERCE_NO_ADMIN}/tenant/list-tenant-by-mall`;

//category product
export const API_PRODUCT_CATEGORY = `${API_BASE_ECOMMERCE}/categories/list`;
export const API_PRODUCT_CATEGORY_DETAIL = `${API_BASE_ECOMMERCE}/categories/:id`;
export const API_PRODUCT_CATEGORY_CREATE = `${API_BASE_ECOMMERCE}/categories/create`;
export const API_PRODUCT_CATEGORY_EDIT = `${API_BASE_ECOMMERCE}/categories/update`;
export const API_PRODUCT_CATEGORY_DEL = `${API_BASE_ECOMMERCE}/categories/delete`;
export const API_PRODUCT_CATEGORY_ACTIVE = `${API_BASE_ECOMMERCE}/categories/:id/active`;
export const API_PARENT_CATEGORY_LIST = `${API_BASE_ECOMMERCE_NO_ADMIN}/categories/list-parent-category`;

// tag
export const API_GET_TAG_LIST = `${API_BASE_ECOMMERCE_NO_ADMIN}/tagging/list`;

// coupon
export const API_CREATE_COUPON = `${API_BASE_ECOMMERCE_NO_ADMIN}/coupons/create`;
export const API_LIST_COUPON = `${API_BASE_ECOMMERCE_NO_ADMIN}/coupons/list`;
export const API_UPDATE_COUPON = `${API_BASE_ECOMMERCE_NO_ADMIN}/coupons/update`;
export const API_DETAIL_COUPON = `${API_BASE_ECOMMERCE_NO_ADMIN}/coupons/detail/:id`;
export const API_CHANGE_STATUS_COUPON = `${API_BASE_ECOMMERCE_NO_ADMIN}/coupons/change-status`;
export const API_APPROVE_COUPON = `${API_BASE_ECOMMERCE_NO_ADMIN}/coupons/approval`;
export const API_LIST_DELIVERY_VENDORS = `${API_BASE_ECOMMERCE_NO_ADMIN}/delivery-vendors/list`;

// discount
export const API_DISCOUNTS = `${API_BASE_ECOMMERCE}/discounts`;
export const API_DISCOUNT_CREATE = `${API_DISCOUNTS}/create`;
export const API_PRODUCT_LIST_BY_TENANT = `${API_BASE_ECOMMERCE}/products/list-by-tenant`;
export const API_LIST_TENANT_BY_MALL = `${API_BASE_ECOMMERCE}/tenant/list-tenant-by-mall`;
export const API_LIST_TENANT_BY_BRAND = `${API_BASE_ECOMMERCE}/tenant/list-tenant-by-brand/:id`;
export const API_DISCOUNT_LIST = `${API_DISCOUNTS}/list`;
export const API_DISCOUNT_DETAIL = `${API_DISCOUNTS}/detail/:id`;
export const API_DISCOUNT_EDIT = `${API_DISCOUNTS}/update`;
export const API_PRODUCT_LIST_BY_DATE = `${API_DISCOUNTS}/list-product`;
export const API_DISCOUNT_ACTIVE = `${API_DISCOUNTS}/active/:id`;
export const API_DISCOUNT_APPROVE = `${API_DISCOUNTS}/approve`;
export const API_DISCOUNT_LOCK = `${API_DISCOUNTS}/lock`;
export const API_LIST_MALL_APPROVED = `${API_DISCOUNTS}/list-approval-mall/:id`;

export const API_PRODUCT_DISCOUNT_LIST = `${API_BASE_ECOMMERCE_NO_ADMIN}/product-discount-list/:id`;

//brand
export const API_GET_MAKER_LIST = `${API_BASE_ECOMMERCE_NO_ADMIN}/maker/list`;

//cs ticket
export const API_CS_TICKET_CREATE = `${API_BASE_ECOMMERCE_NO_ADMIN}/ticket/create`;
export const API_CS_TICKET_DETAIL = `${API_BASE_ECOMMERCE_NO_ADMIN}/ticket/:id/detail`;
export const API_CS_TICKET_LIST = `${API_BASE_ECOMMERCE_NO_ADMIN}/ticket/list`;
export const API_CS_TICKET_STATUS = `${API_BASE_ECOMMERCE_NO_ADMIN}/ticket/:id/status-process-ticket`;
export const API_CS_TICKET_CLOSE = `${API_BASE_ECOMMERCE_NO_ADMIN}/ticket/:id/close-ticket`;
export const API_CS_TICKET_COMMENT = `${API_BASE_ECOMMERCE_NO_ADMIN}/ticket/:id/feedbackTicket`;

//orders
export const API_ORDER = `${API_BASE_ECOMMERCE}/order`;
export const API_ORDER_LIST = `${API_ORDER}/cms-list`;
export const API_COUNT_ORDERS_BY_STATUS = `${API_ORDER}/count-by-status`;
export const API_ORDER_DETAIL = `${API_ORDER}/detail/cms/:id`;
export const API_ORDER_DETAIL_3 = `${API_ORDER}/cms-detail-cs/:id`;
export const API_ORDER_APPROVE = `${API_ORDER_DETAIL}/approve`;
export const API_ORDER_COMPLAINT_UPDATE_STATUS = `${API_BASE_ECOMMERCE}/complaint/:id/update-status`;
export const API_ORDER_REASON = `${API_ORDER}/reason-cms`;
export const API_ORDER_CANCEL = `${API_ORDER}/cancel-cms`;
export const API_UPDATE_RETURN = `${API_BASE_ECOMMERCE}/return/cms-update-reject-approve/:id`;
export const API_ORDER_APPROVE_CANCEL = `${API_ORDER}/approve-cancel-order/:id`;
export const API_ORDER_CLOSE_COMPLAINT = `${API_BASE_ECOMMERCE}/complaint/:id/close`;
export const API_ORDER_GET_SOLUTION_COMPLAINT = `${API_BASE_ECOMMERCE}/complaint/:id/solutions`;
export const API_ORDER_LIST_REASON_COMPLAINT = `${API_BASE_ECOMMERCE}/complaint/reason-order-detail/:id`;
export const API_ORDER_REQUIRE_PROOF_COMPLAINT = `${API_BASE_ECOMMERCE}/complaint/:id/require-proof`;
export const API_ORDER_UPLOAD_PROOF_COMPLAINT = `${API_BASE_ECOMMERCE}/complaint/:id/tenant-upload-proof`;
export const API_ORDER_COMMENT_COMPLAINT = `${API_BASE_ECOMMERCE}/complaint/:id/comment`;
export const API_ORDER_CREATE_COMPLAINT = `${API_BASE_ECOMMERCE}/complaint/cms/tenant`;
export const API_ORDER_CANCEL_COMPLAINT = `${API_BASE_ECOMMERCE}/complaint/:id/tenant-cancel`;
export const API_ORDER_EXPORT = `${API_BASE_ECOMMERCE}/order/export-report`;
export const API_ORDER_TENANT_REVENUE_LIST = `${API_ORDER}/list-order-tenant-with-revenue`;
export const API_ORDER_TENANT_REVENUE_DETAIL_LIST = `${API_BASE_ECOMMERCE}/adjustment/list-adjustment-detail-by-order-detail`;
export const API_ORDER_TENANT_REVENUE_CREATE = `${API_BASE_ECOMMERCE}/adjustment/make-adjustment-detail`;
export const API_ORDER_TENANT_REVENUE_EDIT = `${API_BASE_ECOMMERCE}/adjustment/edit-adjustment-detail`;
export const API_TENANT_BRAND = `${API_BASE_ECOMMERCE}/brands/list-tenant-by-brand`;
//order-complaint
export const API_COMPLAINT = `${API_BASE_ECOMMERCE}/complaint`;
export const API_COMPLAINT_LIST = `${API_COMPLAINT}/cms-list`;
//order-return
export const API_RETURN = `${API_BASE_ECOMMERCE}/return`;
export const API_RETURN_LIST = `${API_RETURN}/cms-list`;
export const API_CONFIRM_RECEIVE_PRODUCT = `${API_RETURN}/confirm-receive-order-return`;

// refund
export const API_REFUND_DETAIL = `${API_ORDER}/cms-detail-cs/:id`;
export const API_REFUND_APPROVE = `${API_BASE_ECOMMERCE}/refund/approve-refund`;
export const API_RQ_UPDATE_BANKING_INFO = `${API_ORDER}/detail/cms/request-rewrite-bank-info`;
export const API_REFUND_COMPLETE = `${API_BASE_ECOMMERCE}/refund/change-status-refund`;
// facility booking
export const API_FACILITY = `${API_BASE}/facility-booking`;

// sub-admin list
export const API_SUB_ADMIN_LIST = `${API_BASE}/sub-admins`;
export const API_SUB_ADMIN_DETAIL = `${API_BASE}/sub-admins/:id`;
export const API_SUB_ADMIN_LIST_PERMISSION = `${API_BASE}/sub-admins/permissions`;

//notifications
export const API_NOTI_LIST = `${API_BASE}/man-noti`;
export const API_NOTI_DETAIL = `${API_BASE}/man-noti/:id`;

//e-survey
export const API_ESURVEY_LIST = `${API_BASE}/survey`;
export const API_ESURVEY_DETAIL = `${API_ESURVEY_LIST}/:id`;
export const API_ESURVEY_CUSTOMER_DETAIL = `${API_ESURVEY_DETAIL}/customer/:id`;
export const API_ESURVEY_CUSTOMER_HISTORY = `${API_ESURVEY_LIST}/customer/history`;
export const API_ESURVEY_CUSTOMER_HISTORY_EXPORT = `${API_ESURVEY_LIST}/customer/history/export`;
export const API_ESURVEY_CUSTOMER_EXPORT = `${API_ESURVEY_LIST}/:id/customers/export`;
// export const API_NOTI_DETAIL = `${API_BASE}/man-noti/:id`;
// wordpress convert api
export const API_WP_EVENT = `${API_WEBSITE_MANAGEMENT}/wp/events`;
export const API_WP_EVENT_DETAIL = `${API_WEBSITE_MANAGEMENT}/wp/events/:id`;
export const API_WP_NEWS = `${API_WEBSITE_MANAGEMENT}/wp/news`;
export const API_WP_NEWS_DETAIL = `${API_WEBSITE_MANAGEMENT}/wp/news/:id`;
export const API_WP_PROMOTION = `${API_WEBSITE_MANAGEMENT}/wp/promotions`;
export const API_WP_PROMOTION_DETAIL = `${API_WEBSITE_MANAGEMENT}/wp/promotions/:id`;
export const API_WP_SERVICE = `${API_WEBSITE_MANAGEMENT}/wp/services`;
export const API_WP_SERVICE_DETAIL = `${API_WEBSITE_MANAGEMENT}/wp/services/:id`;
export const API_WP_POPUP = `${API_WEBSITE_MANAGEMENT}/wp/popups`;
export const API_WP_POPUP_DETAIL = `${API_WEBSITE_MANAGEMENT}/wp/popups/:id`;
export const API_WP_BANNER = `${API_WEBSITE_MANAGEMENT}/wp/banners`;
export const API_WP_BANNER_DETAIL = `${API_WEBSITE_MANAGEMENT}/wp/banners/:id`;
export const API_WP_TENANT = `${API_WEBSITE_MANAGEMENT}/wp/tenants`;
export const API_WP_TENANT_DETAIL = `${API_WEBSITE_MANAGEMENT}/wp/tenants/:id`;
export const API_WP_CATEGORY = `${API_WEBSITE_MANAGEMENT}/wp/categories`;
export const API_WP_CATEGORY_DETAIL = `${API_WEBSITE_MANAGEMENT}/wp/categories/:id`;
export const API_WP_LOCATION = `${API_WEBSITE_MANAGEMENT}/wp/locations`;
export const API_WP_LOCATION_DETAIL = `${API_WEBSITE_MANAGEMENT}/wp/locations/:id`;
export const API_WP_FLOORMAP = `${API_WEBSITE_MANAGEMENT}/wp/floormaps`;
export const API_WP_FLOORMAP_DETAIL = `${API_WEBSITE_MANAGEMENT}/wp/floormaps/:id`;
export const API_WP_FACILITY = `${API_WEBSITE_MANAGEMENT}/wp/facilities`;
export const API_WP_FACILITY_DETAIL = `${API_WEBSITE_MANAGEMENT}/wp/facilities/:id`;
export const API_WP_UPLOAD_IMAGE = `${API_WEBSITE_MANAGEMENT}/wp/upload/image`;
export const API_WP_DELETE_IMAGE = `${API_WEBSITE_MANAGEMENT}/wp/delete/image`;
export const API_WP_TAG = `${API_WEBSITE_MANAGEMENT}/wp/tags`;
export const API_WP_TAG_DETAIL = `${API_WEBSITE_MANAGEMENT}/wp/tags/:id`;
export const API_WP_RECRUITMENT = `${API_WEBSITE_MANAGEMENT}/wp/recruitments`;
export const API_WP_RECRUITMENT_DETAIL = `${API_WEBSITE_MANAGEMENT}/wp/recruitments/:id`;
export const API_WP_COLLECTION = `${API_WEBSITE_MANAGEMENT}/wp/galleries`;
export const API_WP_COLLECTION_DETAIL = `${API_WEBSITE_MANAGEMENT}/wp/galleries/:id`;
export const API_WP_UPLOAD_IMAGE_EDITOR = `${API_WEBSITE_MANAGEMENT}/wp/upload/image-editor`;

// customer api
export const API_GET_ORDERED_LIST = `${API_WEBSITE_MANAGEMENT}/ecommerce/order/cms-list`;

// banner setting api
export const API_GET_BANNERS_LIST = `${API_BASE_ECOMMERCE}/banners-cms`;
export const API_CREATE_BANNER = `${API_BASE_ECOMMERCE}/banners-cms`;
export const API_DELETE_BANNER = `${API_BASE_ECOMMERCE}/banners-cms/:id/delete`;
export const API_UPDATE_DETAIL_BANNER = `${API_BASE_ECOMMERCE}/banners-cms/:id`;
export const API_GET_DETAIL_BANNER = `${API_BASE_ECOMMERCE}/banners-cms/:id`;
export const API_GET_BANNER_POSITION = `${API_BASE_ECOMMERCE}/banners-cms/position`;

// notifications
export const API_GET_NOTIFICATIONS = `${API_BASE_ECOMMERCE}/notifications/admin`;
export const API_READ_ALL_NOTIFICATIONS = `${API_BASE_ECOMMERCE}/notifications/admin/read-all`;
export const API_READ_NOTIFICATION = `${API_BASE_ECOMMERCE}/notifications/admin/read`;

export const REPORT_ORDER_DETAIL = `${API_REPORT}/order/detail`;
export const REPORT_ORDER_PAYMENT = `${API_REPORT}/order/payment`;
export const REPORT_ORDER_REFUND = `${API_REPORT}/order/refund`;
export const REPORT_ORDER_RETURN = `${API_REPORT}/order/return`;
export const REPORT_ORDER_TRANSPORT = `${API_REPORT}/order/transport`;
export const REPORT_ORDER_COMPLAINT = `${API_REPORT}/order/complaint`;
export const REPORT_ORDER_DAILY = `${API_REPORT}/order/daily`;
export const REPORT_ORDER_MONTHLY = `${API_REPORT}/order/monthly`;
export const REPORT_ORDER_SETTLEMENT = `${API_REPORT}/order/settlement`;
export const REPORT_ORDER_SETTLEMENT_EXPORT = `${API_REPORT}/order/settlement/export`;
export const REPORT_PRODUCT_PHYSIC = `${API_REPORT}/product/physic`;
export const REPORT_PRODUCT_EGIFT = `${API_REPORT}/product/egift`;
export const REPORT_COUPON_CAMPAIGN = `${API_REPORT}/coupon/campaign`;
export const REPORT_TAX_010 = `${API_REPORT}/tax/010`;
export const REPORT_TAX_011 = `${API_REPORT}/tax/011`;
export const REPORT_TAX_012 = `${API_REPORT}/tax/012`;
export const REPORT_TAX_013 = `${API_REPORT}/tax/013`;
export const REPORT_PAYOO_TOKEN = `${API_REPORT}/payoo-token`;
export const REPORT_TICKET = `${API_REPORT}/ticket`;
export const REPORT_DAILY_SALES = `${API_REPORT}/daily-sales`;
export const REPORT_SETTLEMENT_PERIOD = `${API_REPORT}/settlement-period`;
export const REPORT_COD_ASPIRE = `${API_REPORT}/cod-aspire`;
export const REPORT_COD_ASPIRE_UPLOAD = `${API_REPORT}/cod-aspire-upload`;
// user account
export const API_GET_ADMIN_USERS = `${API_BASE_ECOMMERCE}/admins/users`;
export const API_CREATE_USERS_ADMIN = `${API_GET_ADMIN_USERS}/create`;
export const API_UPDATE_USERS_ADMIN = `${API_GET_ADMIN_USERS}/:id/update`;
export const API_GET_LIST_ROLE = `${API_BASE_ECOMMERCE}/admins/roles/list-by-user`;
export const API_DELETE_USERS_ADMIN = `${API_GET_ADMIN_USERS}/:id`;
export const API_GET_DETAIL_ACCOUNT = `${API_GET_ADMIN_USERS}/:id`;
// roles managemnet
export const API_GET_ROLE_PERMISSIONS = `${API_BASE_ECOMMERCE}/admins/role-permissions`;
export const API_GET_PERMISSIONS_LIST = `${API_BASE_ECOMMERCE}/admins/permissions`;
export const API_GET_ROLE_PERMISSIONS_DETAIL = `${API_GET_ROLE_PERMISSIONS}/:id`;
export const API_UPDATE_ROLE_PERMISSIONS = `${API_GET_ROLE_PERMISSIONS}/:id`;

export const API_WP_DISHES = `${API_WEBSITE_MANAGEMENT}/wp/dishes`;
export const API_WP_DISHES_DETAIL = `${API_WEBSITE_MANAGEMENT}/wp/dishes/:id`;
export const API_WP_TIPS = `${API_WEBSITE_MANAGEMENT}/wp/tips`;
export const API_WP_TIPS_DETAIL = `${API_WEBSITE_MANAGEMENT}/wp/tips/:id`;
export const API_WP_APPLICANT = `${API_WEBSITE_MANAGEMENT}/wp/applicants`;
export const API_WP_APPLICANT_DETAIL = `${API_WEBSITE_MANAGEMENT}/wp/applicants/:id`;

export const API_ECOM_CATEGORY = `${API_BASE}/ecom-category`;

export const API_GET_TENANT_TIME_ACTIVE = `${API_BASE_ECOMMERCE}/tenants/:tenant_id/list-active-time`;
export const API_CREATE_TENANT_TIME_ACTIVE = `${API_BASE_ECOMMERCE}/tenants/make-active-time`;
export const API_UPDATE_TENANT_TIME_ACTIVE = `${API_BASE_ECOMMERCE}/tenants/update-end-date-active`;
