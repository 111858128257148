import { Tabs } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { CUSTOMER_PATH, MEMBER_PATH } from '../../config/path'

const { TabPane } = Tabs

const CustomerTab = ({ defaultActiveKey, tabBarExtraContent }) => {
    const history = useHistory()
    const { t } = useTranslation('member')

    return (
        <Tabs
            className="customer_list--tab"
            defaultActiveKey={defaultActiveKey}
            tabBarExtraContent={tabBarExtraContent}
            onChange={(tab) => {
                if (tab === 'customer_list') {
                    history.push(CUSTOMER_PATH)
                }

                if (tab === 'members_list') {
                    history.push(MEMBER_PATH)
                }
            }}
        >
            <TabPane tab={t('Khách hàng')} key="customer_list" />
            <TabPane tab={t('Thành viên')} key="members_list" />
        </Tabs>
    )
}

export default CustomerTab
