import React, { useEffect, useState } from "react";
import "./DropDownNotification.scss";
import { Empty, Spin } from "antd";
import { useNotification } from "context/notifications/NotificationContext";
import { NotificationItem } from "components/notification";
import { NOTIFICATIONS } from "config/path";
import { useHistory } from "react-router-dom";
import { bindParams, convertDate } from "config/function";
import { useLocation } from "react-router-dom";

const centerStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100px",
};

const DropDownNotification = () => {
  const location = useLocation()
  const {
    loadingNotification,
    recentNotifications,
    handleViewDetailNotification,
    navigateToNotificationDetail,
  } = useNotification();
  let history = useHistory();

  const goToNotifications = () => {
    history.push(NOTIFICATIONS);
  };

  const renderNotifications = () => {
    if (recentNotifications.length <= 0) {
      return (
        <div style={centerStyle}>
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        </div>
      );
    }

    return (
      <div className={"wrapper_notifications"}>
        {recentNotifications.map((notification, index) => (
          <NotificationItem
            key={`${index + 1}`}
            isActivated={!notification.is_read}
            title={notification?.title}
            des={notification?.content}
            img={""}
            viewDetailNotification={async () => {
              const { res, url } = await handleViewDetailNotification(
                notification
              );
              const newPath = bindParams(url.path, url.params)
              if(newPath === location.pathname){
                window.location.reload();
              }
              if (res.code === 200 && url.path && url.params) {
                history.push(bindParams(url.path, url.params));
              }
            }}
            time={convertDate(notification?.created_at)}
          />
        ))}
      </div>
    );
  };

  return (
    <div className={"wrapper_notification"}>
      <div className={"header"}>
        <span className={"title"}>Thông báo</span>
        <a onClick={goToNotifications} className={"link_to_notifications"}>
          Xem tất cả thông báo
        </a>
      </div>

      {/* notification list */}
      {renderNotifications()}
    </div>
  );
};

export default DropDownNotification;
