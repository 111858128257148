import { getAxios, putAxios } from "../Http";
import { API_BASE_LOYALTY, API_CUSTOMER } from "../config/endpointApi";
import { useQuery, useQueryClient } from "react-query";
import { getMallId, getPageLimit, sortObject } from "../config/function";
import { useState, useMemo, useEffect } from "react";
import { useLocation } from "react-router-dom";
import qs from "qs";

export const initialFilter = {
  account_type: [],
  district_id: [],
  first_mall_id: [],
  gender: [],
  keyword: "",
  limit: 10,
  mall_id: [],
  month_birth: [],
  is_ekyc: '',
  page: 1,
  province_id: [],
  register_day_begin: "",
  register_day_end: "",
  year_birth_begin: "",
  year_birth_end: "",
  ward_id: [],
};

export const filterToParams = (filters) => {
  const params = {};
  Object.keys(filters).forEach((key) => {
    if (Array.isArray(filters[key]) && !filters[key].length) {
      return;
    }

    if (Array.isArray(filters[key])) {
      params[key] = filters[key].join(",");
      return;
    }

    params[key] = filters[key];
  });

  return params;
};

export const formatInitialFilter = (initialFilter) => {
  const params = {};
  Object.keys(initialFilter).forEach((key) => {
    if (
      [
        "province_id",
        "district_id",
        "ward_id",
        "mall_id",
        "first_mall_id",
        "gender",
        "month_birth",
        "account_type",
      ].indexOf(key) !== -1
    ) {
      if (initialFilter[key]) {
        params[key] = initialFilter[key].split(",");
      } else {
        params[key] = [];
      }
    }

    if (Array.isArray(params[key])) {
      params[key] =
        key !== "account_type"
          ? params[key].map((value) => Number(value))
          : params[key];
      return;
    }

    if (["page", "limit"].indexOf(key) !== -1) {
      params[key] = Number(initialFilter[key]);
      return;
    }

    params[key] = initialFilter[key];
  });

  return params;
};

const getCustomerList = async ({ queryKey }) => {
  // eslint-disable-next-line no-unused-vars
  const [_, params] = queryKey;

  const { data } = await getAxios(API_CUSTOMER, filterToParams(params), {
    paramsSerializer: (params) => {
      return qs.stringify(params);
    },
  });
  return data;
};
const formatFilter = (params, filterData) => {
  let filters = { ...params, ...filterData };

  if (!filters.age_start && !filters.age_end) {
    const { age_start, age_end, ...filterRest } = filters;
    filters = { ...filterRest };
  }

  if (!filters.register_day_begin || !filters.register_day_end) {
    delete filters.register_day_begin;
    delete filters.register_day_end;
  }

  if (!filters.year_birth_begin || !filters.year_birth_end) {
    delete filters.year_birth_begin;
    delete filters.year_birth_end;
  }

  return sortObject(filters);
};
function useCustomerQuery() {
  const location = useLocation();
  const savedPageLimit = getPageLimit("customers");
  const mallId = getMallId();
  const queryClient = useQueryClient();

  const initialParams = useMemo(() => {
    let params = {};
    if (location.search) {
      params = qs.parse(location.search.substr(1));
    }
    return sortObject({
      ...initialFilter,
      limit: savedPageLimit,
      ...formatInitialFilter(params),
    });
  }, [location.search, savedPageLimit]);

  const [params, setParams] = useState(initialParams);

  const query = useQuery(["customers", params, mallId], getCustomerList, {
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    staleTime: 1800000, // cache for 30 min
  });

  useEffect(() => {
    if (!location.search) {
      setParams({ ...initialFilter, limit: savedPageLimit });
    }
  }, [location.search, savedPageLimit]);

  useEffect(() => {
    setParams({
      ...params,
      is_exact: localStorage.getItem("customer_search_exact"),
    });
  }, [localStorage.getItem("customer_search_exact")]);

  useEffect(() => {
    queryClient.invalidateQueries({ queryKey: ["customer"] });
    queryClient.fetchQuery({ queryKey: ["customer"] });
  }, [params, localStorage.getItem("customer_search_exact")]);

  const setFilters = (filterData) => {
    const filter = formatFilter(params, filterData);

    setParams(filter);
    return filter;
  };
  const resetFilters = () => {
    setParams(initialFilter);
  };

  return {
    ...query,
    setFilters,
    filters: params,
    resetFilters,
  };
}

export default useCustomerQuery;
