import { Button, Form, Input, Modal, Typography } from "antd";
import { useTranslation } from "react-i18next";

const PreventReRegisterModal = ({
  isOpenModal,
  preventModalSubmit,
  isPreventLoading,
  closePreventModal,
}) => {
  const { t } = useTranslation("member");
  return (
    <Modal
      visible={isOpenModal}
      width={714}
      centered
      footer={null}
      closable={false}
    >
      <Form
        className="prevent-form"
        layout="vertical"
        colon={false}
        onFinish={preventModalSubmit}
      >
        {isOpenModal}
        <Typography.Title className="title" level={4}>
          {t("Chặn đăng kí lại")}
        </Typography.Title>
        <Form.Item
          name="config_delete_date"
          label={t("Số ngày được đăng kí lại")}
          rules={[
            {
              required: true,
              message: t("Nhập số ngày"),
            },
            {
              pattern: /^[1-9]\d*$/,
              message: t(
                "Số ngày đăng được kí lại phải là số nguyên lớn hơn 0"
              ),
            },
          ]}
        >
          <Input />
        </Form.Item>
        <div className="wrap-btn">
          <Button
            className="btn btn-common"
            loading={isPreventLoading}
            htmlType="submit"
          >
            {t("Xác nhận")}
          </Button>
          <Button
            className="btn btn-cancel"
            onClick={closePreventModal}
            disabled={isPreventLoading}
          >
            {t("Huỷ")}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default PreventReRegisterModal;
