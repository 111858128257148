import { getPermissions } from "../config/function";

const MAP_URL_TO_PERMISSIONS = {
  //lifestyle
  "customer-reviews": "lifestyle.customer_reviews",
  events: "lifestyle.events",
  challenges: "lifestyle.challenges",
  banners: "lifestyle.banners",
  //reward
  customers: "rewards.customers",
  "earning-rules": "rewards.earning_rules",
  "redeem-campaigns": "rewards.redeem_rules",
  analytics: "rewards.reports",
  //staff-recommend
  reviews: "staff_recommend",
  tenants: "staff_recommend",
  commments: "staff_recommend",
  follows: "staff_recommend",
  "campaign-manage": "staff_recommend",
  //settings
  tenants: "setting.tenants",
  sub_admins: "setting.tenants.sub_admins",
  //request
  request: "request_management",
  //quiz-game
  "quiz-game-campaign": "quizgame.campaigns",
  "quiz-game-question": "quizgame.questions",
  "quiz-game-report": "quizgame.reports",
  //lucky-draw
  campaigns: "lucky_draw.campaigns",
  "draw-histories": "lucky_draw.shake_history",
  //user-tracking
  "report-geo-province": "user_tracking.reports",
  "report-geo-district": "user_tracking.reports",
  "report-geo-ward": "user_tracking.reports",
  "report-retention-time": "user_tracking.reports",
  "report-retention-group": "user_tracking.reports",
  "report-geo-check-otp": "user_tracking.otp",
  "report-geo-user-overlap": "user_tracking.reports",
  //facility
  facilities: "facility.device",
  bookings: "facility.booking",
  //manual_notis
  notifications: "manual_notis.send_noti",
  //e-survey
  "e-surveys":"survey",
  "e-survey":"survey",
  
  //website management
  banners: "wpconvert.banners",
  facilities: "wpconvert.free_services",
  popups: "wpconvert.popups",
  promotions: "wpconvert.promotions",
  events: "wpconvert.events",
  services: "wpconvert.services",
  categories: "wpconvert.wp_category",
  tenants: "wpconvert.wp_tenants",
  collections: "wpconvert.collections",
  news: "wpconvert.news",
  tags: "wpconvert.tag",
  recruitments: "wpconvert.recruitment",
  locations: "wpconvert.locations",
  floormaps: "wpconvert.floormaps",
  appliants: "wpconvert.appliants",
  dishes: "wpconvert.dishes",
};

//if have role return flase else return true
const useCheckRole = (role) => {
  const permissions = getPermissions();

  const PATH_NAME = window.location.pathname.toString().split("/");
  const PATH_CHECK = PATH_NAME[2] === "wp" ? PATH_NAME[3] : PATH_NAME[2];
  // if (permissions) {
  //   return !JSON.stringify(permissions).includes(
  //     `${MAP_URL_TO_PERMISSIONS[PATH_CHECK]}.${role}`
  //   );
  // }

  if (Array.isArray(permissions) && permissions?.length > 0) {
    return !JSON.stringify(permissions).includes(
      `${MAP_URL_TO_PERMISSIONS[PATH_CHECK]}.${role}`
    );
  }
  return false;

  // const ROLE_CRUD = useCheckRole('crud'): Use check buttons status
  // ROLE_CRUD === false --> enable btn
  // ROLE_CRUD === true --> disabled btn
};

export default useCheckRole;
