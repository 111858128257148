import React from 'react';
import PropTypes from 'prop-types';
import logoBinhduong from "../../assets/img/logo-aeonmall-binhduong.png"
const IconBinhDuong = props => {
    const {onClick} = props
    return <img src={logoBinhduong} alt={''} onClick={onClick}/>
};

IconBinhDuong.defaultProps = {
}

IconBinhDuong.propTypes = {
    onClick: PropTypes.func
};

export default IconBinhDuong;