import { getAxios } from '../Http'
import { API_MALL } from '../config/endpointApi'
import { useQuery } from 'react-query'
import { ROLE_ADMIN, ROLE_BRAND, ROLE_MALL, ROLE_PLATFORM_ACCOUNTING, VI } from '../config/const'
import { getCurrentLanguage, getRole } from '../config/function'

const getMalls = async () => {
    const { data } = await getAxios(`${API_MALL}`)
    return data
}

const locale = getCurrentLanguage()

const role = getRole()

function useMalls(enable = false) {
    const { data } = useQuery(['malls'], () => getMalls(), {
        enabled: enable || [ROLE_ADMIN, ROLE_MALL, ROLE_BRAND, ROLE_PLATFORM_ACCOUNTING].includes(role),
        refetchOnWindowFocus: false,
        staleTime: 12 * 60 * 60 * 1000,
    })
    return data?.list.map((item) => ({
        value: item.id,
        label: locale === VI ? item.name : item.name_en,
        status: item.status,
    }))
}

export default useMalls
