import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import {CUSTOMER_PATH, EVENT, SEARCH_PARKING_PATH} from "../config/path";
import {getRole, isLogin} from "../config/function";
import NotFound from '../components/permission/NotFound';
import { ROLE_PARKING_ADMIN } from '../config/const';

const PublicRoute = ({component: Component, restricted, path, ...rest}) => {
    const role = getRole()

    return (
        // restricted = false meaning public route
        // restricted = true meaning restricted route
        <Route {...rest} render={props => (
            (isLogin() && restricted) ?
                <Redirect to={role !== ROLE_PARKING_ADMIN ? CUSTOMER_PATH : SEARCH_PARKING_PATH} />
                : (path ? <Component {...props} /> : <NotFound isPublicRoute />)
        )} />
    );
};

export default PublicRoute;
