import { SearchOutlined } from "@ant-design/icons";
import { Button, Input, Table, Tag, Modal, Image } from "antd";
import Form from "antd/lib/form/Form";
import FormItem from "antd/lib/form/FormItem";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import QRCode from "react-qr-code";
import Empty from "../../common/Empty";
import IconBinhDuong from "../../common/Icon/IconBinhDuong";
import IconBinhTan from "../../common/Icon/IconBinhTan";
import IconHaDong from "../../common/Icon/IconHaDong";
import IconHaiPhong from "../../common/Icon/IconHaiPhong";
import IconLB from "../../common/Icon/IconLB";
import IconLogo from "../../common/Icon/IconLogo";
import IconTanPhu from "../../common/Icon/IconTanPhu";
import {
  MALL_BINHDUONG_ID,
  MALL_BT_ID,
  MALL_HD_ID,
  MALL_HP_ID,
  MALL_LB_ID,
  MALL_TP_ID,
  VI,
} from "../../config/const";
import { API_GET_PARKING_QR_LIST } from "../../config/endpointApi";
import { getCurrentLanguage, getMallId } from "../../config/function";
import { getAxios } from "../../Http";
import PrivateLayout from "../../layout/PrivateLayout";
import "./SearchParking.scss";

function SearchParking(props) {
  const { t } = useTranslation("parking");
  const mallId = getMallId() || MALL_BINHDUONG_ID;
  const locale = getCurrentLanguage();

  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [keyword, setKeyword] = useState("");

  const [data, setData] = useState();
  const [isFetching, setIsFetching] = useState();
  // const [isLoaded, setIsLoaded] = useState(false)
  const [totalRecord, setTotalRecord] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dataModal, setDataModal] = useState();

  const fetchParkingList = async (newParams) => {
    const params = {
      mall_id: mallId,
      keyword,
      locale,
      perpage: pageSize,
      page: page,
      ...newParams,
    };
    setIsFetching(true);
    const res = await getAxios(API_GET_PARKING_QR_LIST, { ...params });

    if (res && res?.code === 200) {
      setIsFetching(false);
      setData(res?.data?.data);
      setTotalRecord(res?.data?.total);
    }
  };

  useEffect(() => {
    fetchParkingList();
  }, []);

  const maskingPhoneNumber = (phoneNumber) => {
    let subNum = phoneNumber?.toString()?.substring(0, 7);
    subNum = subNum + "***";
    return subNum;
  };
  const maskingEmail = (email) => {
    const maskid = email?.replace(
      /^(...)(.*)(@.*)$/,
      (_, a, b, c) => a + b?.replace(/./g, "*") + c
    );
    return maskid;
  };

  const genLogo = () => {
    switch (mallId) {
      case MALL_BINHDUONG_ID:
        return <IconBinhDuong />;
      case MALL_HD_ID:
        return <IconHaDong />;
      case MALL_HP_ID:
        return <IconHaiPhong />;
      case MALL_BT_ID:
        return <IconBinhTan />;
      case MALL_LB_ID:
        return <IconLB />;
      case MALL_TP_ID:
        return <IconTanPhu />;
      default:
        return <IconLogo />;
    }
  };

  const columns = [
    {
      title: "#",
      dataIndex: "key",
      key: "key",
      render: (value, data, index) => {
        return page * pageSize + index - (pageSize - 1);
      },
    },
    {
      title: t("name"),
      dataIndex: "customer",
      key: "name",
      render: (text, record, index) => text?.full_name,
    },
    {
      title: t("phone"),
      dataIndex: "customer",
      key: "phone",
      render: (text, record, index) => maskingPhoneNumber(text?.phone),
    },
    {
      title: "Email",
      dataIndex: "customer",
      key: "email",
      render: (text, record, index) => maskingEmail(text?.email),
    },
    {
      title: "CMND",
      dataIndex: "customer",
      key: "CMND",
      render: (text, record, index) => text?.identification_number, // citizen_identification
    },
    {
      title: t("status"),
      dataIndex: "status",
      key: "status",
      render: (text, record, index) =>
        text === 1 ? (
          <Tag color="#52C41A">{t("parking")}</Tag>
        ) : (
          <Tag color="warning">{t("not_parking")}</Tag>
        ),
      // text === 1 ? (
      //     <div className="parking-status-button">Đang gửi</div>
      // ) : (
      //     <div className="not-parking-status-button">Không gửi</div>
      // ),
    },
    {
      title: t("parking_text_code"),
      dataIndex: "qr_code",
      key: "parking_text_code",
    },
    {
      title: t("parking_QR_code"),
      dataIndex: "qr_code",
      key: "parking_QR_code",
      render: (text, record, index) => <QRCode value={text} size={90} />,
    },
    {
      title: t("parking_position"),
      dataIndex: "parking_lot",
      key: "current_parking",
      render: (text) => (locale === VI ? text?.name_vi : text?.name_en),
    },
  ];

  const onSearch = async (value) => {
    const keyword = value.keyword;
    setKeyword(keyword);
    const res = await fetchParkingList({ keyword });

    if (res && res?.code === 200) {
      setData(res?.data?.data);
      setTotalRecord(res?.data?.total);
    }
    // setIsLoaded(true)
  };

  const onPageChange = (page, pageSize = 10) => {
    setPageSize(pageSize);
    setPage(page);
    fetchParkingList({ page, perpage: pageSize });
  };

  const showModal = () => {
    // console.log(dataModal)
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <PrivateLayout breadcrumbs={[t("search_parking")]}>
      <div className="search-parking-container">
        <div className="logo-aeon">{genLogo()}</div>
        <Form
          className="search-form"
          onFinish={onSearch}
          initialValues={{ keyword: keyword }}
        >
          <FormItem name={"keyword"} className="search-wrap">
            <Input
              placeholder={t("search_parking_placeholder")}
              allowClear
              onChange={(e) => !e?.target?.value && onSearch({ keyword: "" })}
            />
          </FormItem>
          <Button
            loading={isFetching}
            icon={<SearchOutlined />}
            className="btn btn-common btn-search"
            htmlType="submit"
          />
        </Form>

        {/* {isLoaded && ( */}
        <Table
          style={{ width: "100%" }}
          columns={columns}
          locale={{ emptyText: <Empty /> }}
          dataSource={data}
          loading={isFetching}
          onRow={(record) => {
            return {
              onClick: (event) => {
                setDataModal(record);
                showModal();
              },
            };
          }}
          pagination={{
            total: totalRecord,
            pageSize: pageSize,
            onChange: onPageChange,
            current: page,
            pageSizeOptions: [10, 15, 20, 50],
          }}
          rowKey="id"
        />
        {/* )} */}
      </div>

      <Modal
        className="modal-detail-parking"
        visible={isModalOpen}
        onCancel={handleCancel}
        footer={null}
        closable={null}
        width={746}
        centered={true}
      >
        <div>
          <h3>{t("detail")}</h3>
          <div className="modal-row-content">
            <div className="modal-data">
              <span className="lable">{t("name")}: </span>
              <span>{dataModal?.customer?.full_name}</span>
            </div>
            <div className="modal-data">
              <span className="lable">{t("phone")}: </span>
              <span>{dataModal?.customer?.phone}</span>
            </div>
          </div>
          <div className="modal-row-content">
            <div className="modal-data">
              <span className="lable">Email: </span>
              <span>{dataModal?.customer?.email}</span>
            </div>
            <div className="modal-data">
              <span className="lable">{t("color")}: </span>
              <span>{dataModal?.customer?.vehicle_color}</span>
            </div>
          </div>
          <div className="modal-row-content">
            <div className="modal-data">
              <span className="lable">{t("parking_text_code")}: </span>
              <span>{dataModal?.qr_code}</span>
            </div>
            <div className="modal-data">
              <span className="lable">{t("parking_position")}: </span>
              <span>
                {locale === VI
                  ? dataModal?.parking_lot?.name_vi
                  : dataModal?.parking_lot?.name_en}
              </span>
            </div>
          </div>
          <div>
            <p>
              <span className="lable">{t("id_card")}: </span>
              <span>{dataModal?.customer?.identification_number}</span>
            </p>
            <div className="modal-row-content">
              <div className="modal-data modal-img">
                <span>{t("front")}</span>
                <Image
                  src={
                    dataModal?.customer?.front_of_identification_number_image
                  }
                />
              </div>
              <div className="modal-data modal-img">
                <span>{t("back")}</span>
                <Image
                  src={dataModal?.customer?.back_of_identification_number_image}
                />
              </div>
            </div>
          </div>
          <div className="modal-row-content">
            <div className="modal-data">
              <p>
                <span className="lable">{t("plate_number")}: </span>
                <span>{dataModal?.customer?.plate_number}</span>
              </p>
              <Image src={dataModal?.customer?.plate_number_image} />
            </div>
            <div className="modal-data">
              <p>
                <span className="lable">
                  {t("vehicle_registration_number")}:{" "}
                </span>
                <span>{dataModal?.customer?.vehicle_registration_number}</span>
              </p>
              <Image
                src={dataModal?.customer?.vehicle_registration_number_image}
              />
            </div>
          </div>
          <div className="parking-QR-code">
            <span className="lable">{t("parking_QR_code")}: </span>
            <QRCode value={dataModal?.qr_code} size={90} />
          </div>
          <div>
            <span className="lable">{t("status")}: </span>
            <span>
              {dataModal?.status ? (
                <span className="parking">{t("parking")}</span>
              ) : (
                <span className="not-parking">{t("not_parking")}</span>
              )}
            </span>
          </div>
          <div className="btn-close">
            <button onClick={handleCancel}>{t("close")}</button>
          </div>
        </div>
      </Modal>
    </PrivateLayout>
  );
}

export default SearchParking;
