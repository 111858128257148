import { getAxios } from "Http";
import { API_GET_ORDERED_LIST } from "config/endpointApi";

export default {
  getOrderedListAPI: async (id, keyword, page, status) => {
    return await getAxios(
      `${API_GET_ORDERED_LIST}?customer_id=${id}${
        keyword && "&keyword=" + keyword
      }${page && "&page=" + page}&per_page=10&filter_status=${status}`
    );
  },
};
