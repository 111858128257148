import './App.scss';
import 'antd/dist/antd.css';
import AppRouter from "./routers";
import {
    QueryClient,
    QueryClientProvider,
} from 'react-query'
import AppContextProvider from './context/AppContext';
import { CustomerProvider } from 'components/customer/context/customer/index.js';
import NotificationProvider from 'context/notifications/NotificationContext';

function App() {

    const queryClient = new QueryClient()

    return (
        <QueryClientProvider client={queryClient}>
            <AppContextProvider>
                <NotificationProvider>
                    <CustomerProvider>
                        <AppRouter />
                    </CustomerProvider>
                </NotificationProvider>
            </AppContextProvider>
        </QueryClientProvider>
    );
}

export default App;
