import React, { lazy, Suspense } from 'react'
import { BrowserRouter, Switch } from 'react-router-dom'
// import Layout from "../layout/PrivateLayout";
import routes from './routes'
import RouteLoader from '../common/loader/RouteLoader'
import PrivateRoute from './PrivateRoute'
import PublicRoute from './PublicRoute'
// import NotFound from "../components/permission/NotFound";
// import EventList from '../components/event/EventList'
import { getRole } from '../config/function'
import { ROLE_PARKING_ADMIN } from '../config/const'
import SearchParking from '../components/search-parking/SearchParking'
import ScrollToTop from '../common/ScrollToTop'
import CustomerList from '../components/customer/CustomerList'

const AppRouter = () => {
    const role = getRole()

    return (
        <BrowserRouter>
            {/* Auto scroll to top when moving to a new page */}
            <ScrollToTop />

            <Suspense fallback={<RouteLoader />}>
                <Switch>
                    {routes.map((route, key) => {
                        const { path, exact, isPrivate, component, restricted, isAdminMall, isTenantHead, isBrand, isCS, allowRoles, allowPermissions } = route
                        const props = {
                            key,
                            path,
                            exact,
                            isTenantHead,
                            isBrand,
                            isCS,
                            allowRoles,
                            allowPermissions,
                            component: lazy(() => new Promise((resolve) => setTimeout(() => resolve(component), 200))),
                        }
                        return isPrivate ? (
                            <PrivateRoute isAdminMall={isAdminMall} {...props} />
                        ) : (
                            <PublicRoute restricted={restricted} {...props} />
                        )
                    })}
                    {role !== ROLE_PARKING_ADMIN ? (
                        <PrivateRoute component={CustomerList} />
                    ) : (
                        <PrivateRoute component={SearchParking} />
                    )}
                </Switch>
            </Suspense>
        </BrowserRouter>
    )
}

export default AppRouter
