import {
  Button,
  Col,
  Divider,
  Form,
  Layout,
  Row,
  Tabs,
  notification,
} from "antd";
import moment from "moment";
import qs from "qs";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.js";
import { getAxios, putAxios } from "../../Http";
import EmptyCommon from "../../common/EmptyCommon";

import RouteLoader from "../../common/loader/RouteLoader.js";
import { STATUS, VI, LEVEL_EKYC } from "../../config/const";
import {
  API_CUSTOMER_ADDRESS_LIST,
  API_CUSTOMER_LOCK,
  API_PRODUCT_LIST,
  API_PROVINCE,
} from "../../config/endpointApi";
import {
  bindParams,
  getCurrentLanguage,
  getEcPermissions,
  getPermissions,
} from "../../config/function";
import { CUSTOMER_PATH } from "../../config/path.js";
import useCustomerDetail from "../../hooks/useCustomerDetail";
import PrivateLayout from "../../layout/PrivateLayout";
import "../membership/MemberDetails.scss";
import CustomerDetailAddress from "./CustomerDetailAddress";
import OrderedAll from "./orderedTab/orderedAll";
import ReturnComplain from "./orderedTab/returnComplain";
import ReturnRefund from "./orderedTab/cancelRefund";
import { useCustomer } from "./context/customer";
import ModalNoti from "./items/ModalNoti";
import ModalConfirmLockUnlock from "./items/ModalConfirmLockUnlock";
import {
  CustomerPermissions,
  SubadminRewardsPermissions,
} from "config/permissionsList";
import { handleCallApiOrderedList } from "components/membership/ListOrder";

export const CUSTOMER_KEY = {
  LIST_ORDER: "CUSTOMER_LIST_ORDER",
  DETAIL: "CUSTOMER_DETAIL",
  ALL: 1,
  CANCEL_REFUND: 2,
  RETURN_COMPLAIN: 3,
};

export const getCustomerOrderList = async (params) => {
  const { data } = await getAxios(API_PRODUCT_LIST, params, {
    paramsSerializer: (params) => {
      return qs.stringify(params);
    },
  });

  return data;
};

export const useCustomerOrderList = (params) => {
  return useQuery(
    [CUSTOMER_KEY.LIST_ORDER, params],
    () => getCustomerOrderList(params),
    {
      enabled: !!params,
      // retry: 1,
      refetchOnWindowFocus: false,
    }
  );
};

const DividerCustomMargin = ({ size = 0 }) => (
  <Divider style={{ margin: `${size}px 0px` }} />
);
const Dot = ({ color = "#52C41A" }) => (
  <svg
    width="7"
    height="6"
    viewBox="0 0 7 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_32074_43565)">
      <rect
        x="0.997559"
        width="6.0025"
        height="6.0015"
        rx="3.00075"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0_32074_43565">
        <rect
          x="0.997559"
          width="6.0025"
          height="6.0015"
          rx="3.00075"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
);

const MembeDetails = () => {
  const { t } = useTranslation("member");
  const { id, is_exact } = useParams();
  const [customerData, setCustomerData] = useState({});
  const { data: customer, error, isFetching } = useCustomerDetail(id, is_exact);
  const [listProvince, setListProvince] = useState([]);
  const [province, setProvince] = useState("");
  const [addressData, setAddressData] = useState([]);
  const [modalData, setModalData] = useState({});
  const [district, setDistrict] = useState("");
  const [ward, setWard] = useState("");
  const locale = getCurrentLanguage();
  const location = useLocation();
  const history = useHistory();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const initialFilter = qs.parse(location.search.substr(1));
  const [modalConfirm, setModalConfirm] = useState(false);
  const [modalError, setModalError] = useState(false);
  const [message, setMessage] = useState("");
  const queryClient = useQueryClient();
  const {
    valueDebounceSearch,
    getOrderedList,
    setOrderedList,
    setHasMore,
    currentTab,
    setCurrentTab,
    setPage,
    setKeyword,
    setLoading,
  } = useCustomer();
  const permissions = [...getEcPermissions(), ...getPermissions()];

  useEffect(() => {
    if (id) {
      setLoading(true);
      handleCallApiOrderedList(
        id,
        valueDebounceSearch,
        1,
        setHasMore,
        setOrderedList,
        getOrderedList
      );
      setPage(1);
    }
  }, [id, currentTab]);

  Object.keys(initialFilter).forEach((key) => {
    if (["page", "per_page"].indexOf(key) !== -1) {
      initialFilter[key] = Number(initialFilter[key]);
    }
  });

  const baseParams = useMemo(
    () => ({
      ...initialFilter,
    }),
    []
  );

  const [params, setParams] = useState(baseParams);
  const {
    data: list,
    isLoading: isLoadingCustomerOrderList,
    isFetching: isFetchingCustomerOrderList,
  } = useCustomerOrderList(params);
  const [keyword, setKeyWord] = useState("");

  useEffect(() => {
    const handler = setTimeout(async () => {
      await cb(keyword);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [keyword]);

  const cb = (value) => {
    if (value !== "") {
      setParams({
        ...params,
        keyword: value,
        page: 1,
      });
    } else {
      const newParams = {
        ...params,
      };
      delete newParams.keyword;

      setParams({
        ...newParams,
      });
    }
  };

  useEffect(() => {
    if (customer?.data) {
      setCustomerData(customer?.data);
      listProvince.forEach((province) => {
        if (province.province_id === customer?.data?.province_id) {
          setProvince(province.name);
          province?.children.forEach((district) => {
            if (district.district_id === customer?.data?.district_id) {
              setDistrict(district.name);
              district?.children.forEach((ward) => {
                if (ward.ward_id === customer?.data?.ward_id) {
                  setWard(ward.name);
                }
              });
            }
          });
        }
      });
    }
  }, [customer?.data, listProvince]);

  useEffect(() => {
    getAxios(API_PROVINCE).then((response) => {
      setListProvince(response.data.list);
    });
    getAxios(bindParams(API_CUSTOMER_ADDRESS_LIST, { id: id }), null).then(
      (response) => {
        setAddressData(response.data);
      }
    );
  }, []);

  const handleChangeTab = (key) => {
    setKeyword("");
    setOrderedList([]);
    setCurrentTab(key);
  };

  const showModal = (data) => {
    setIsModalOpen(true);
    setModalData(data);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleToggleLockAccount = async () => {
    let params = {
      is_banned:
        customerData?.is_banned === STATUS.ACTIVE
          ? STATUS.INACTIVE
          : STATUS.ACTIVE,
    };
    try {
      const res = await putAxios(
        bindParams(API_CUSTOMER_LOCK, { id: customer?.data?.id }),
        params
      );
      if (res.code === 200 && !res?.message) {
        // notification.success({
        //   description: t("common:msg_change_status_success"),
        // });
        queryClient.invalidateQueries("customers");
        setMessage(
          customerData?.is_banned === 0
            ? t("msg_lock_success")
            : t("msg_unlock_success")
        );
        setModalError(true);
      } else {
        setMessage(res.message || t("common:edit_fail_msg"));
        setModalError(true);
        // notification.error({
        //   description: t("common:edit_fail_msg"),
        // });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setModalConfirm(false);
    }
  };

  const renderData = () => {
    if (customer && customer.code !== 200)
      return <EmptyCommon text={customer.message} />;
    if (isFetching || isFetchingCustomerOrderList)
      return (
        <div>
          <RouteLoader />
        </div>
      );
    if (error) return <div>Something went wrong...</div>;
    return (
      <Layout className="banner-wrapper banner">
        <div
          className="site-layout-background pos-relative"
          style={{ width: "95%", padding: "24px 232px" }}
        >
          <Tabs
            destroyInactiveTabPane
            defaultActiveKey={params?.type || "1"}
            onChange={(key) => {
              setParams({
                ...params,
                // page: 1,
                // limit: 10,
                type: key,
              });
            }}
          >
            <Tabs.TabPane
              tab={
                <div className="flex-center">
                  <span className="mg-r-4">{t("Thông tin khách hàng")}</span>
                </div>
              }
              key="1"
            >
              <Form layout={"vertical"} className="member-detail-custom">
                <Row gutter={[24, 12]}>
                  <Col span={12}>
                    <Form.Item label={t("name")}>
                      <div>{customerData?.full_name}</div>
                    </Form.Item>
                    <DividerCustomMargin />
                  </Col>
                  <Col span={12}>
                    <Form.Item label={t("birthday_cus")}>
                      <div>
                        {customerData?.birthday === null ? (
                          <>{t("no_birthday")}</>
                        ) : (
                          moment(customerData?.birthday).format("DD.MM.YYYY")
                        )}
                      </div>
                    </Form.Item>
                    <DividerCustomMargin />
                  </Col>

                  <Col span={12}>
                    <Form.Item label={t("gender")}>
                      <div>
                        {customerData?.gender === 0 ? (
                          <>{t("female")}</>
                        ) : customerData?.gender === 1 ? (
                          <>{t("male")}</>
                        ) : (
                          <>{t("data_empty")}</>
                        )}
                      </div>
                    </Form.Item>
                    <DividerCustomMargin />
                  </Col>
                  <Col span={12}>
                    <Form.Item label={t("phone_number")}>
                      <div>
                        {customerData?.phone === null ? (
                          <>{t("data_empty")}</>
                        ) : (
                          <>{customerData?.phone}</>
                        )}
                      </div>
                    </Form.Item>
                    <DividerCustomMargin />
                  </Col>
                  <Col span={12}>
                    <Form.Item label={t("email")}>
                      <div>
                        {customerData?.email === null ? (
                          <>{t("data_empty")}</>
                        ) : (
                          <>{customerData?.email}</>
                        )}
                      </div>
                    </Form.Item>
                    <DividerCustomMargin />
                  </Col>
                  <Col span={12}>
                    <Form.Item label={t("referral_code")}>
                      <div>
                        {customerData?.phone === null ? (
                          <>{t("data_empty")}</>
                        ) : (
                          <>{customerData?.phone}</>
                        )}
                      </div>
                    </Form.Item>
                    <DividerCustomMargin />
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label={
                        locale === VI
                          ? t("identification_number")
                          : t("identification_number_cus")
                      }
                    >
                      <div>
                        {customerData?.identification_number === null ? (
                          <>{t("data_empty")}</>
                        ) : (
                          <>{customerData?.identification_number}</>
                        )}
                      </div>
                    </Form.Item>
                    <DividerCustomMargin />
                  </Col>
                  <Col span={12}>
                    <Form.Item label={t("province_cus")}>
                      <div>
                        {province === "" ? (
                          <>{t("no_address")}</>
                        ) : (
                          <>{province}</>
                        )}
                      </div>
                    </Form.Item>
                    <DividerCustomMargin />
                  </Col>
                  <Col span={12}>
                    <Form.Item label={t("district")}>
                      <div>
                        {province === "" ? (
                          <>{t("no_address")}</>
                        ) : (
                          <>{district}</>
                        )}
                      </div>
                    </Form.Item>
                    <DividerCustomMargin />
                  </Col>
                  <Col span={12}>
                    <Form.Item label={t("ward")}>
                      <div>
                        {" "}
                        {province === "" ? <>{t("no_address")}</> : <>{ward}</>}
                      </div>
                    </Form.Item>
                    <DividerCustomMargin />
                  </Col>
                  <Col span={12}>
                    <Form.Item label={t("address")}>
                      <div>
                        {customerData?.address === null ? (
                          <>{t("no_address")}</>
                        ) : (
                          <>{customerData?.address}</>
                        )}
                      </div>
                    </Form.Item>
                    <DividerCustomMargin />
                  </Col>
                  <Col span={12}>
                    <Form.Item label={t("Là Khách hàng thân thiết")}>
                      <div>
                        {customerData?.loyalty_card_number === null ? (
                          <>{t("Không")}</>
                        ) : (
                          <>{t("Có")}</>
                        )}
                      </div>
                    </Form.Item>
                    <DividerCustomMargin />
                  </Col>
                  <Col span={12}>
                  <Form.Item label={t("Xác thực eKYC")}>
                    <div>
                      {customerData?.level === LEVEL_EKYC.AUTHENTICATED ? (
                        <>{t("Đã xác thực")}</>
                      ) : (
                        <>{t("Chưa xác thực")}</>
                      )}
                    </div>
                  </Form.Item>
                </Col>
                  <Col span={12}>
                    <Form.Item label={t("active_status")}>
                      <div>
                        {customerData?.is_deleted === 1
                          ? t("deleted")
                          : customerData?.is_banned === 1
                          ? t("banned_status")
                          : t("normal_status")}
                      </div>
                    </Form.Item>
                    <DividerCustomMargin />
                  </Col>
                </Row>
              </Form>

              <Row
                style={{ position: "absolute", right: "4vw", bottom: "-20%" }}
              >
                <Button
                  htmlType="button"
                  id="btn-solid"
                  // style={{ position: "absolute", right: "12vw", bottom: "-20%" }}
                  onClick={() => {
                    history.push(CUSTOMER_PATH);
                  }}
                >
                  {t("Quay lại")}
                </Button>
                {customerData?.is_deleted !== 1 && (
                  <Button
                    htmlType="button"
                    id="btn-solid"
                    className={"btn btn-common"}
                    style={{ width: "fit-content" }}
                    onClick={() => {
                      setModalConfirm(true);
                    }}
                  >
                    {customerData?.is_banned === 1 ? t("unlock") : t("lock")}
                  </Button>
                )}
              </Row>
            </Tabs.TabPane>

            {[
              CustomerPermissions.LIST_CUSTOMER_ORDERS,
              ...SubadminRewardsPermissions.ALL,
            ].some((element) => permissions.includes(element)) && (
              <Tabs.TabPane
                tab={
                  <div className="flex-center">
                    <span className="mg-r-4"> {t("Danh sách đơn hàng")}</span>
                  </div>
                }
                key="2"
              >
                <div style={{ backgroundColor: "#F5F5F5", padding: "24px" }}>
                  <div
                    style={{
                      backgroundClip: "content-box",
                      borderRadius: "8px",
                      marginBottom: "8px",
                    }}
                  >
                    <div>
                      <Tabs
                        destroyInactiveTabPane
                        tabBarStyle={{
                          padding: "0 10px",
                          backgroundColor: "#fff",
                          borderRadius: 8,
                        }}
                        defaultActiveKey={currentTab}
                        onChange={(key) => handleChangeTab(key)}
                      >
                        <Tabs.TabPane
                          tab={
                            <div className="flex-center">
                              <span className="mg-r-4">{t("Tất cả")}</span>
                            </div>
                          }
                          key={CUSTOMER_KEY.ALL}
                        >
                          <OrderedAll />
                        </Tabs.TabPane>
                        <Tabs.TabPane
                          tab={
                            <div className="flex-center">
                              <span className="mg-r-4">
                                {t("Huỷ đơn/Hoàn Tiền")}
                              </span>
                            </div>
                          }
                          key={CUSTOMER_KEY.CANCEL_REFUND}
                        >
                          <ReturnRefund />
                        </Tabs.TabPane>
                        <Tabs.TabPane
                          tab={
                            <div className="flex-center">
                              <span className="mg-r-4">
                                {t("Trả hàng/Khiếu nại/Hoàn tiền")}
                              </span>
                            </div>
                          }
                          key={CUSTOMER_KEY.RETURN_COMPLAIN}
                        >
                          <ReturnComplain />
                        </Tabs.TabPane>
                      </Tabs>
                    </div>
                  </div>
                </div>
                <Button
                  htmlType="button"
                  id="btn-solid"
                  style={{
                    position: "absolute",
                    right: "6vw",
                    bottom: "-80px",
                  }}
                  onClick={() => {
                    history.push(CUSTOMER_PATH);
                  }}
                >
                  {t("Quay lại")}
                </Button>
              </Tabs.TabPane>
            )}

            {[
              CustomerPermissions.LIST_CUSTOMER_ADDRESSES,
              ...SubadminRewardsPermissions.ALL,
            ].some((element) => permissions.includes(element)) && (
              <Tabs.TabPane
                tab={
                  <div className="flex-center">
                    <span className="mg-r-4">{t("Sổ địa chỉ")}</span>
                  </div>
                }
                key="3"
              >
                <div
                  style={{
                    maxHeight: "56vh",
                    overflow: "auto",
                    scrollbarWidth: "-moz-initial",
                  }}
                >
                  {addressData && addressData.map((addressItem) => {
                    return (
                      <div key={addressItem?.id}>
                        <p className="text-bold--black">
                          {addressItem?.contact_name}
                        </p>
                        <div
                          className="d-flex"
                          style={{
                            justifyContent: "space-between",
                          }}
                        >
                          <span>{addressItem?.contact_phone}</span>
                          <span
                            onClick={() => showModal(addressItem)}
                            className="table-text--pink"
                            style={{ cursor: "pointer" }}
                            role="none"
                          >
                            {t("Chi tiết")}
                          </span>
                        </div>
                        <p>{addressItem?.full_address}</p>
                        <DividerCustomMargin size={16} />
                      </div>
                    );
                  })}
                </div>
                <Button
                  htmlType="button"
                  id="btn-solid"
                  style={{
                    position: "absolute",
                    right: "6vw",
                    bottom: "-80px",
                  }}
                  onClick={() => {
                    history.push(CUSTOMER_PATH);
                  }}
                >
                  {t("Quay lại")}
                </Button>
              </Tabs.TabPane>
            )}
          </Tabs>
        </div>
      </Layout>
    );
  };
  return (
    <PrivateLayout
      breadcrumbs={[t("customer_title"), t("breadcum_customer_detail")]}
    >
      {renderData()}
      {isModalOpen && (
        <CustomerDetailAddress
          handleCancel={handleCancel}
          isModalOpen={isModalOpen}
          data={modalData}
        />
      )}
      {modalError && (
        <ModalNoti
          visible={modalError}
          onCancel={() => {
            setModalError(false);
          }}
          width={414}
          message={message || t("msg_error")}
        />
      )}
      {modalConfirm && (
        <ModalConfirmLockUnlock
          visible={modalConfirm}
          onCancel={() => {
            setModalConfirm(false);
          }}
          onSubmit={handleToggleLockAccount}
          width={580}
          message={
            customerData?.is_banned === 0
              ? t("msg_confirm_lock")
              : t("msg_confirm_unlock")
          }
        />
      )}
    </PrivateLayout>
  );
};
export default MembeDetails;
