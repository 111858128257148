import { createContext, useContext, useState } from "react";
import api from "../../api";
import { CUSTOMER_KEY } from "components/customer/CustomerDetail";

export const CustomerContext = createContext();

export const CustomerProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [orderedList, setOrderedList] = useState([]);
  const [orderedFilteredList, setOrderedFilteredList] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [valueDebounceSearch, setValueDebounceSearch] = useState("");
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [currentTab, setCurrentTab] = useState(CUSTOMER_KEY.ALL);
  const [loadingTabChange, setLoadingTabChange] = useState(false);

  const { getOrderedListAPI } = api;

  const getOrderedList = async (id, keyword = "", page) => {
    let status = getStatusFromCurrentTab(currentTab);

    return await getOrderedListAPI(id, keyword, page, status)
      .then((res) => res)
      .catch((e) => console.log(e?.message))
      .finally(() => {
        setLoadingTabChange(false);
        setLoading(false);
      });
  };

  const getStatusFromCurrentTab = (currentTab) => {
    const status = {
      [CUSTOMER_KEY.ALL]: "",
      [CUSTOMER_KEY.CANCEL_REFUND]: "CANCELLED",
      [CUSTOMER_KEY.RETURN_COMPLAIN]: "RETURN",
    };

    return status[currentTab];
  };

  const filterOrderedListById = (id) => {
    return orderedList.filter((ordered) => {
      return !!ordered[id];
    });
  };

  const checkEmptyFilterData = (data, key) => {
    const keyNumber = parseInt(key);
    if (keyNumber === CUSTOMER_KEY.CANCEL_REFUND) {
      const dataFilter = data.filter((ordered) => {
        return !!ordered["has_cancel"];
      });
      return dataFilter?.length || 0;
    } else if (keyNumber === CUSTOMER_KEY.RETURN_COMPLAIN) {
      const dataFilter = data.filter((ordered) => {
        return !!ordered["has_return"];
      });
      return dataFilter?.length || 0;
    }
    return data?.length || 0;
  };

  const filterOrderedList = (key) => {
    const keyNumber = parseInt(key);

    if (keyNumber === CUSTOMER_KEY.CANCEL_REFUND) {
      return filterOrderedListById("has_cancel");
    } else if (keyNumber === CUSTOMER_KEY.RETURN_COMPLAIN) {
      return filterOrderedListById("has_return");
    }
    return orderedList;
  };

  if (!children) {
    return <></>;
  }

  return (
    <CustomerContext.Provider
      value={{
        getOrderedList,
        setLoading,
        loading,
        orderedList,
        setOrderedList,
        filterOrderedList,
        orderedFilteredList,
        setOrderedFilteredList,
        setKeyword,
        keyword,
        valueDebounceSearch,
        setValueDebounceSearch,
        page,
        setPage,
        hasMore,
        setHasMore,
        currentTab,
        setCurrentTab,
        loadingTabChange,
        setLoadingTabChange,
        checkEmptyFilterData,
      }}
    >
      {children}
    </CustomerContext.Provider>
  );
};

export const useCustomer = () => {
  return useContext(CustomerContext);
};

export default CustomerProvider;
