import React from 'react';

const IconCategoryTenant = ({half}) => {
    return (
        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0)">
                <path
                    d="M23.0463 23.2111L21.2222 5.8405C21.1839 5.47686 20.8774 5.20073 20.5117 5.20073H17.0833V4.41906C17.0833 1.98248 15.1008 0 12.6642 0C10.2274 0 8.24495 1.98248 8.24495 4.41906V5.20073H4.81649C4.45083 5.20073 4.14432 5.47686 4.10605 5.8405L2.28195 23.2111C2.2609 23.4124 2.32627 23.6132 2.46158 23.7637C2.59708 23.9141 2.79007 24 2.9924 24H22.3356C22.5381 24 22.7311 23.9141 22.8664 23.7637C23.0021 23.6132 23.0673 23.4124 23.0463 23.2111ZM9.67354 4.41906C9.67354 2.7702 11.0152 1.42859 12.6642 1.42859C14.3131 1.42859 15.6547 2.7702 15.6547 4.41906V5.20073H9.67354V4.41906ZM3.78561 22.5714L5.45974 6.62932H8.24495V8.20347C8.24495 8.59788 8.56484 8.91777 8.95925 8.91777C9.35366 8.91777 9.67354 8.59788 9.67354 8.20347V6.62932H15.6547V8.20347C15.6547 8.59788 15.9746 8.91777 16.369 8.91777C16.7634 8.91777 17.0833 8.59788 17.0833 8.20347V6.62932H19.8685L21.5426 22.5714H3.78561Z"
                    fill="#828282"/>
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="24" height="24" fill="white" transform="translate(0.637451)"/>
                </clipPath>
            </defs>
        </svg>

    );
};

export default IconCategoryTenant;
