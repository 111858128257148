import React from 'react';
import PropTypes from 'prop-types';
import logoBinhduong from "../../assets/img/logo-aeonmall-longbien.png"
const IconLB = props => {
    const {onClick} = props
    return <img src={logoBinhduong} alt={''} onClick={onClick}/>
};

IconLB.defaultProps = {
}

IconLB.propTypes = {
    onClick: PropTypes.func
};

export default IconLB;