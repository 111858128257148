import React from 'react'
import PropTypes from 'prop-types'

const IconLogo = (props) => {
    const { onClick } = props
    return (
        <svg
            onClick={onClick}
            xmlns="http://www.w3.org/2000/svg"
            width="262"
            height="36"
            viewBox="0 0 262 36"
            fill="none"
        >
            <path
                d="M170.351 4.12995H170.261L159.261 35.1199H154.771L143.771 4.12995H143.671V35.1199H138.911V0.699951H147.441L157.351 29.0599H157.441L167.171 0.699951H175.121V35.1199H170.351V4.12995Z"
                fill="#B60081"
            />
            <path
                d="M178.501 35.1199L192.991 0.699951H198.091L212.001 35.1199H206.811L203.091 26.16H187.131L183.421 35.1199H178.501V35.1199ZM188.751 22.0599H201.571L195.331 5.41995L188.751 22.0599Z"
                fill="#B60081"
            />
            <path d="M215.481 35.1199V0.699951H220.241V31.02H236.251V35.1199H215.481Z" fill="#B60081" />
            <path d="M240.541 35.1199V0.699951H245.301V31.02H261.321V35.1199H240.541Z" fill="#B60081" />
            <path
                d="M0.471191 35.13H5.53119L31.0612 1.45003V35.15H51.9512V31.11H35.5312V4.82003H51.5712V0.780029H26.5112L0.471191 35.13Z"
                fill="#B60081"
            />
            <path
                d="M116.251 0.780029V27.05L97.4012 0.780029H93.9912V35.15H98.2112V8.87003L117.111 35.15H120.471V0.780029H116.251Z"
                fill="#B60081"
            />
            <path
                d="M72.0513 0C61.6913 0 53.5913 7.89 53.5913 17.96C53.5913 28.04 61.6913 35.93 72.0513 35.93C82.4513 35.93 90.5913 28.04 90.5913 17.96C90.5913 7.89 82.4513 0 72.0513 0ZM72.1413 31.98C64.3913 31.98 58.1113 25.7 58.1113 17.95C58.1113 10.21 64.3913 3.92 72.1413 3.92C79.8913 3.92 86.1713 10.21 86.1713 17.95C86.1713 25.7 79.8913 31.98 72.1413 31.98Z"
                fill="#B60081"
            />
            <path
                d="M60.2612 13.67C66.8612 14.27 68.1812 15.96 68.0212 16.75C67.6212 18.79 59.1212 20.54 48.1812 20.54C37.2012 20.54 28.3212 18.83 28.3212 16.75C28.3212 14.68 38.4912 12.87 50.1712 12.87C50.1712 12.87 51.0212 12.91 51.0212 12.54C51.0012 12.11 50.2012 12.15 50.2012 12.15C33.6512 12.15 24.7712 14.61 24.7712 17.93C24.7712 21.07 35.2512 23.66 48.1812 23.66C61.0812 23.66 71.5612 21.08 71.5612 17.93C71.5612 16.12 68.8212 13.76 60.1712 12.93C60.1712 12.93 59.5212 12.86 59.4812 13.23C59.4512 13.62 60.2612 13.67 60.2612 13.67Z"
                fill="#B60081"
            />
            <path d="M35.5313 8.53003H31.0613V16.95H35.5313V8.53003Z" fill="#B60081" />
            <defs>
                <linearGradient
                    id="paint0_linear"
                    x1="66.0374"
                    y1="31.4885"
                    x2="66.0374"
                    y2="18.9382"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="0.068" stopColor="#F3E1EF" />
                    <stop offset="0.203" stopColor="#DFACD2" />
                    <stop offset="0.34" stopColor="#CD7EB9" />
                    <stop offset="0.475" stopColor="#BE58A4" />
                    <stop offset="0.61" stopColor="#B33B94" />
                    <stop offset="0.743" stopColor="#AA2789" />
                    <stop offset="0.874" stopColor="#A61A82" />
                    <stop offset="1" stopColor="#A41680" />
                </linearGradient>
            </defs>
        </svg>
    )
}

IconLogo.defaultProps = {}

IconLogo.propTypes = {
    onClick: PropTypes.func,
}

export default IconLogo
