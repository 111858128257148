import React from 'react';

const IconUser = ({
    width = 22,
    height = 22,
    viewBox = "0 0 22 22",
    fillSvg = "none",
    fillPath = "#828282",
    onClick = () => {
    }
}) => {
    return (
        <svg width={width} height={height} viewBox={viewBox} fill={fillSvg} xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M11.0001 0C14.7269 0 17.7143 3.23637 17.7143 7.19048C17.7143 9.50566 16.6902 11.5748 15.0931 12.8923C18.8548 13.943 21.1938 16.6069 21.9829 20.8157C22.0911 21.3927 21.6829 21.9287 21.1156 21.9935L21.0001 22H1.00006C0.373922 22 -0.0982023 21.4311 0.0171878 20.8157C0.806331 16.6069 3.14536 13.943 6.90685 12.8921C5.30994 11.5748 4.28577 9.50566 4.28577 7.19048C4.28577 3.23637 7.27319 0 11.0001 0ZM11.0001 14.381C6.412 14.381 3.61315 16.0729 2.40628 19.5624L2.31874 19.8275L2.26606 20H19.7331L19.6814 19.8275C18.5809 16.3335 15.9682 14.5547 11.6599 14.3931L11.3251 14.3838L11.0001 14.381ZM11.0001 2C8.4151 2 6.28577 4.30677 6.28577 7.19048C6.28577 10.0742 8.4151 12.381 11.0001 12.381C13.585 12.381 15.7143 10.0742 15.7143 7.19048C15.7143 4.30677 13.585 2 11.0001 2Z"
                  fill={fillPath}/>
        </svg>

    );
};

export default IconUser;