import { getAxios } from '../Http'
import { API_PROVINCE } from '../config/endpointApi'
import { useQuery } from 'react-query'

const getProvinces = async () => {
    const { data } = await getAxios(API_PROVINCE)
    return data?.list || []
}

function useProvinceQuery(enable = false) {
    return useQuery(['provinces'], getProvinces, {
        refetchOnWindowFocus: false,
        staleTime: 12 * 60 * 60 * 1000, // stale 12 hours
    })
}

export default useProvinceQuery
