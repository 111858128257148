import React from 'react';

const IconEng = () => {
    return (
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.64175 6.50061C1.67445 7.90459 0.939335 9.48104 0.493591 11.1754H7.31681L2.64175 6.50061Z"
                  fill="#29337A"/>
            <path d="M10.799 0.600403C9.07079 1.10372 7.47022 1.90685 6.06079 2.9544L10.799 7.69266V0.600403Z"
                  fill="#29337A"/>
            <path d="M6.41327 27.2958C7.7353 28.2209 9.21231 28.9371 10.7991 29.3991V22.9106L6.41327 27.2958Z"
                  fill="#29337A"/>
            <path d="M0.665771 19.429C1.16174 21.0355 1.92175 22.5259 2.89303 23.8518L7.31583 19.429H0.665771V19.429Z"
                  fill="#29337A"/>
            <path
                d="M2.88924 6.15253C2.80538 6.26716 2.72273 6.38344 2.64173 6.50093L7.31678 11.1757H0.49357C0.382989 11.5966 0.292738 12.0255 0.218384 12.4604H9.19761L2.88924 6.15253Z"
                fill="white"/>
            <path
                d="M6.06531 27.0483C6.17944 27.1333 6.29673 27.2143 6.4132 27.2958L10.7991 22.9104V29.399C11.2204 29.5222 11.6484 29.6285 12.0837 29.714V21.03L6.06531 27.0483Z"
                fill="white"/>
            <path
                d="M0.33252 18.1444C0.42562 18.5797 0.535505 19.0084 0.665529 19.4291H7.31565L2.89286 23.8519C3.37388 24.5091 3.90727 25.1258 4.48684 25.6962L12.0385 18.1444H0.33252Z"
                fill="white"/>
            <path
                d="M10.7992 0.600423V7.69268L6.06085 2.95467C5.40763 3.44044 4.79702 3.97973 4.23126 4.56354L12.0836 12.4159V0.2854C11.6485 0.371408 11.2205 0.477999 10.7992 0.600423Z"
                fill="white"/>
            <path d="M10.7991 19.3837L10.7541 19.429H10.7991V19.3837Z" fill="#D32030"/>
            <path d="M12.0388 18.1443H12.0837V18.0991L12.0388 18.1443Z" fill="#D32030"/>
            <path d="M17.8113 12.4601H17.768V12.5037L17.8113 12.4601Z" fill="#D32030"/>
            <path d="M10.8434 11.1757L10.7991 11.1312V11.1757H10.8434Z" fill="#D32030"/>
            <path d="M12.0837 12.4158V12.4601H12.128L12.0837 12.4158Z" fill="#D32030"/>
            <path
                d="M19.0525 26.0786V29.4411C20.66 28.9911 22.1587 28.2817 23.4992 27.3576L19.8559 23.7142C19.5647 24.7371 19.4582 25.266 19.0525 26.0786Z"
                fill="#252F6C"/>
            <path
                d="M19.8749 6.86988L23.8523 2.89258C22.4234 1.84618 20.8024 1.04874 19.0524 0.558472V4.01258C19.5202 4.94928 19.5595 5.66103 19.8749 6.86988Z"
                fill="#252F6C"/>
            <path d="M27.0454 23.9392C28.047 22.5918 28.8277 21.0708 29.3347 19.429H22.5352L27.0454 23.9392Z"
                  fill="#252F6C"/>
            <path d="M29.5065 11.1757C29.0513 9.4455 28.2941 7.83948 27.2963 6.41345L22.5336 11.1757H29.5065Z"
                  fill="#252F6C"/>
            <path
                d="M20.7505 18.3985L21.7812 19.4293L26.7151 24.3632C26.8272 24.2229 26.9385 24.0832 27.0456 23.9395L22.5354 19.4293H29.3349C29.4645 19.0088 29.5745 18.5799 29.6674 18.1446H20.8469C20.8324 18.3267 20.7678 18.2176 20.7505 18.3985Z"
                fill="#E7E7E7"/>
            <path
                d="M19.7191 7.6221L24.1909 3.14978C24.0789 3.06288 23.9666 2.97643 23.8522 2.89258L19.8749 6.86988C19.9161 7.02714 19.6807 7.46104 19.7191 7.6221Z"
                fill="#E7E7E7"/>
            <path
                d="M19.0524 3.7362V0.558229C18.6315 0.440302 18.2031 0.340361 17.7677 0.25885V1.80901C18.2457 2.32721 18.6741 2.97809 19.0524 3.7362Z"
                fill="#E7E7E7"/>
            <path
                d="M20.6494 12.4601H29.7818C29.7076 12.0252 29.6172 11.5965 29.5066 11.1754H22.5337L27.2964 6.41323C26.8282 5.74436 26.3078 5.11533 25.7394 4.53247L20.3749 9.89647C20.5193 10.8695 20.584 11.4345 20.6494 12.4601Z"
                fill="#E7E7E7"/>
            <path
                d="M19.639 23.497L23.4994 27.3578C24.1414 26.9156 24.7461 26.4234 25.3103 25.8891L20.2326 20.8112C20.0705 21.7503 19.8783 22.6574 19.639 23.497Z"
                fill="#E7E7E7"/>
            <path
                d="M17.7679 28.0126V29.7404C18.2032 29.6594 18.6315 29.5589 19.0526 29.441V26.0786C18.6741 26.837 18.2457 27.4951 17.7679 28.0126Z"
                fill="#E7E7E7"/>
            <path
                d="M20.375 9.89649L25.739 4.53249C25.256 4.03652 24.7383 3.57494 24.1907 3.14978L19.9758 7.36535C20.1632 8.14834 20.2482 9.04458 20.375 9.89649Z"
                fill="#D71F28"/>
            <path
                d="M14.2513 0.0184935C14.5013 0.00639672 14.7504 0 14.9998 0C14.749 0 14.4994 0.00639672 14.2513 0.0184935Z"
                fill="#D32030"/>
            <path d="M14.9997 30C14.7503 30 14.5012 29.9932 14.2518 29.9811C14.4998 29.9936 14.7489 30 14.9997 30Z"
                  fill="#D32030"/>
            <path d="M14.9998 30C15.9462 30 16.8703 29.9083 17.768 29.7404V28.0126C16.98 28.8667 16.0583 30 14.9998 30Z"
                  fill="#D32030"/>
            <path
                d="M29.7817 12.4601H20.7679C20.8873 14.3414 20.8729 16.2759 20.7248 18.1444H29.6675C29.884 17.1299 30 16.0789 30 15.0003C30 14.1339 29.9228 13.2865 29.7817 12.4601Z"
                fill="#D71F28"/>
            <path
                d="M20.7506 18.3985C20.6673 19.2848 20.3769 19.9754 20.2324 20.8115L25.3101 25.8891C25.8125 25.413 26.2821 24.9038 26.715 24.3632L21.7813 19.429L20.7506 18.3985Z"
                fill="#D71F28"/>
            <path
                d="M17.768 1.92054V0.259099C16.8706 0.0916442 15.9465 0 14.9998 0C16.0583 0 16.98 1.06673 17.768 1.92054Z"
                fill="#D32030"/>
            <path d="M19.0524 26.7273C19.4581 25.9145 19.806 24.9787 20.0974 23.956L19.0524 22.9111V26.7273Z"
                  fill="#29337A"/>
            <path d="M20.2274 6.51743C19.9114 5.30857 19.5202 4.2096 19.0524 3.27289V7.69189L20.2274 6.51743Z"
                  fill="#29337A"/>
            <path d="M21.0416 18.6893C21.0587 18.5084 21.0741 18.3268 21.0888 18.1443H20.4966L21.0416 18.6893Z"
                  fill="white"/>
            <path
                d="M19.0524 7.69196V3.27271C18.6739 2.5146 18.2457 1.86327 17.7677 1.34552V9.57297L20.3476 6.99307C20.3088 6.83201 20.268 6.67424 20.2273 6.51699L19.0524 7.69196Z"
                fill="white"/>
            <path d="M17.8113 12.4601H21.1318C21.0667 11.4346 20.963 10.4256 20.8187 9.45288L17.8113 12.4601Z"
                  fill="white"/>
            <path
                d="M17.7679 28.6543C18.2459 28.1361 18.6741 27.4852 19.0526 26.7271V22.9108L20.0976 23.9558C20.3372 23.1157 20.5383 22.2183 20.7008 21.2792L17.768 18.3466L17.7679 28.6543Z"
                fill="white"/>
            <path
                d="M17.768 28.6543V18.3466L20.7006 21.2794C20.8448 20.4431 20.9583 19.5758 21.0414 18.6893L20.4964 18.1443H21.0885C21.2366 16.2759 21.251 14.3413 21.1317 12.4601H17.8111L20.8184 9.4529C20.6916 8.60074 20.5349 7.77652 20.3475 6.99327L17.7681 9.57317V1.34572C16.98 0.491661 16.0582 0 14.9997 0C14.7503 0 14.5012 0.00639672 14.2513 0.0184935C13.5136 0.0554172 12.79 0.146175 12.0837 0.285446V12.4159L4.23121 4.56352C3.74873 5.06139 3.29995 5.59188 2.88923 6.15251L7.91243 11.1757L9.19754 12.4604H0.218375C0.0777107 13.2864 0 14.1338 0 15.0003C0 16.0791 0.116534 17.1299 0.332503 18.1443H12.0388L4.48702 25.6961C4.98153 26.1819 5.50802 26.6342 6.06536 27.0482L12.0835 21.03V29.7141C12.7898 29.8534 13.5138 29.9441 14.2516 29.9811C14.501 29.9932 14.7501 30 14.9996 30C16.0582 30 16.98 29.5083 17.768 28.6543Z"
                fill="#E51D35"/>
        </svg>

    );
};

export default IconEng;