import {
  BrandPermissions,
  CSticketPermissions,
  CouponPermissions,
  CustomerPermissions,
  DisountPermissions,
  MallPermissions,
  NotificationPermissions,
  OrderPermissions,
  PlatformPermissions,
  ProductCatePermissions,
  ProductPermissions,
  StaffRecommendPermissions,
  SubadminRewardsPermissions,
  SubadminTenantPermissions,
  TaxPermissions,
  TenantPermissions,
} from "config/permissionsList";
import {
  ROLE_ADMIN,
  ROLE_BRAND,
  ROLE_CS,
  ROLE_HEAD_SHOP,
  ROLE_MALL,
  ROLE_STAFF,
  ROLE_MALL_ACCOUNTING,
  ROLE_PLATFORM_ACCOUNTING,
  ROLE_PARKING_ADMIN,
} from "../config/const";
import {
  EVENT,
  EVENT_CREATE,
  EVENT_DETAIL,
  EVENT_EDIT,
  EVENT_REPORT,
  ADMIN_LOGIN,
  ADMIN_FORGOT_PASSWORD,
  ADMIN_RESET_PASSWORD,
  CHALLENGE,
  CHALLENGE_CREATE,
  CHALLENGE_DETAIL,
  CHALLENGE_EDIT,
  BANNER_PATH,
  TEAM_PATH,
  IMAGE_PATH,
  FANCLUB,
  FANCLUB_APPROVED,
  FANCLUB_REJECTED,
  FANCLUB_STORES_LIST,
  FANCLUB_REPORT,
  CHALLENGE_REPORT,
  MALL_PATH,
  MALL_ADMIN_CREATE,
  MALL_ADMIN_EDIT,
  MALL_REPORT,
  TENANT_PATH,
  TENANT_CREATE_PATH,
  TENANT_EDIT_PATH,
  TENANT_DETAIL_PATH,
  TENANT_STAFF_PATH,
  TENANT_STAFF_CREATE_PATH,
  TENANT_STAFF_EDIT_PATH,
  TENANT_STAFF_DETAIL_PATH,
  TENANT_TRADE_LIST,
  TENANT_TRADE_DETAIL,
  OTHER_POINT_DETAIL,
  OTHER_POINT_HISTORY,
  POINT_HISTORY,
  REVIEW_PATH,
  REVIEW_REJECTED,
  REVIEW_APPROVED,
  REDEEM_CAMPAIGN_CREATE_PATH,
  MEMBERSHIP_REGISTER,
  REDEEM_CAMPAIGN_DETAIL_PATH,
  MEMBER_UPDATE,
  POS_LIST_CREATE,
  MEMBER_DETAIL,
  CUSTOMER_PATH,
  REDEEM_CAMPAIGN_PATH,
  REDEEM_CAMPAIGN_TRANSACTION_PATH,
  EARNINGRULE_DETAIL_PATH,
  EARNINGRULE_TRADE_PATH,
  MEMBER_PATH,
  MEMBER_REFERRALS,
  COMMENT,
  REVIEW_DETAIL,
  LIST_POS,
  MEMBER_REDEEMS,
  EARNINGRULE_PATH,
  EARNINGRULE_CREATE_PATH,
  TRADE_HISTORY,
  CUSTOMER_DETAIL,
  TENANT_LIST_COIN,
  PURCHASE_DETAIL,
  DASHBOARD_ANALYTICS,
  ANALYTIC_PURCHASE_DETAIL_PATH,
  ANALYTIC_REDEEM_POINT_PATH,
  ANALYTIC_EARNING_POINT_PATH,
  ANALYTIC_FOLLOW_POINT_PATH,
  FOLLOW_PATH,
  REVIEW_REPORT_PRODUCT,
  REVIEW_REPORT_COMMENT,
  TENANT_STAFF_REPORT_PATH,
  TENANT_REPORT_PATH,
  SMS_HISTORY,
  SMS_SENDING_LIST,
  DASHBOARD_STORE,
  SEARCH_PARKING_PATH,
  STATUS_PARKING_PATH,
  CAMPAIGN,
  CAMPAIGN_CREATE,
  CAMPAIGN_EDIT,
  REWARD_CREATE,
  REWARD_EDIT,
  DRAW_HISTORY,
  REQUEST,
  CREATE_MEMBER,
  QUIZ_GAME_CAMPAIGN,
  QUIZ_GAME_CAMPAIGN_CREATE,
  QUIZ_GAME_CAMPAIGN_EDIT,
  QUIZ_GAME_REPORT,
  QUIZ_GAME_QUESTION,
  QUIZ_GAME_QUESTION_CREATE,
  QUIZ_GAME_QUESTION_EDIT,
  TAPU_QR_LIST,
  TAPU_QR_LIST_LOGIN,
  COUPON_CREATE,
  DISCOUNT_CREATE,
  PRODUCT_LIST,
  BRAND_CREATE,
  BRAND_EDIT,
  BRAND_PATH,
  PRODUCT_CREATE,
  PRODUCT_UPDATE,
  DISCOUNT_LIST,
  COUPON_LIST,
  TAX_PATH,
  TAX_CREATE_PATH,
  TAX_EDIT_PATH,
  COUPON_DETAIL,
  COUPON_DETAIL_APPLY,
  PRODUCT_CATEGORY_LIST_PATH,
  PRODUCT_CATEGORY_CREATE_PATH,
  PRODUCT_CATEGORY_EDIT_PATH,
  DISCOUNT_DETAIL,
  DISCOUNT_WAITING_LIST,
  DISCOUNT_RUNNING_LIST,
  DISCOUNT_END_LIST,
  DISCOUNT_VIOLATE_LIST,
  DISCOUNT_EDIT,
  PRODUCT_DETAIL,
  PRODUCT_COPY,
  COUPON_LIST_WAITING,
  COUPON_LIST_RUNNING,
  COUPON_LIST_END,
  COUPON_UPDATE,
  CSTICKET_LIST,
  CSTICKET_CREATE,
  CSTICKET_UPDATE,
  CSTICKET_DETAIL,
  COUPON_LIST_APPROVED,
  COUPON_LIST_REJECTED,
  COUPON_LIST_INCOMING,
  DISCOUNT_UPCOMING_LIST,
  DISCOUNT_REJECTED_LIST,
  DISCOUNT_APPROVED_LIST,
  CSTICKET_COMPLETE_LIST,
  CSTICKET_CANCELED_LIST,
  CSTICKET_PENDING_LIST,
  CUSTOMER_ECOM_LIST_PATH,
  CUSTOMER_ECOM_DETAIL_PATH,
  CUSTOMER_LIST_ORDER,
  CUSTOMER_LIST_ADDRESS,
  ORDER_LIST,
  ORDER_WAIT_CONFIRM_LIST,
  ORDER_WAIT_PICKUP_LIST,
  ORDER_DELIVERY_LIST,
  ORDER_DELIVERED_LIST,
  ORDER_CANCEL_LIST,
  ORDER_RETURN_LIST,
  ORDER_REFUND_LIST,
  ORDER_DETAIL,
  ORDER_REFUND_DETAIL,
  ORDER_COMPLAINT_DETAIL,
  ORDER_CANCEL_DETAIL,
  ORDER_COMPLAINT_LIST,
  ORDER_DELIVERY_FAIL_LIST,
  ORDER_RETURN_DETAIL,
  TRANSACTION_PATH,
  TRANSACTION_DETAIL_PATH,
  // ===================
  WP_TENANT_GALLERY_PATH,
  WP_TENANT_COLLECTION_PATH,
  TENANT_CAMPAIGN_MANAGE_PATH,
  TENANT_CAMPAIGN_EDIT_PATH,
  TENANT_CAMPAIGN_CREATE_PATH,
  REPORT_GEO_DISTRICT,
  REPORT_GEO_WARD,
  REPORT_RETENTION_GROUP,
  REPORT_RETENTION_TIME,
  CHECK_OTP,
  FACILITY_LIST,
  BOOKING_LIST,
  FACILITY_DETAIL,
  USER_OVERLAP,
  COMPARATIVE_COHORT,
  REPORT_GEO_PROVINCE,
  SUB_ADMIN_PATH,
  SUB_ADMIN_CREATE_PATH,
  SUB_ADMIN_EDIT_PATH,
  REPORT_GEO_DISTANCE,
  NOTIFICATION_LIST,
  NOTIFICATION_CREATE_PATH,
  NOTIFICATION_DETAIL_PATH,
  ESURVEY,
  ESURVEY_CREATE_PATH,
  ESURVEY_DETAIL_PATH,
  PARTICIPANT_DETAIL_PATH,
  CAMPAIGN_DETAIL_PATH,
  WP_EVENT,
  WP_EVENT_CREATE,
  WP_EVENT_EDIT,
  WP_NEWS,
  WP_NEWS_CREATE,
  WP_NEWS_EDIT,
  WP_BANNER,
  WP_BANNER_CREATE,
  WP_BANNER_EDIT,
  WP_POPUP,
  WP_POPUP_CREATE,
  WP_POPUP_EDIT,
  WP_PROMOTION,
  WP_PROMOTION_CREATE,
  WP_PROMOTION_EDIT,
  WP_SERVICE,
  WP_SERVICE_CREATE,
  WP_SERVICE_EDIT,
  WP_TENANT,
  WP_TENANT_CREATE,
  WP_TENANT_EDIT,
  WP_CATEGORY,
  WP_CATEGORY_CREATE,
  WP_CATEGORY_EDIT,
  WP_LOCATION_CREATE,
  WP_LOCATION_EDIT,
  WP_LOCATION,
  WP_FLOORMAP_CREATE,
  WP_FLOORMAP_EDIT,
  WP_FLOORMAP,
  WP_FACILITY,
  WP_FACILITY_CREATE,
  WP_FACILITY_EDIT,
  WP_TAG,
  WP_TAG_CREATE,
  WP_TAG_EDIT,
  WP_RECRUITMENT,
  WP_RECRUITMENT_CREATE,
  WP_RECRUITMENT_EDIT,
  WP_COLLECTION,
  WP_COLLECTION_CREATE,
  WP_COLLECTION_EDIT,
  NOTIFICATIONS,
  TENANT_SETTING_DELIVERY,
  TENANT_SETTING_TAX,
  MALL_ADMIN_LIST,
  MALL_CREATE_PATH,
  MALL_EDIT_PATH,
  MALL_WAREHOUSE_SETTING,
  USER_ACCOUNTS_PATH,
  TENANT_SETTING_BANNER,
  TENANT_SETTING_COLOR,
  TENANT_SETTING_WAREHOUSE,
  MALL_BANNER_SETTING,
  MALL_DETAIL_BANNER_SETTING,
  MALL_CREATE_BANNER_SETTING,
  MALL_DETAIL_PATH,
  TENANT_SETTING_DETAIL_BANNER,
  TENANT_SETTING_CREATE_BANNER,
  ECOM_BANNER_SETTING,
  ECOM_BANNER_DETAIL_SETTING,
  REPORT_ORDER_DETAIL,
  REPORT_ORDER_PAYMENT,
  REPORT_ORDER_REFUND,
  REPORT_ORDER_RETURN,
  REPORT_ORDER_TRANSPORT,
  REPORT_ORDER_COMPLAINT,
  REPORT_ORDER_DAILY,
  REPORT_ORDER_MONTHLY,
  REPORT_ORDER_SETTLEMENT,
  REPORT_PRODUCT_PHYSIC,
  REPORT_PRODUCT_EGIFT,
  REPORT_COUPON_CAMPAIGN,
  USER_ACCOUNT_EDIT_PATH,
  USER_ACCOUNT_DETAIL_PATH,
  USER_ACCOUNT_CREATE_PATH,
  MALL_DETAIL_ACCOUNT,
  BRAND_ACCOUNT_LIST_PATH,
  BRAND_ACCOUNT_CREATE_PATH,
  BRAND_ACCOUNT_EDIT_PATH,
  BRAND_ACCOUNT_DETAIL_PATH,
  REPORT_TAX_010,
  REPORT_TAX_011,
  REPORT_TAX_012,
  REPORT_TAX_013,
  REPORT_PAYOO_TOKEN,
  REPORT_TICKET,
  REPORT_DAILY_SALES,
  REPORT_SETTLEMENT_PERIOD,
  REPORT_COD_ASPIRE,
  REPORT_COD_ASPIRE_UPLOAD,
  TENANT_SETTING_TOS,
  REVENUE_PATH,
  REVENUE_PATH_DETAIL,
  USER_PROFILE_PATH,
  USER_PROFILE_EDIT_PATH,
  WP_DISHES,
  WP_DISHES_CREATE,
  WP_DISHES_EDIT,
  WP_TIPS,
  WP_TIPS_CREATE,
  WP_TIPS_EDIT,
  WP_APPLICANT,
  WP_APPLICANT_EDIT,
  ADMIN_LOGOUT,
  TENANT_SETTING_OPERATING_TIME,
} from "../config/path";
import { exact } from "prop-types";

const routes = [
  {
    path: FANCLUB,
    exact: true,
    isPrivate: true,
    component: import("../components/fanclub/FanclubList"),
  },
  {
    path: FANCLUB_APPROVED,
    exact: true,
    isPrivate: true,
    component: import("../components/fanclub/FanclubList"),
  },
  {
    path: FANCLUB_REJECTED,
    exact: true,
    isPrivate: true,
    component: import("../components/fanclub/FanclubList"),
  },
  {
    path: FANCLUB_STORES_LIST,
    exact: true,
    isPrivate: true,
    component: import("../components/fanclub/StoreList"),
  },
  {
    path: EVENT_CREATE,
    exact: true,
    isPrivate: true,
    component: import("../components/event/EventAction"),
    isAdminMall: true,
  },
  {
    path: EVENT,
    exact: true,
    isPrivate: true,
    component: import("../components/event/EventList"),
  },
  {
    path: EVENT_DETAIL,
    exact: true,
    isPrivate: true,
    component: import("../components/event/EventDetail"),
  },
  {
    path: EVENT_EDIT,
    exact: true,
    isPrivate: true,
    component: import("../components/event/EventAction"),
    isAdminMall: true,
  },
  {
    path: EVENT_REPORT,
    exact: true,
    isPrivate: true,
    component: import("../components/event/EventReport"),
  },
  {
    path: ADMIN_LOGIN,
    exact: true,
    isPrivate: false,
    component: import("../components/auth/Login"),
    restricted: true,
  },
  {
    path: ADMIN_LOGOUT,
    exact: true,
    isPrivate: false,
    component: import("../components/auth/Login"),
    restricted: false,
  },
  {
    path: ADMIN_FORGOT_PASSWORD,
    exact: true,
    isPrivate: false,
    component: import("../components/auth/ForgotPassword"),
    restricted: true,
  },
  {
    path: ADMIN_RESET_PASSWORD,
    exact: true,
    isPrivate: false,
    component: import("../components/auth/ResetPassword"),
    restricted: true,
  },
  {
    path: CHALLENGE_CREATE,
    exact: true,
    isPrivate: true,
    component: import("../components/challenge/ChallengeAction"),
    isAdminMall: true,
  },
  {
    path: CHALLENGE,
    exact: true,
    isPrivate: true,
    component: import("../components/challenge/ChallengeList"),
  },
  {
    path: CHALLENGE_DETAIL,
    exact: true,
    isPrivate: true,
    component: import("../components/challenge/ChallengeDetail"),
  },
  {
    path: CHALLENGE_EDIT,
    exact: true,
    isPrivate: true,
    component: import("../components/challenge/ChallengeAction"),
    isAdminMall: true,
  },
  {
    path: CHALLENGE_REPORT,
    exact: true,
    isPrivate: true,
    component: import("../components/challenge/ChallengeReport"),
  },
  {
    path: BANNER_PATH,
    exact: true,
    isPrivate: true,
    component: import("../components/banner/Banner"),
    restricted: true,
  },
  {
    path: ECOM_BANNER_SETTING,
    exact: true,
    isPrivate: true,
    component: import("../components/banner/EcomBannerSetting"),
    restricted: true,
    allowPermissions: [PlatformPermissions.MANAGE_BANNER],
  },
  {
    path: ECOM_BANNER_DETAIL_SETTING,
    exact: true,
    isPrivate: true,
    component: import("../components/banner/EcomDetaillBanner"),
    restricted: true,
    allowPermissions: [PlatformPermissions.MANAGE_BANNER],
  },
  {
    path: TEAM_PATH,
    exact: true,
    isPrivate: true,
    component: import("../components/challenge/TeamList"),
    restricted: true,
  },
  {
    path: IMAGE_PATH,
    exact: true,
    isPrivate: true,
    component: import("../components/challenge/UploadedImageList"),
    restricted: true,
  },
  {
    path: FANCLUB_REPORT,
    exact: true,
    isPrivate: true,
    component: import("../components/fanclub/FanclubReport"),
    restricted: true,
  },
  {
    path: MALL_PATH,
    exact: true,
    isPrivate: true,
    component: import("../components/malls/Mall"),
    restricted: true,
    allowPermissions: [MallPermissions.LIST_MALLS],
  },
  {
    path: MALL_CREATE_PATH,
    exact: true,
    isPrivate: true,
    component: import("../components/malls/MallAction"),
    restricted: true,
    allowPermissions: [MallPermissions.CREATE_MALL],
  },
  {
    path: MALL_EDIT_PATH,
    exact: true,
    isPrivate: true,
    component: import("../components/malls/MallAction"),
    allowPermissions: [MallPermissions.UPDATE_MALL],
    restricted: true,
  },
  {
    path: MALL_WAREHOUSE_SETTING,
    exact: true,
    isPrivate: true,
    component: import("../components/malls/MallWarehouse"),
    restricted: true,
    allowPermissions: [MallPermissions.UPDATE_MALL],
  },
  {
    path: MALL_BANNER_SETTING,
    exact: true,
    isPrivate: true,
    component: import("../components/malls/MallBannerSetting"),
    restricted: true,
    allowPermissions: [MallPermissions.BANNER_MANAGE],
  },
  {
    path: MALL_DETAIL_BANNER_SETTING,
    exact: true,
    isPrivate: true,
    component: import("../components/malls/MallDetailBanner"),
    restricted: true,
    allowPermissions: [MallPermissions.BANNER_MANAGE],
  },
  {
    path: MALL_CREATE_BANNER_SETTING,
    exact: true,
    isPrivate: true,
    component: import("../components/malls/MallDetailBanner"),
    restricted: true,
    allowPermissions: [MallPermissions.BANNER_MANAGE],
  },
  {
    path: MALL_REPORT,
    exact: true,
    isPrivate: true,
    component: import("../components/malls/MallReport"),
    restricted: true,
  },
  {
    path: MALL_DETAIL_PATH,
    exact: true,
    isPrivate: true,
    component: import("../components/malls/MallDetail"),
    restricted: true,
    allowPermissions: [...MallPermissions.ALL],
  },
  {
    path: MALL_ADMIN_LIST,
    exact: true,
    isPrivate: true,
    component: import("../components/malls/MallAdminList"),
    restricted: true,
    allowPermissions: [MallPermissions.MANAGE_ACCOUNT],
  },
  {
    path: MALL_ADMIN_CREATE,
    exact: true,
    isPrivate: true,
    component: import("../components/malls/MallAdminAction"),
    restricted: true,
    allowPermissions: [MallPermissions.MANAGE_ACCOUNT],
  },
  {
    path: MALL_DETAIL_ACCOUNT,
    exact: true,
    isPrivate: true,
    component: import("../components/malls/MallDetailAccount"),
    restricted: true,
    allowPermissions: [MallPermissions.MANAGE_ACCOUNT],
  },
  {
    path: MALL_ADMIN_EDIT,
    exact: true,
    isPrivate: true,
    component: import("../components/malls/MallAdminAction"),
    restricted: true,
    allowPermissions: [MallPermissions.MANAGE_ACCOUNT],
  },
  {
    path: TENANT_PATH,
    exact: true,
    isPrivate: true,
    component: import("../components/tenants/TenantList"),
    allowPermissions: [TenantPermissions.LIST_TENANTS, ...SubadminTenantPermissions.ALL],
  },
  {
    path: TENANT_CREATE_PATH,
    exact: true,
    isPrivate: true,
    component: import("../components/tenants/TenantAction"),
    allowPermissions: [TenantPermissions.CREATE_TENANT, SubadminTenantPermissions.TENANTS_CRUD],
  },
  {
    path: TENANT_EDIT_PATH,
    exact: true,
    isPrivate: true,
    component: import("../components/tenants/TenantAction"),
    isTenantHead: true,
    allowPermissions: [TenantPermissions.EDIT_TENANT, SubadminTenantPermissions.TENANTS_CRUD],
  },
  {
    path: TENANT_DETAIL_PATH,
    exact: true,
    isPrivate: true,
    component: import("../components/tenants/TenantDetail"),
    isTenantHead: true,
    allowPermissions: TenantPermissions.ALL,
  },
  {
    path: TENANT_STAFF_PATH,
    exact: true,
    isPrivate: true,
    component: import("../components/tenants/Staff"),
    isTenantHead: true,
    allowPermissions: [TenantPermissions.MANAGE_ACCOUNT, , ...SubadminTenantPermissions.ALL],
  },
  {
    path: TENANT_STAFF_CREATE_PATH,
    exact: true,
    isPrivate: true,
    component: import("../components/tenants/StaffAction"),
    isTenantHead: true,
    allowPermissions: [TenantPermissions.MANAGE_ACCOUNT, SubadminTenantPermissions.TENANTS_CRUD],
  },
  {
    path: TENANT_STAFF_EDIT_PATH,
    exact: true,
    isPrivate: true,
    component: import("../components/tenants/StaffAction"),
    isTenantHead: true,
    allowPermissions: [TenantPermissions.MANAGE_ACCOUNT, SubadminTenantPermissions.TENANTS_CRUD],
  },
  {
    path: TENANT_STAFF_DETAIL_PATH,
    exact: true,
    isPrivate: true,
    component: import("../components/tenants/StaffDetail"),
    isTenantHead: true,
    allowPermissions: [TenantPermissions.MANAGE_ACCOUNT, ...SubadminTenantPermissions.ALL],
  },
  {
    path: TENANT_STAFF_REPORT_PATH,
    exact: true,
    isPrivate: true,
    component: import("../components/tenants/StaffReport"),
    isTenantHead: true,
    allowPermissions: [TenantPermissions.MANAGE_ACCOUNT, ...SubadminTenantPermissions.ALL],
  },
  {
    path: LIST_POS,
    exact: true,
    isPrivate: true,
    component: import("../components/tenants/TenantListPos"),
    isTenantHead: true,
  },
  {
    path: POINT_HISTORY,
    exact: true,
    isPrivate: true,
    component: import("../components/membership/PointHistory"),
    restricted: true,
  },
  {
    path: REDEEM_CAMPAIGN_PATH,
    exact: true,
    isPrivate: true,
    component: import("../components/redeemCampaign/campaignList"),
  },
  {
    path: REDEEM_CAMPAIGN_CREATE_PATH,
    exact: true,
    isPrivate: true,
    component: import("../components/redeemCampaign/RuleAction"),
  },
  {
    path: POS_LIST_CREATE,
    exact: true,
    isPrivate: true,
    component: import("../components/tenants/TenantListPosAction"),
  },
  {
    path: TENANT_TRADE_LIST,
    exact: true,
    isPrivate: true,
    component: import("../components/tenants/TradeList"),
    isTenantHead: true,
    allowPermissions: [TenantPermissions.LIST_TENANT_TRANSACTIONS, ...SubadminTenantPermissions.ALL],
  },
  {
    path: TENANT_TRADE_DETAIL,
    exact: true,
    isPrivate: true,
    component: import("../components/tenants/TradeDetail"),
    isTenantHead: true,
  },
  {
    path: TENANT_LIST_COIN,
    exact: true,
    isPrivate: true,
    component: import("../components/tenants/TenantListCoin"),
    isTenantHead: true,
    allowPermissions: [TenantPermissions.VIEW_TENANT_TRANSACTIONS_REPORT, ...SubadminTenantPermissions.ALL],
  },
  {
    path: REVIEW_PATH,
    exact: true,
    isPrivate: true,
    component: import("../components/review/Review"),
  },
  {
    path: REVIEW_APPROVED,
    exact: true,
    isPrivate: true,
    component: import("../components/review/Review"),
  },
  {
    path: REVIEW_REJECTED,
    exact: true,
    isPrivate: true,
    component: import("../components/review/Review"),
  },
  {
    path: FOLLOW_PATH,
    exact: true,
    isPrivate: true,
    component: import("../components/follows/index.js"),
  },
  {
    path: COMMENT,
    exact: true,
    isPrivate: true,
    component: import("../components/comments/CommentList"),
  },
  {
    path: REVIEW_DETAIL,
    exact: true,
    isPrivate: true,
    component: import("../components/review/ReviewDetailItem"),
  },
  {
    path: MEMBERSHIP_REGISTER,
    exact: true,
    isPrivate: true,
    component: import("../components/customer/MembershipRegister"),
  },
  {
    path: REDEEM_CAMPAIGN_DETAIL_PATH,
    exact: true,
    isPrivate: true,
    component: import("../components/redeemCampaign/CampaignDetail"),
  },
  {
    path: MEMBER_UPDATE,
    exact: true,
    isPrivate: true,
    component: import("../components/membership/MemberUpdate"),
    restricted: true,
    allowPermissions: [CustomerPermissions.EDIT_CUSTOMER, SubadminRewardsPermissions.CUSTOMERS_CRUD],
  },
  {
    path: MEMBER_DETAIL,
    exact: true,
    isPrivate: true,
    component: import("../components/membership/MemberDetails"),
    restricted: true,
    allowPermissions: [CustomerPermissions.VIEW_CUSTOMER, SubadminRewardsPermissions.CUSTOMERS_VIEW],
  },
  {
    path: EARNINGRULE_PATH,
    exact: true,
    isPrivate: true,
    component: import("../components/earningrule/EarningRuleList"),
  },
  {
    path: CUSTOMER_PATH,
    exact: true,
    isPrivate: true,
    component: import("../components/customer/CustomerList"),
    allowPermissions: [CustomerPermissions.LIST_CUSTOMERS, SubadminRewardsPermissions.CUSTOMERS_VIEW],
  },
  {
    path: REDEEM_CAMPAIGN_TRANSACTION_PATH,
    exact: true,
    isPrivate: true,
    component: import("../components/redeemCampaign/RedeemTransaction"),
  },
  {
    path: EARNINGRULE_DETAIL_PATH,
    exact: true,
    isPrivate: true,
    component: import("../components/earningrule/EarningRuleDetail"),
    isTenantHead: true,
  },
  {
    path: EARNINGRULE_TRADE_PATH,
    exact: true,
    isPrivate: true,
    component: import("../components/earningrule/EarningRuleTrade"),
  },
  {
    path: OTHER_POINT_HISTORY,
    exact: true,
    isPrivate: true,
    component: import("../components/membership/OtherPointHistory"),
    restricted: true,
  },
  {
    path: OTHER_POINT_DETAIL,
    exact: true,
    isPrivate: true,
    component: import("../components/membership/OtherPointDetail"),
  },
  {
    path: MEMBER_PATH,
    exact: true,
    isPrivate: true,
    component: import("../components/membership/MemberList"),
  },
  {
    path: TRADE_HISTORY,
    exact: true,
    isPrivate: true,
    component: import("../components/membership/PurchaseHistory"),
    restricted: true,
  },
  {
    path: PURCHASE_DETAIL,
    exact: true,
    isPrivate: true,
    component: import("../components/membership/PurchaseHistoryDetail"),
    restricted: true,
  },
  {
    path: MEMBER_REFERRALS,
    exact: true,
    isPrivate: true,
    component: import("../components/membership/MemberReferrals"),
    restricted: true,
  },
  {
    path: CUSTOMER_LIST_ADDRESS,
    exact: true,
    isPrivate: true,
    component: import("../components/membership/ListAddress.js"),
    restricted: true,
    allowPermissions: [CustomerPermissions.LIST_CUSTOMER_ADDRESSES, SubadminRewardsPermissions.CUSTOMERS_VIEW],
  },
  {
    path: CUSTOMER_LIST_ORDER,
    exact: true,
    isPrivate: true,
    component: import("../components/membership/ListOrder.js"),
    restricted: true,
    allowPermissions: [CustomerPermissions.LIST_CUSTOMER_ORDERS, SubadminRewardsPermissions.CUSTOMERS_VIEW],
  },
  {
    path: REVIEW_PATH,
    exact: true,
    isPrivate: true,
    component: import("../components/review/Review"),
  },
  {
    path: REVIEW_APPROVED,
    exact: true,
    isPrivate: true,
    component: import("../components/review/Review"),
  },
  {
    path: REVIEW_REJECTED,
    exact: true,
    isPrivate: true,
    component: import("../components/review/Review"),
  },
  {
    path: EARNINGRULE_CREATE_PATH,
    exact: true,
    isPrivate: true,
    component: import("../components/earningrule/CreateManagePoint"),
  },
  {
    path: MEMBER_REDEEMS,
    exact: true,
    isPrivate: true,
    component: import("../components/membership/ReedemTransaction"),
    restricted: true,
  },
  {
    path: CUSTOMER_DETAIL,
    exact: true,
    isPrivate: true,
    component: import("../components/customer/CustomerDetail"),
    restricted: true,
    allowPermissions: [CustomerPermissions.VIEW_CUSTOMER, SubadminRewardsPermissions.CUSTOMERS_VIEW],
  },
  {
    path: CUSTOMER_ECOM_DETAIL_PATH,
    exact: true,
    isPrivate: true,
    component: import("../components/customers-ecom/CustomerDetailEC"),
    restricted: true,
    allowPermissions: [CustomerPermissions.VIEW_CUSTOMER, SubadminRewardsPermissions.CUSTOMERS_VIEW],
  },
  {
    path: DASHBOARD_ANALYTICS,
    exact: true,
    isPrivate: true,
    component: import("../components/report/Dashboard"),
    restricted: true,
  },
  {
    path: DASHBOARD_STORE,
    exact: true,
    isPrivate: true,
    component: import("../components/report/CoinStore"),
    restricted: true,
  },
  {
    path: ANALYTIC_REDEEM_POINT_PATH,
    exact: true,
    isPrivate: true,
    component: import("../components/report/PointRedeemTransaction"),
    restricted: true,
  },
  {
    path: ANALYTIC_EARNING_POINT_PATH,
    exact: true,
    isPrivate: true,
    component: import("../components/report/EarningPointTransaction"),
    restricted: true,
  },
  {
    path: ANALYTIC_PURCHASE_DETAIL_PATH,
    exact: true,
    isPrivate: true,
    component: import("../components/report/PurchaseTransaction"),
    restricted: true,
  },
  {
    path: ANALYTIC_REDEEM_POINT_PATH,
    exact: true,
    isPrivate: true,
    component: import("../components/report/TransactionDetail"),
    restricted: true,
  },
  {
    path: ANALYTIC_FOLLOW_POINT_PATH,
    exact: true,
    isPrivate: true,
    component: import("../components/report/PurchaseRedeemPoint"),
    restricted: true,
  },
  {
    path: REVIEW_REPORT_PRODUCT,
    exact: true,
    isPrivate: true,
    component: import("../components/review/ReviewDetailProduct"),
  },
  {
    path: REVIEW_REPORT_COMMENT,
    exact: true,
    isPrivate: true,
    component: import("../components/review/ReviewDetailComment"),
  },
  {
    path: TENANT_REPORT_PATH,
    exact: true,
    isPrivate: true,
    isTenantHead: true,
    component: import("../components/tenants/TenantReport"),
    allowPermissions: [TenantPermissions.VIEW_TENANT_REPORT, ...SubadminTenantPermissions.ALL],
  },
  {
    path: SMS_HISTORY,
    exact: true,
    isPrivate: true,
    component: import("../components/sms/SmsHistoryList"),
  },
  {
    path: SMS_SENDING_LIST,
    exact: true,
    isPrivate: true,
    component: import("../components/sms/SmsSendingList"),
  },
  {
    path: SEARCH_PARKING_PATH,
    exact: true,
    isPrivate: true,
    component: import("../components/search-parking/SearchParking"),
  },
  {
    path: STATUS_PARKING_PATH,
    exact: true,
    isPrivate: true,
    component: import("../components/parking-status/ParkingStatus"),
  },
  {
    path: CAMPAIGN,
    exact: true,
    isPrivate: true,
    component: import("../components/campaign/Campaign"),
  },
  {
    path: CAMPAIGN_CREATE,
    exact: true,
    isPrivate: true,
    component: import("../components/campaign/CampaignAction"),
  },
  {
    path: CAMPAIGN_EDIT,
    exact: true,
    isPrivate: true,
    component: import("../components/campaign/CampaignAction"),
  },
  // {
  //     path: CAMPAIGN_DETAIL,
  //     exact: true,
  //     isPrivate: true,
  //     component: import('../components/campaign/Detail'),
  // },
  {
    path: TENANT_SETTING_TOS,
    exact: true,
    isPrivate: true,
    component: import("../components/tenants/setting-tos/SettingTos.js"),
    isTenantHead: true,
    allowPermissions: [
      TenantPermissions.CREATE_TENANT_TOS,
      TenantPermissions.VIEW_TENANT_TOS, ...SubadminTenantPermissions.ALL,
    ],
  },
  {
    path: TENANT_SETTING_DELIVERY,
    exact: true,
    isPrivate: true,
    component: import(
      "../components/tenants/setting-delivery/SettingDelivery.js"
    ),
    isTenantHead: true,
    allowPermissions: [
      TenantPermissions.CREATE_TENANT_DELIVERY,
      TenantPermissions.VIEW_TENANT_DELIVERY,
      ...SubadminTenantPermissions.ALL,
    ],
  },
  {
    path: TENANT_SETTING_TAX,
    exact: true,
    isPrivate: true,
    component: import("../components/tenants/setting-tax/SettingTax.js"),
    isTenantHead: true,
    allowPermissions: [
      TenantPermissions.CREATE_TENANT_TAX_REPORT,
      TenantPermissions.VIEW_TENANT_TAX_REPORT,
      TenantPermissions.CREATE_ACCOUNTANT_TAX_REPORT,
      ...SubadminTenantPermissions.ALL
    ],
  },
  {
    path: TENANT_SETTING_BANNER,
    exact: true,
    isPrivate: true,
    component: import("../components/tenants/banner-setup/SetupBannerPage.js"),
    isTenantHead: true,
    allowPermissions: [TenantPermissions.MANAGE_TENANT_BANNER, ...SubadminTenantPermissions.ALL],
  },
  {
    path: TENANT_SETTING_DETAIL_BANNER,
    exact: true,
    isPrivate: true,
    component: import("../components/tenants/banner-setup/SetupBannerDetail"),
    restricted: true,
    allowPermissions: [TenantPermissions.MANAGE_TENANT_BANNER, ...SubadminTenantPermissions.ALL],
    isTenantHead: true,
  },
  {
    path: TENANT_SETTING_CREATE_BANNER,
    exact: true,
    isPrivate: true,
    component: import("../components/tenants/banner-setup/SetupBannerDetail"),
    restricted: true,
    allowPermissions: [TenantPermissions.MANAGE_TENANT_BANNER, ...SubadminTenantPermissions.ALL],
    isTenantHead: true,
  },
  {
    path: TENANT_SETTING_COLOR,
    exact: true,
    isPrivate: true,
    component: import("../components/tenants/color_tenant/ColorTenant.js"),
    isTenantHead: true,
    allowPermissions: [TenantPermissions.MANAGE_TENANT_COLOR, ...SubadminTenantPermissions.ALL],
  },
  {
    path: TENANT_SETTING_WAREHOUSE,
    exact: true,
    isPrivate: true,
    component: import(
      "../components/tenants/warehouse_setup/WarehouseSetupPage.js"
    ),
    isTenantHead: true,
    allowPermissions: [TenantPermissions.VIEW_WAREHOUSE_ADDRESS, ...SubadminTenantPermissions.ALL],
  },
  {
    path: TENANT_SETTING_OPERATING_TIME,
    exact: true,
    isPrivate: true,
    component: import(
      "../components/tenants/operating-time/OperatingTimePage.js"
    ),
    isTenantHead: true,
    allowPermissions: [TenantPermissions.VIEW_CREATE_UPDATE_ACTIVE_TIME, ...SubadminTenantPermissions.ALL],
  },
  {
    path: REWARD_CREATE,
    exact: true,
    isPrivate: true,
    component: import("../components/campaign/RewardAction"),
  },
  {
    path: REWARD_EDIT,
    exact: true,
    isPrivate: true,
    component: import("../components/campaign/RewardAction"),
  },
  {
    path: DRAW_HISTORY,
    exact: true,
    isPrivate: true,
    component: import("../components/drawHistory/DrawHistory"),
    restricted: true,
  },
  {
    path: REQUEST,
    exact: true,
    isPrivate: true,
    component: import("../components/request/RequestList"),
    restricted: true,
  },
  {
    path: CREATE_MEMBER,
    exact: true,
    isPrivate: true,
    component: import("../components/request/RegisterCustomer"),
    restricted: true,
  },
  {
    path: QUIZ_GAME_CAMPAIGN,
    exact: true,
    isPrivate: true,
    component: import("../components/quiz-game/campaign/Campaign"),
    restricted: true,
  },
  {
    path: QUIZ_GAME_CAMPAIGN_CREATE,
    exact: true,
    isPrivate: true,
    component: import("../components/quiz-game/campaign/CampaignAction"),
    restricted: true,
  },
  {
    path: QUIZ_GAME_CAMPAIGN_EDIT,
    exact: true,
    isPrivate: true,
    component: import("../components/quiz-game/campaign/CampaignAction"),
    restricted: true,
  },
  {
    path: QUIZ_GAME_REPORT,
    exact: true,
    isPrivate: true,
    component: import("../components/quiz-game/report"),
    restricted: true,
  },
  {
    path: QUIZ_GAME_QUESTION,
    exact: true,
    isPrivate: true,
    component: import("../components/quiz-game/question"),
    restricted: true,
  },
  {
    path: QUIZ_GAME_QUESTION_CREATE,
    exact: true,
    isPrivate: true,
    component: import("../components/quiz-game/question/QuestionAction"),
    restricted: true,
  },
  {
    path: QUIZ_GAME_QUESTION_EDIT,
    exact: true,
    isPrivate: true,
    component: import("../components/quiz-game/question/QuestionAction"),
    restricted: true,
  },
  {
    path: TAPU_QR_LIST,
    exact: true,
    isPrivate: false,
    component: import("../components/tapu-qr/TapuQrList"),
  },
  {
    path: TAPU_QR_LIST_LOGIN,
    exact: true,
    isPrivate: false,
    component: import("../components/tapu-qr/TapuLogin"),
  },
  {
    path: COUPON_LIST,
    exact: true,
    isPrivate: true,
    component: import("../components/coupon/CouponList"),
    isTenantHead: true,
    isBrand: true,
    allowPermissions: [CouponPermissions.LIST_COUPONS],
  },
  {
    path: COUPON_LIST_APPROVED,
    exact: true,
    isPrivate: true,
    component: import("../components/coupon/CouponList"),
    isTenantHead: true,
    isBrand: true,
    allowPermissions: [CouponPermissions.LIST_COUPONS],
  },
  {
    path: COUPON_LIST_REJECTED,
    exact: true,
    isPrivate: true,
    component: import("../components/coupon/CouponList"),
    isTenantHead: true,
    isBrand: true,
    allowPermissions: [CouponPermissions.LIST_COUPONS],
  },
  {
    path: COUPON_LIST_INCOMING,
    exact: true,
    isPrivate: true,
    component: import("../components/coupon/CouponList"),
    isTenantHead: true,
    isBrand: true,
    allowPermissions: [CouponPermissions.LIST_COUPONS],
  },
  {
    path: COUPON_LIST_WAITING,
    exact: true,
    isPrivate: true,
    component: import("../components/coupon/CouponList"),
    isTenantHead: true,
    isBrand: true,
    allowPermissions: [CouponPermissions.LIST_COUPONS],
  },
  {
    path: COUPON_LIST_RUNNING,
    exact: true,
    isPrivate: true,
    component: import("../components/coupon/CouponList"),
    isTenantHead: true,
    isBrand: true,
    allowPermissions: [CouponPermissions.LIST_COUPONS],
  },
  {
    path: COUPON_LIST_END,
    exact: true,
    isPrivate: true,
    component: import("../components/coupon/CouponList"),
    isTenantHead: true,
    isBrand: true,
    allowPermissions: [CouponPermissions.LIST_COUPONS],
  },
  {
    path: COUPON_CREATE,
    exact: true,
    isPrivate: true,
    component: import("../components/coupon/CouponAction"),
    isTenantHead: true,
    isBrand: true,
    allowPermissions: [CouponPermissions.CREATE_COUPON],
  },

  {
    path: COUPON_UPDATE,
    exact: true,
    isPrivate: true,
    component: import("../components/coupon/CouponAction"),
    isTenantHead: true,
    isBrand: true,
    allowPermissions: [CouponPermissions.EDIT_COUPON],
  },
  {
    path: COUPON_DETAIL,
    exact: true,
    isPrivate: true,
    component: import("../components/coupon/CouponDetail"),
    isTenantHead: true,
    isBrand: true,
    allowPermissions: [CouponPermissions.VIEW_COUPON],
  },
  {
    path: COUPON_DETAIL_APPLY,
    exact: true,
    isPrivate: true,
    component: import("../components/coupon/CouponDetailApply"),
    isTenantHead: true,
    isBrand: true,
    allowPermissions: [CouponPermissions.LIST_COUPONS],
  },
  {
    path: DISCOUNT_CREATE,
    exact: true,
    isPrivate: true,
    component: import("../components/promotion/DiscountAction"),
    restricted: true,
    isTenantHead: true,
    allowPermissions: [DisountPermissions.CREATE_DISCOUNT],
  },
  {
    path: DISCOUNT_EDIT,
    exact: true,
    isPrivate: true,
    component: import("../components/promotion/DiscountAction"),
    restricted: true,
    isTenantHead: true,
    allowPermissions: [DisountPermissions.EDIT_DISCOUNT],
  },
  {
    path: DISCOUNT_DETAIL,
    exact: true,
    isPrivate: true,
    component: import("../components/promotion/DiscountDetail"),
    restricted: true,
    isTenantHead: true,
    allowPermissions: [DisountPermissions.VIEW_DISCOUNT],
  },
  {
    path: DISCOUNT_LIST,
    exact: true,
    isPrivate: true,
    component: import("../components/promotion/DiscountList"),
    restricted: true,
    isTenantHead: true,
    allowPermissions: [DisountPermissions.LIST_DISCOUNTS],
  },
  {
    path: DISCOUNT_APPROVED_LIST,
    exact: true,
    isPrivate: true,
    component: import("../components/promotion/DiscountList"),
    restricted: true,
    isTenantHead: true,
    allowPermissions: [DisountPermissions.LIST_DISCOUNTS],
  },
  {
    path: DISCOUNT_WAITING_LIST,
    exact: true,
    isPrivate: true,
    component: import("../components/promotion/DiscountList"),
    restricted: true,
    isTenantHead: true,
    allowPermissions: [DisountPermissions.LIST_DISCOUNTS],
  },
  {
    path: DISCOUNT_REJECTED_LIST,
    exact: true,
    isPrivate: true,
    component: import("../components/promotion/DiscountList"),
    restricted: true,
    isTenantHead: true,
    allowPermissions: [DisountPermissions.LIST_DISCOUNTS],
  },
  {
    path: DISCOUNT_RUNNING_LIST,
    exact: true,
    isPrivate: true,
    component: import("../components/promotion/DiscountList"),
    restricted: true,
    isTenantHead: true,
    allowPermissions: [DisountPermissions.LIST_DISCOUNTS],
  },
  {
    path: DISCOUNT_UPCOMING_LIST,
    exact: true,
    isPrivate: true,
    component: import("../components/promotion/DiscountList"),
    restricted: true,
    isTenantHead: true,
    allowPermissions: [DisountPermissions.LIST_DISCOUNTS],
  },
  {
    path: DISCOUNT_END_LIST,
    exact: true,
    isPrivate: true,
    component: import("../components/promotion/DiscountList"),
    restricted: true,
    isTenantHead: true,
    allowPermissions: [DisountPermissions.LIST_DISCOUNTS],
  },
  {
    path: DISCOUNT_VIOLATE_LIST,
    exact: true,
    isPrivate: true,
    component: import("../components/promotion/DiscountList"),
    restricted: true,
    isTenantHead: true,
    allowPermissions: [DisountPermissions.LIST_DISCOUNTS],
  },
  {
    path: PRODUCT_LIST,
    exact: true,
    isPrivate: true,
    component: import("../components/product/ProductList"),
    restricted: true,
    isTenantHead: true,
    isBrand: true,
    allowPermissions: [ProductPermissions.LIST_PRODUCTS],
  },
  {
    path: PRODUCT_CREATE,
    exact: true,
    isPrivate: true,
    component: import("../components/product/ProductAction"),
    restricted: true,
    isTenantHead: true,
    allowPermissions: [ProductPermissions.CREATE_PRODUCT],
  },
  {
    path: PRODUCT_UPDATE,
    exact: true,
    isPrivate: true,
    component: import("../components/product/ProductAction"),
    restricted: true,
    isTenantHead: true,
    allowPermissions: [ProductPermissions.EDIT_PRODUCT],
  },
  {
    path: PRODUCT_DETAIL,
    exact: true,
    isPrivate: true,
    component: import("../components/product/ProductDetail"),
    restricted: true,
    isTenantHead: true,
    isBrand: true,
    allowPermissions: [ProductPermissions.VIEW_PRODUCT],
  },
  {
    path: PRODUCT_COPY,
    exact: true,
    isPrivate: true,
    component: import("../components/product/ProductAction"),
    restricted: true,
    isTenantHead: true,
    allowPermissions: [ProductPermissions.EDIT_PRODUCT],
  },
  {
    path: CSTICKET_LIST,
    exact: true,
    isPrivate: true,
    component: import("../components/cs-ticket/CsTicketList"),
    restricted: true,
    isTenantHead: true,
    allowPermissions: [CSticketPermissions.LIST_CS_TICKETS],
  },
  {
    path: CSTICKET_PENDING_LIST,
    exact: true,
    isPrivate: true,
    component: import("../components/cs-ticket/CsTicketList"),
    restricted: true,
    isTenantHead: true,
    allowPermissions: [CSticketPermissions.LIST_CS_TICKETS],
  },
  {
    path: CSTICKET_CANCELED_LIST,
    exact: true,
    isPrivate: true,
    component: import("../components/cs-ticket/CsTicketList"),
    restricted: true,
    isTenantHead: true,
    allowPermissions: [CSticketPermissions.LIST_CS_TICKETS],
  },
  {
    path: CSTICKET_COMPLETE_LIST,
    exact: true,
    isPrivate: true,
    component: import("../components/cs-ticket/CsTicketList"),
    restricted: true,
    isTenantHead: true,
    allowPermissions: [CSticketPermissions.LIST_CS_TICKETS],
  },
  {
    path: CSTICKET_CREATE,
    exact: true,
    isPrivate: true,
    component: import("../components/cs-ticket/CsTicketAction"),
    restricted: true,
    isTenantHead: true,
    allowPermissions: [CSticketPermissions.CREATE_CS_TICKET],
  },
  {
    path: CSTICKET_UPDATE,
    exact: true,
    isPrivate: true,
    component: import("../components/cs-ticket/CsTicketAction"),
    restricted: true,
    isTenantHead: true,
    allowPermissions: [CSticketPermissions.EDIT_CS_TICKET],
  },
  {
    path: CSTICKET_DETAIL,
    exact: true,
    isPrivate: true,
    component: import("../components/cs-ticket/CsTicketDetail"),
    restricted: true,
    isTenantHead: true,
    allowPermissions: [CSticketPermissions.VIEW_CS_TICKET],
  },

  {
    path: BRAND_PATH,
    exact: true,
    isPrivate: true,
    component: import("../components/brand/BrandList"),
    allowPermissions: BrandPermissions.ALL,
  },
  {
    path: BRAND_CREATE,
    exact: true,
    isPrivate: true,
    component: import("../components/brand/BrandAction"),
    allowPermissions: [BrandPermissions.CREATE_BRAND],
  },
  {
    path: BRAND_EDIT,
    exact: true,
    isPrivate: true,
    component: import("../components/brand/BrandAction"),
    allowPermissions: [BrandPermissions.UPDATE_BRAND],
  },
  {
    path: BRAND_ACCOUNT_LIST_PATH,
    exact: true,
    isPrivate: true,
    component: import("../components/brand/BrandAccountList"),
    allowPermissions: [BrandPermissions.MANAGE_ACCOUNT],
  },
  {
    path: BRAND_ACCOUNT_CREATE_PATH,
    exact: true,
    isPrivate: true,
    component: import("../components/brand/BrandAccountAction"),
    allowPermissions: [BrandPermissions.MANAGE_ACCOUNT],
  },
  {
    path: BRAND_ACCOUNT_EDIT_PATH,
    exact: true,
    isPrivate: true,
    component: import("../components/brand/BrandAccountAction"),
    allowPermissions: [BrandPermissions.MANAGE_ACCOUNT],
  },
  {
    path: BRAND_ACCOUNT_DETAIL_PATH,
    exact: true,
    isPrivate: true,
    component: import("../components/brand/BrandDetailAccount"),
    allowPermissions: [BrandPermissions.MANAGE_ACCOUNT],
  },
  {
    path: TAX_PATH,
    exact: true,
    isPrivate: true,
    component: import("../components/tax/TaxList"),
    allowPermissions: [TaxPermissions.LIST_TAXES],
  },
  {
    path: REVENUE_PATH,
    exact: true,
    isPrivate: true,
    isTenantHead: true,
    component: import("../components/revenue"),
    allowRoles: [
      ROLE_ADMIN,
      ROLE_HEAD_SHOP,
      ROLE_MALL_ACCOUNTING,
      ROLE_STAFF,
      ROLE_BRAND,
    ],
  },
  {
    path: REVENUE_PATH_DETAIL,
    exact: true,
    isPrivate: true,
    isTenantHead: true,
    component: import("../components/revenue/revenue-adjustment"),
    allowRoles: [
      ROLE_ADMIN,
      ROLE_HEAD_SHOP,
      ROLE_MALL_ACCOUNTING,
      ROLE_STAFF,
      ROLE_BRAND,
    ],
    // allowRoles: [ROLE_ADMIN],
  },

  {
    path: TAX_CREATE_PATH,
    exact: true,
    isPrivate: true,
    component: import("../components/tax/TaxAction"),
    allowPermissions: [TaxPermissions.CREATE_TAX],
  },
  {
    path: TAX_EDIT_PATH,
    exact: true,
    isPrivate: true,
    component: import("../components/tax/TaxAction"),
    allowPermissions: [TaxPermissions.EDIT_TAX],
  },
  // {
  //   path: TRANSACTION_PATH,
  //   exact: true,
  //   isPrivate: true,
  //   component: import("../components/transaction/TransactionList"),
  //   // restricted: true,
  //   isTenantHead: true,
  //   allowRoles: [ROLE_ADMIN, ROLE_MALL, ROLE_HEAD_SHOP],
  // },
  // {
  //   path: TRANSACTION_DETAIL_PATH,
  //   exact: true,
  //   isPrivate: true,
  //   component: import("../components/transaction/TransactionDetail"),
  //   // restricted: true,
  //   isTenantHead: true,
  //   allowRoles: [ROLE_ADMIN, ROLE_MALL, ROLE_HEAD_SHOP],
  // },
  {
    path: PRODUCT_CATEGORY_LIST_PATH,
    exact: true,
    isPrivate: true,
    component: import("../components/product-category/CategoryList"),
    allowPermissions: [ProductCatePermissions.LIST_CATEGORIES],
  },
  {
    path: PRODUCT_CATEGORY_CREATE_PATH,
    exact: true,
    isPrivate: true,
    component: import("../components/product-category/CategoryAction"),
    allowPermissions: [ProductCatePermissions.CREATE_CATEGORY],
  },
  {
    path: PRODUCT_CATEGORY_EDIT_PATH,
    exact: true,
    isPrivate: true,
    component: import("../components/product-category/CategoryAction"),
    allowPermissions: [ProductCatePermissions.EDIT_CATEGORY],
  },
  {
    path: CUSTOMER_ECOM_LIST_PATH,
    exact: true,
    isPrivate: true,
    component: import("../components/customers-ecom/CustomerList"),
    allowRoles: [ROLE_MALL],
  },
  {
    path: ORDER_LIST,
    exact: true,
    isPrivate: true,
    component: import("../components/orders/OrderList.js"),
    restricted: true,
    isTenantHead: true,
    allowPermissions: [OrderPermissions.LIST_ORDERS],
  },
  {
    path: ORDER_WAIT_CONFIRM_LIST,
    exact: true,
    isPrivate: true,
    component: import("../components/orders/OrderList.js"),
    restricted: true,
    isTenantHead: true,
    allowPermissions: [OrderPermissions.LIST_ORDERS],
  },
  {
    path: ORDER_WAIT_PICKUP_LIST,
    exact: true,
    isPrivate: true,
    component: import("../components/orders/OrderList.js"),
    restricted: true,
    isTenantHead: true,
    allowPermissions: [OrderPermissions.LIST_ORDERS],
  },
  {
    path: ORDER_DELIVERY_LIST,
    exact: true,
    isPrivate: true,
    component: import("../components/orders/OrderList.js"),
    restricted: true,
    isTenantHead: true,
    allowPermissions: [OrderPermissions.LIST_ORDERS],
  },
  {
    path: ORDER_DELIVERED_LIST,
    exact: true,
    isPrivate: true,
    component: import("../components/orders/OrderList.js"),
    restricted: true,
    isTenantHead: true,
    allowPermissions: [OrderPermissions.LIST_ORDERS],
  },
  {
    path: ORDER_CANCEL_LIST,
    exact: true,
    isPrivate: true,
    component: import("../components/orders/order_cancel/OrderCancelList.js"),
    restricted: true,
    isTenantHead: true,
    allowPermissions: [OrderPermissions.LIST_ORDERS],
  },
  {
    path: ORDER_RETURN_LIST,
    exact: true,
    isPrivate: true,
    component: import("../components/orders/order_return/OrderReturnList.js"),
    restricted: true,
    isTenantHead: true,
    allowPermissions: [OrderPermissions.LIST_ORDERS],
  },
  {
    path: ORDER_COMPLAINT_LIST,
    exact: true,
    isPrivate: true,
    component: import(
      "../components/orders/order_complaint/OrderComplainList.js"
    ),
    restricted: true,
    isTenantHead: true,
    allowPermissions: [OrderPermissions.LIST_ORDERS],
  },
  {
    path: ORDER_REFUND_LIST,
    exact: true,
    isPrivate: true,
    component: import("../components/orders/order-refund/OrderRefundList.js"),
    restricted: true,
    isTenantHead: true,
    allowPermissions: [OrderPermissions.LIST_ORDERS],
  },
  {
    path: ORDER_DETAIL,
    exact: true,
    isPrivate: true,
    component: import("../components/orders/OrderDetail.js"),
    restricted: true,
    isTenantHead: true,
    allowPermissions: [OrderPermissions.VIEW_ORDER],
  },
  {
    path: ORDER_REFUND_DETAIL,
    exact: true,
    isPrivate: true,
    component: import("../components/orders/order-refund/OrderRefundDetail.js"),
    restricted: true,
    isTenantHead: true,
    allowPermissions: [OrderPermissions.VIEW_ORDER],
  },
  {
    path: ORDER_RETURN_DETAIL,
    exact: true,
    isPrivate: true,
    component: import("../components/orders/order_return/OrderReturnDetail.js"),
    restricted: true,
    isTenantHead: true,
    allowPermissions: [OrderPermissions.VIEW_ORDER],
  },
  {
    path: ORDER_COMPLAINT_DETAIL,
    exact: true,
    isPrivate: true,
    component: import("../components/orders/order_complaint/ComplainDetail.js"),
    restricted: true,
    isTenantHead: true,
    allowPermissions: [OrderPermissions.VIEW_ORDER],
  },
  {
    path: ORDER_CANCEL_DETAIL,
    exact: true,
    isPrivate: true,
    component: import("../components/orders/order_cancel/OrderCancelDetail.js"),
    restricted: true,
    isTenantHead: true,
    allowPermissions: [OrderPermissions.VIEW_ORDER],
  },
  {
    path: ORDER_DELIVERY_FAIL_LIST,
    exact: true,
    isPrivate: true,
    component: import("../components/orders/OrderList.js"),
    restricted: true,
    isTenantHead: true,
    allowPermissions: [OrderPermissions.LIST_ORDERS],
  },
  // ===================
  {
    path: TENANT_CAMPAIGN_MANAGE_PATH,
    exact: true,
    isPrivate: true,
    component: import("../components/tenants/CampaignRewardList"),
  },
  {
    path: TENANT_CAMPAIGN_EDIT_PATH,
    exact: true,
    isPrivate: true,
    component: import("../components/tenants/CampaignRewardAction"),
  },
  {
    path: TENANT_CAMPAIGN_CREATE_PATH,
    exact: true,
    isPrivate: true,
    component: import("../components/tenants/CampaignRewardAction"),
  },
  {
    path: REPORT_GEO_PROVINCE,
    exact: true,
    isPrivate: true,
    component: import("../components/userTracking/ReportProvince.js"),
  },
  {
    path: REPORT_GEO_DISTRICT,
    exact: true,
    isPrivate: true,
    component: import("../components/userTracking/ReportDistrict.js"),
  },
  {
    path: REPORT_GEO_WARD,
    exact: true,
    isPrivate: true,
    component: import("../components/userTracking/ReportWard"),
  },
  {
    path: REPORT_RETENTION_TIME,
    exact: true,
    isPrivate: true,
    component: import("../components/userTracking/ReportRetention"),
  },
  {
    path: REPORT_RETENTION_GROUP,
    exact: true,
    isPrivate: true,
    component: import("../components/userTracking/ReportRetetionGroup"),
  },
  {
    path: CHECK_OTP,
    exact: true,
    isPrivate: true,
    component: import("../components/userTracking/CheckOTP"),
  },
  {
    path: USER_OVERLAP,
    exact: true,
    isPrivate: true,
    component: import("../components/userTracking/UserOverLap"),
  },
  {
    path: COMPARATIVE_COHORT,
    exact: true,
    isPrivate: true,
    component: import("../components/userTracking/ComparativeCohort"),
  },
  {
    path: REPORT_GEO_DISTANCE,
    exact: true,
    isPrivate: true,
    component: import("../components/userTracking/ReportDistance"),
  },
  {
    path: FACILITY_LIST,
    exact: true,
    isPrivate: true,
    component: import("../components/facilityBooking/Facility/FacilityList"),
  },
  {
    path: `${FACILITY_DETAIL}`,
    exact: true,
    isPrivate: true,
    component: import("../components/facilityBooking/Facility/FacilityDetail"),
  },
  {
    path: BOOKING_LIST,
    exact: true,
    isPrivate: true,
    component: import("../components/facilityBooking/Booking/BookingList"),
  },
  {
    path: NOTIFICATION_LIST,
    exact: true,
    isPrivate: true,
    component: import("../components/manualNotification/NotificationList"),
  },
  {
    path: NOTIFICATION_CREATE_PATH,
    exact: true,
    isPrivate: true,
    component: import("../components/manualNotification/CreateNotification"),
  },
  {
    path: NOTIFICATION_DETAIL_PATH,
    exact: true,
    isPrivate: true,
    component: import("../components/manualNotification/CreateNotification"),
  },
  {
    path: ESURVEY,
    exact: true,
    isPrivate: true,
    component: import("../components/eSurvey/eSurveyList"),
  },
  {
    path: ESURVEY_CREATE_PATH,
    exact: true,
    isPrivate: true,
    component: import("../components/eSurvey/CreateESurvey.js"),
  },
  {
    path: ESURVEY_DETAIL_PATH,
    exact: true,
    isPrivate: true,
    component: import("../components/eSurvey/CreateESurvey.js"),
  },
  {
    path: CAMPAIGN_DETAIL_PATH,
    exact: true,
    isPrivate: true,
    component: import("../components/eSurvey/eSurveyDetail.js"),
  },
  {
    path: PARTICIPANT_DETAIL_PATH,
    exact: true,
    isPrivate: true,
    component: import("../components/eSurvey/ParticipantDetail.js"),
  },
  {
    path: WP_EVENT,
    exact: true,
    isPrivate: true,
    component: import("../components/wordpressConvert/event/EventList"),
  },
  {
    path: WP_EVENT_CREATE,
    exact: true,
    isPrivate: true,
    component: import("../components/wordpressConvert/event/EventAction"),
  },
  {
    path: WP_EVENT_EDIT,
    exact: true,
    isPrivate: true,
    component: import("../components/wordpressConvert/event/EventAction"),
    isAdminMall: true,
  },
  {
    path: WP_NEWS,
    exact: true,
    isPrivate: true,
    component: import("../components/wordpressConvert/news/NewsList"),
  },
  {
    path: WP_NEWS_CREATE,
    exact: true,
    isPrivate: true,
    component: import("../components/wordpressConvert/news/NewsAction"),
  },
  {
    path: WP_NEWS_EDIT,
    exact: true,
    isPrivate: true,
    component: import("../components/wordpressConvert/news/NewsAction"),
    isAdminMall: true,
  },
  {
    path: WP_PROMOTION,
    exact: true,
    isPrivate: true,
    component: import("../components/wordpressConvert/promotion/PromotionList"),
  },
  {
    path: WP_PROMOTION_CREATE,
    exact: true,
    isPrivate: true,
    component: import(
      "../components/wordpressConvert/promotion/PromotionAction"
    ),
  },
  {
    path: WP_PROMOTION_EDIT,
    exact: true,
    isPrivate: true,
    component: import(
      "../components/wordpressConvert/promotion/PromotionAction"
    ),
    isAdminMall: true,
  },
  {
    path: WP_SERVICE,
    exact: true,
    isPrivate: true,
    component: import("../components/wordpressConvert/service/ServiceList"),
  },
  {
    path: WP_SERVICE_CREATE,
    exact: true,
    isPrivate: true,
    component: import("../components/wordpressConvert/service/ServiceAction"),
  },
  {
    path: WP_SERVICE_EDIT,
    exact: true,
    isPrivate: true,
    component: import("../components/wordpressConvert/service/ServiceAction"),
    isAdminMall: true,
  },
  {
    path: WP_POPUP,
    exact: true,
    isPrivate: true,
    component: import("../components/wordpressConvert/popup/PopupList"),
  },
  {
    path: WP_POPUP_CREATE,
    exact: true,
    isPrivate: true,
    component: import("../components/wordpressConvert/popup/PopupAction"),
  },
  {
    path: WP_POPUP_EDIT,
    exact: true,
    isPrivate: true,
    component: import("../components/wordpressConvert/popup/PopupAction"),
    isAdminMall: true,
  },
  {
    path: WP_BANNER,
    exact: true,
    isPrivate: true,
    component: import("../components/wordpressConvert/banner/BannerList"),
  },
  {
    path: WP_BANNER_CREATE,
    exact: true,
    isPrivate: true,
    component: import("../components/wordpressConvert/banner/BannerAction"),
  },
  {
    path: WP_BANNER_EDIT,
    exact: true,
    isPrivate: true,
    component: import("../components/wordpressConvert/banner/BannerAction"),
    isAdminMall: true,
  },
  {
    path: WP_TENANT,
    isTenantHead: true,
    exact: true,
    isPrivate: true,
    component: import("../components/wordpressConvert/tenant/TenantList"),
    allowRoles: [ROLE_ADMIN, ROLE_MALL, ROLE_PLATFORM_ACCOUNTING],
  },
  {
    path: WP_TENANT_CREATE,
    isTenantHead: true,
    exact: true,
    isPrivate: true,
    component: import("../components/wordpressConvert/tenant/TenantAction"),
  },
  {
    path: WP_TENANT_EDIT,
    isTenantHead: true,
    exact: true,
    isPrivate: true,
    component: import("../components/wordpressConvert/tenant/TenantAction"),
    isAdminMall: false,
  },
  {
    path: WP_CATEGORY,
    isTenantHead: true,
    exact: true,
    isPrivate: true,
    component: import("../components/wordpressConvert/category/CategoryList"),
  },
  {
    path: WP_CATEGORY_CREATE,
    exact: true,
    isPrivate: true,
    component: import("../components/wordpressConvert/category/CategoryAction"),
  },
  {
    path: WP_CATEGORY_EDIT,
    exact: true,
    isPrivate: true,
    component: import("../components/wordpressConvert/category/CategoryAction"),
    isAdminMall: true,
  },
  {
    path: WP_LOCATION,
    exact: true,
    isPrivate: true,
    component: import("../components/wordpressConvert/location/LocationList"),
  },
  {
    path: WP_LOCATION_CREATE,
    exact: true,
    isPrivate: true,
    component: import("../components/wordpressConvert/location/LocationAction"),
  },
  {
    path: WP_LOCATION_EDIT,
    exact: true,
    isPrivate: true,
    component: import("../components/wordpressConvert/location/LocationAction"),
    isAdminMall: true,
  },
  {
    path: WP_FLOORMAP,
    exact: true,
    isPrivate: true,
    component: import("../components/wordpressConvert/floormap/FloorMapList"),
  },
  {
    path: WP_FLOORMAP_CREATE,
    exact: true,
    isPrivate: true,
    component: import("../components/wordpressConvert/floormap/FloorMapAction"),
  },
  {
    path: WP_FLOORMAP_EDIT,
    exact: true,
    isPrivate: true,
    component: import("../components/wordpressConvert/floormap/FloorMapAction"),
    isAdminMall: true,
  },
  {
    path: WP_FACILITY,
    exact: true,
    isPrivate: true,
    component: import("../components/wordpressConvert/facility/FacilityList"),
  },
  {
    path: WP_FACILITY_CREATE,
    exact: true,
    isPrivate: true,
    component: import("../components/wordpressConvert/facility/FacilityAction"),
  },
  {
    path: WP_FACILITY_EDIT,
    exact: true,
    isPrivate: true,
    component: import("../components/wordpressConvert/facility/FacilityAction"),
    isAdminMall: true,
  },
  {
    path: WP_TAG,
    exact: true,
    isPrivate: true,
    component: import("../components/wordpressConvert/tag/TagList"),
  },
  {
    path: WP_TAG_CREATE,
    exact: true,
    isPrivate: true,
    component: import("../components/wordpressConvert/tag/TagAction"),
  },
  {
    path: WP_TAG_EDIT,
    exact: true,
    isPrivate: true,
    component: import("../components/wordpressConvert/tag/TagAction"),
    isAdminMall: true,
  },
  {
    path: WP_RECRUITMENT,
    exact: true, 
    component: import(
      "../components/wordpressConvert/recruitment/RecruitmentList"
    ),
  },
  {
    path: WP_RECRUITMENT_CREATE,
    exact: true,
    component: import(
      "../components/wordpressConvert/recruitment/RecruitmentAction"
    ),
  },
  {
    path: WP_RECRUITMENT_EDIT,
    exact: true,
    component: import(
      "../components/wordpressConvert/recruitment/RecruitmentAction"
    ),
    isAdminMall: true,
  },
  {
    path: WP_COLLECTION,
    exact: true,
    isPrivate: true,
    component: import(
      "../components/wordpressConvert/collection/CollectionList"
    ),
  },
  {
    path: WP_COLLECTION_CREATE,
    exact: true,
    isPrivate: true,
    component: import(
      "../components/wordpressConvert/collection/CollectionAction"
    ),
  },
  {
    path: WP_COLLECTION_EDIT,
    exact: true,
    isPrivate: true,
    component: import(
      "../components/wordpressConvert/collection/CollectionAction"
    ),
    isAdminMall: true,
  },
  {
    path: NOTIFICATIONS,
    exact: true,
    isPrivate: true,
    isTenantHead: true,
    component: import("../components/notification"),
    // allowPermissions: [NotificationPermissions.LIST_NOTIFICATIONS],
  },
  {
    path: USER_ACCOUNTS_PATH,
    isPrivate: true,
    exact: true,
    component: import("../components/userAccounts"),
    allowPermissions: [PlatformPermissions.MANAGE_ACCOUNT],
  },
  {
    path: USER_ACCOUNT_EDIT_PATH,
    isPrivate: true,
    exact: true,
    component: import("../components/userAccounts/EditAccount"),
    allowPermissions: [PlatformPermissions.MANAGE_ACCOUNT],
  },
  {
    path: USER_ACCOUNT_CREATE_PATH,
    isPrivate: true,
    exact: true,
    component: import("../components/userAccounts/CreateAccount"),
    allowPermissions: [PlatformPermissions.MANAGE_ACCOUNT],
  },
  {
    path: USER_ACCOUNT_DETAIL_PATH,
    isPrivate: true,
    exact: true,
    component: import("../components/userAccounts/DetailAccount"),
    allowPermissions: [PlatformPermissions.MANAGE_ACCOUNT],
  },
  {
    path: REPORT_ORDER_DETAIL,
    exact: true,
    isPrivate: true,
    component: import("../components/report/OrderDetail"),
  },
  {
    path: REPORT_ORDER_PAYMENT,
    exact: true,
    isPrivate: true,
    component: import("../components/report/OrderPayment"),
  },
  {
    path: REPORT_ORDER_REFUND,
    exact: true,
    isPrivate: false,
    component: import("../components/report/OrderRefund"),
  },
  {
    path: REPORT_ORDER_RETURN,
    exact: true,
    isPrivate: false,
    component: import("../components/report/OrderReturn"),
  },
  {
    path: REPORT_ORDER_TRANSPORT,
    exact: true,
    isPrivate: false,
    component: import("../components/report/OrderTransport"),
  },
  {
    path: REPORT_ORDER_COMPLAINT,
    exact: true,
    isPrivate: false,
    component: import("../components/report/OrderComplaint"),
  },
  {
    path: REPORT_ORDER_DAILY,
    exact: true,
    isPrivate: false,
    component: import("../components/report/OrderDaily"),
  },
  {
    path: REPORT_ORDER_MONTHLY,
    exact: true,
    isPrivate: false,
    component: import("../components/report/OrderMonthly"),
  },
  {
    path: REPORT_ORDER_SETTLEMENT,
    exact: true,
    isPrivate: false,
    component: import("../components/report/OrderSettlement"),
  },
  {
    path: REPORT_PRODUCT_PHYSIC,
    exact: true,
    isPrivate: false,
    component: import("../components/report/ProductPhysic"),
  },
  {
    path: REPORT_PRODUCT_EGIFT,
    exact: true,
    isPrivate: false,
    component: import("../components/report/ProductEgift"),
  },
  {
    path: REPORT_COUPON_CAMPAIGN,
    exact: true,
    isPrivate: false,
    component: import("../components/report/CouponCampaign"),
  },
  {
    path: REPORT_TAX_010,
    exact: true,
    isPrivate: true,
    component: import("../components/report/Tax010"),
  },
  {
    path: REPORT_TAX_011,
    exact: true,
    isPrivate: true,
    component: import("../components/report/Tax011"),
  },
  {
    path: REPORT_TAX_012,
    exact: true,
    isPrivate: true,
    component: import("../components/report/Tax012"),
  },
  {
    path: REPORT_TAX_013,
    exact: true,
    isPrivate: true,
    component: import("../components/report/Tax013"),
  },
  {
    path: REPORT_PAYOO_TOKEN,
    exact: true,
    isPrivate: true,
    component: import("../components/report/PayooToken"),
  },
  {
    path: REPORT_TICKET,
    exact: true,
    isPrivate: true,
    component: import("../components/report/Ticket"),
  },
  {
    path: REPORT_DAILY_SALES,
    exact: true,
    isPrivate: true,
    component: import("../components/report/DailySales"),
  },
  {
    path: REPORT_SETTLEMENT_PERIOD,
    exact: true,
    isPrivate: true,
    component: import("../components/report/SettlementPeriod"),
  },
  {
    path: REPORT_COD_ASPIRE,
    exact: true,
    isPrivate: true,
    component: import("../components/report/CodAspire"),
  },
  {
    path: REPORT_COD_ASPIRE_UPLOAD,
    exact: true,
    isPrivate: true,
    component: import("../components/report/CodAspireUpload"),
  },
  {
    path: USER_PROFILE_PATH,
    exact: true,
    isPrivate: true,
    component: import("../components/myAccount/DetailAccount"),
    isTenantHead: true,
  },
  {
    path: USER_PROFILE_EDIT_PATH,
    exact: true,
    isPrivate: true,
    component: import("../components/myAccount/EditAccount"),
    isTenantHead: true,
  },
  {
    path: WP_TENANT_GALLERY_PATH,
    exact: true,
    isPrivate: true,
    component: import("../components/tenants/TenantGallery"),
    isTenantHead: true,
  },
  {
    path: WP_TENANT_COLLECTION_PATH,
    exact: true,
    isPrivate: true,
    component: import("../components/tenants/TenantCollection"),
    isTenantHead: true,
  },
  {
    path: SUB_ADMIN_PATH,
    exact: true,
    isPrivate: true,
    component: import("../components/sub-admin/SubAdminList"),
  },
  {
    path: SUB_ADMIN_CREATE_PATH,
    exact: true,
    isPrivate: true,
    component: import("../components/sub-admin/SubAdminAction"),
  },
  {
    path: SUB_ADMIN_EDIT_PATH,
    exact: true,
    isPrivate: true,
    component: import("../components/sub-admin/SubAdminAction"),
  },
  {
    path: WP_DISHES,
    exact: true,
    component: import("../components/wordpressConvert/dish/DishList"),
  },
  {
    path: WP_DISHES_CREATE,
    exact: true,
    component: import("../components/wordpressConvert/dish/DishAction"),
  },
  {
    path: WP_DISHES_EDIT,
    exact: true,
    component: import("../components/wordpressConvert/dish/DishAction"),
    isAdminMall: true,
  },
  {
    path: WP_TIPS,
    exact: true,
    component: import("../components/wordpressConvert/dish/TipList"),
  },
  {
    path: WP_TIPS_CREATE,
    exact: true,
    component: import("../components/wordpressConvert/dish/TipAction"),
  },
  {
    path: WP_TIPS_EDIT,
    exact: true,
    component: import("../components/wordpressConvert/dish/TipAction"),
    isAdminMall: true,
  },
  {
    path: WP_APPLICANT,
    exact: true,
    component: import(
      "../components/wordpressConvert/recruitment/ApplicantList"
    ),
    isAdminMall: true,
  },
  {
    path: WP_APPLICANT_EDIT,
    exact: true,
    component: import(
      "../components/wordpressConvert/recruitment/ApplicantAction"
    ),
    isAdminMall: true,
  },
];

export default routes;
