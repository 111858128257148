import React from 'react'
import styles from './styles.module.scss'
import clsx from 'clsx'

function NotificationItem(props) {
    return (
        <div
            onClick={props.viewDetailNotification}
            className={clsx(styles.wrapper_notification, {
                [styles.background_second]: props.isActivated,
                [props.className]: props.className,
            })}
        >
            <div className={styles.wrapper_image}>
                {props.img && <img src={props.img ?? ''} alt="" />}
            </div>
            <div className={styles.wrapper_content}>
                <p className={styles.title}>{props.title ?? ''}</p>
                <p className={clsx(styles.des, 'line-clamp-3')}>{props.des ?? ''}</p>
                {props.time && <p className={clsx(styles.time, 'line-clamp-3')}>{props.time}</p>}
            </div>
        </div>
    )
}

export default NotificationItem