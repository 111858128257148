import { getAxios, putAxios } from "Http"
import { API_GET_NOTIFICATIONS, API_READ_NOTIFICATION, API_READ_ALL_NOTIFICATIONS } from "config/endpointApi"

export const getNotificationsAPI = async (params) => {
    return await getAxios(API_GET_NOTIFICATIONS, params || null)
}

export const readNotificationAPI = async (id) => {
    return await putAxios(`${API_READ_NOTIFICATION}/${id}`, null)
}

export const readAllNotificationsAPI = async () => {
    return await putAxios(API_READ_ALL_NOTIFICATIONS, null)
}
