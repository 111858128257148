import { camelCase, filter } from "lodash";
import {
  AUTH_TOKEN,
  DATE_DASHBOARD_DEFAULT,
  DATE_DEFAULT,
  DEFAULT_LANGUAGE,
  KEY_LANGUAGE,
  ROLE_ADMIN,
  USER_INFO,
  PAGE_LIMIT,
  SCROLL_TO_ID,
  MALL_NAME,
  FALLBACK_AVATAR,
  BOOKING_STATUS,
  PERMISSIONS,
  BEFORE_TAX,
  AFTER_TAX,
  FLAT_PRICE,
  DISCOUNT_FIXED,
  DISCOUNT_PERCENTAGE,
} from "./const";
import moment from "moment";
import { getAxios } from "../Http";
import { notification } from "antd";
import { ADMIN_LOGIN } from "./path";

// get current language
export const getCurrentLanguage = () => {
  const lang = localStorage.getItem(KEY_LANGUAGE);
  return lang ? lang : DEFAULT_LANGUAGE;
};

// get translate resource files
export const getTranslationFiles = (languages, translationFiles) => {
  const resources = {};
  for (let i = 0; i < languages.length; i++) {
    resources[languages[i]] = {};
    for (let j = 0; j < translationFiles.length; j++) {
      try {
        resources[languages[i]][
          camelCase(translationFiles[j])
        ] = require(`../locales/${languages[i]}/${translationFiles[j]}.json`);
      } catch (error) { }
    }
  }
  return resources;
};

export const formatPrice = (price) => {
  if (!price) {
    return 0
  }
  if (typeof price === 'string') return price.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const bindParams = (str, params = {}) => {
  let result = str;
  for (let key in params) {
    result = result?.replace(new RegExp(`:${key}`, "g"), params[key]);
  }
  return result;
};

export const isLogin = () => {
  return (
    !!localStorage.getItem(AUTH_TOKEN) && !!localStorage.getItem(USER_INFO)
  );
};

export const scrollToErrorField = (errorFields) => {
  const el = document.getElementById(errorFields[0]?.name[0]);
  el?.scrollIntoView();
  window.scrollTo(0, window.scrollY - el?.offsetHeight - 90);
};

export const scrollToErrorFieldByName = (name) => {
  const el = document.getElementById(name);

  if (!el) {
    return;
  }

  el.scrollIntoView();
  window.scrollTo(0, window.scrollY - el.offsetHeight - 72);
};

export const commonScroll = (errorFields, formName = '') => {
  const el = document.getElementById(`${formName}${errorFields[0]?.name[0]}`)

  if (el) {
    el.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }
}

export const formatObjectReport = (
  page = 1,
  date_from = moment().subtract(1, "months"),
  date_to = moment(),
  date_from_overview = moment().subtract(1, "months"),
  date_to_overview = moment(),
  type = "all",
  mall_id
) => {
  let role = getRole();
  let data = {
    page_overview: page,
    date_from: date_from.format(DATE_DEFAULT),
    date_to: date_to.format(DATE_DEFAULT),
    date_from_overview: date_from_overview.format(DATE_DEFAULT),
    date_to_overview: date_to_overview.format(DATE_DEFAULT),
    type: type,
  };
  if (role === ROLE_ADMIN) {
    data.mall_id = mall_id;
  }
  return data;
};

export const formatObjectDashboardReport = (
  begin = moment().subtract(1, "months"),
  end = moment(),
  mall_id
) => {
  let role = getRole();
  let data = {
    begin: begin.format(DATE_DASHBOARD_DEFAULT),
    end: end.format(DATE_DASHBOARD_DEFAULT),
  };
  if (role === ROLE_ADMIN) {
    data.mall_id = mall_id;
  }
  return data;
};

export const getRole = () =>
  localStorage.getItem("user_info")
    ? JSON.parse(localStorage.getItem("user_info")).rk_role_id
    : null; // ROLE_ADMIN

export const checkIsJoinEc = () =>
      localStorage.getItem("join_ec")
        ? JSON.parse(localStorage.getItem("join_ec"))
        : null;

export const getPermissions = () => {
  if (localStorage.getItem("user_info") && JSON.parse(localStorage.getItem("user_info")).permission_array) {
    return JSON.parse(localStorage.getItem("user_info")).permission_array;
  } else return [];
}

// export const getEcPermissions = () => 
//   localStorage.getItem("user_info")
//     ? JSON.parse(localStorage.getItem("user_info")).ec_permission_array
//     : null; // EC PERMISSIONS

export const getEcPermissions = () => {
  const dataParse = JSON.parse(localStorage.getItem("user_info"))
  if (!dataParse || !dataParse.ec_permission_array) {
    localStorage.clear();
    return [];
  }
  return dataParse.ec_permission_array;
};

export const getMallId = () =>
  localStorage.getItem("user_info")
    ? JSON.parse(localStorage.getItem("user_info"))?.mall_id
    : null;
export const getAccountId = () =>
  localStorage.getItem("user_info")
    ? JSON.parse(localStorage.getItem("user_info"))?.id
    : null;

export const getUserName = () => 
  localStorage.getItem("user_name")
    ? localStorage.getItem("user_name")
    : null;

export const getMallName = (mallId) => MALL_NAME?.[`${mallId}`];

export const isEditableEvent = (event) =>
  true || (event && moment(event.start_at).diff(moment()) > 0);
export const isEditableChallenge = (challenge) =>
  challenge && moment(challenge?.finish_at).diff(moment()) > 0;
export const isEditablePromotion = (event) =>
  true || (event && moment(event.start_date).diff(moment()) > 0);

export const getUserTenantId = () =>
  localStorage.getItem("user_info")
    ? JSON.parse(localStorage.getItem("user_info")).tenant_id
    : null;

export const getExportFileNameByType = (type) => {
  let fileName = "data";
  switch (type) {
    case "checked":
      fileName = "khach_hang_check_in";
      break;
    case "total":
      fileName = "danh_sach_tong_hop";
      break;
    case "used_voucher":
      fileName = "khach_hang_su_dung_voucher";
      break;
    case "attached_users":
      fileName = "danh_sach_tre_dang_ky_di_kem";
      break;
    case "registered":
      fileName = "khach_hang_da_dang_ky";
      break;
    case "completed":
      fileName = "khach_hang_da_hoan_thanh_thu_thach";
      break;
    default:
      break;
  }
  return fileName;
};

export const fetchList = async (endpoint, params) => {
  const { data } = await getAxios(endpoint, params);
  return data;
};

// test api

function buildFormData(formData, data, parentKey) {
  if (
    data &&
    typeof data === "object" &&
    !(data instanceof Date) &&
    !(data instanceof File)
  ) {
    Object.keys(data).forEach((key) => {
      buildFormData(
        formData,
        data[key],
        parentKey ? `${parentKey}[${key}]` : key
      );
    });
  } else {
    const value = data == null ? "" : data;

    formData.append(parentKey, value);
  }
}

export function jsonToFormData(data) {
  const formData = new FormData();

  buildFormData(formData, data);

  return formData;
}

export const sortObject = (input) => {
  return Object.keys(input)
    .sort()
    .reduce((obj, key) => {
      obj[key] = input[key];
      return obj;
    }, {});
};

export const compareArrays = (array1, array2) =>
  array1.sort().join(",") === array2.sort().join(",");

export const numberFormatter = (value) => {
  if (!value) {
    return value;
  }
  return new Intl.NumberFormat().format(value);
};

export const numberParser = (val) => {
  if (typeof val === "string" && !val.length) {
    return "";
  }

  try {
    // removing everything except the digits
    var reversedVal = val.replace(/[^0-9]/g, "");
    return Number.isNaN(reversedVal) ? "" : reversedVal;
  } catch (error) {
    console.error(error);
  }
};

export const setPageLimit = (key, limit) => {
  const pageLimit = JSON.parse(localStorage.getItem(PAGE_LIMIT) || "{}");

  pageLimit[key] = Number(limit);

  localStorage.setItem(PAGE_LIMIT, JSON.stringify(pageLimit));
};

export const getPageLimit = (key) => {
  const pageLimit = JSON.parse(localStorage.getItem(PAGE_LIMIT) || "{}");

  return Number(pageLimit[key]) || 10;
};

// Start - Get list of administrative units
export const _getListProvinces = (fullData) => {
  let provinces = [];

  if (!fullData) return;

  fullData.forEach((province) => {
    provinces.push({
      value: province?.province_id,
      label: province?.name,
    });
  });

  return provinces;
};

export const _getListDistricts = (provinceId, fullData) => {
  let districts = [];

  if (!fullData) return;

  fullData.forEach((province) => {
    if (province?.province_id === provinceId) {
      province?.children?.forEach((dist) => {
        districts.push({
          value: dist?.district_id,
          label: dist?.name,
        });
      });
    }
  });

  return districts;
};

export const _getListWards = (provinceId, districtId, fullData) => {
  let wards = [];

  if (!fullData) return;

  fullData.forEach((province) => {
    if (province?.province_id === provinceId) {
      province?.children?.forEach((dist) => {
        if (dist?.district_id === districtId) {
          dist?.children?.forEach((ward) => {
            wards.push({
              value: ward?.ward_id,
              label: ward?.name,
            });
          });
        }
      });
    }
  });

  return wards;
};
// End - Get list of administrative units

// Start - Get completed address from province, district, ward
export const _getCompletedAddress = (province, district, ward, address) => {
  let fullAddress = address;

  fullAddress += ward ? `, ${ward}` : "";
  fullAddress += district ? `, ${district}` : "";
  fullAddress += province ? `, ${province}` : "";

  return fullAddress;
};

//Fixed to number behind decimal
export const numberFixed = (value, number) => {
  if (!Number.isInteger(value)) {
    return value?.toFixed(number)?.replace(".", ",");
  }

  return value;
};

// Check positive interger
export const isPositiveInteger = (value) => {
  const numberValue = Number(value);

  return (
    typeof numberValue === "number" &&
    Number.isInteger(numberValue) &&
    numberValue > 0
  );
};

// Scroll to previous position of list item before redirecting to detail page
export const scrollToPrevPosInList = (id) => {
  const el = document.getElementById(id);

  if (!el) return;
  else {
    el.scrollIntoView();
    window.scrollTo(0, window.scrollY - el.offsetHeight - 72);

    localStorage.removeItem(SCROLL_TO_ID);
  }
};

// Get avatar URL
export const getAvatarSrc = (imgSrc = "") => {
  const prefix = process.env.REACT_APP_BASE_URL;
  return imgSrc && imgSrc?.includes("http")
    ? imgSrc
    : imgSrc
      ? `${prefix}/${imgSrc}`
      : FALLBACK_AVATAR;
};

export const openNotification = (message, type = "info") => {
  notification[type]({
    message: message,
    duration: 2,
  });
};

export const getBookingStatus = (status, t) => {
  if (typeof status !== "undefined") {
    switch (Number(status)) {
      case BOOKING_STATUS.FACILITY_BOOKING_STATUS_CANCEL:
        return t("FACILITY_BOOKING_STATUS_CANCEL");
      case BOOKING_STATUS.FACILITY_BOOKING_STATUS_CREATED:
        return t("FACILITY_BOOKING_STATUS_CREATED");
      case BOOKING_STATUS.FACILITY_BOOKING_STATUS_PICKUP:
        return t("FACILITY_BOOKING_STATUS_PICKUP");
      case BOOKING_STATUS.FACILITY_BOOKING_STATUS_RETURN:
        return t("FACILITY_BOOKING_STATUS_RETURN");
      default:
        break;
    }
  }
}

export function formatNumberWithDot(n) {
  if (n < 100) return `${n}%`
  return n ? n.toString().replace(/\B(?!\.\d*)(?=(\d{3})+(?!\d))/g, '.') : 'N/A'
}

export function removeNumberAfterDot(number) {
  return number.toString().split('.')[0]
}

export const renderLabel = (title, required = false) => (
  <div className="coupon-form-title">
    <span className="required">{required && '*'}</span>
    <span>{title}</span>
  </div>
);

export const renderRequiredLabel = (content) => <><span style={{ color: '#d13d9e', fontSize: '16px', marginRight: '4px' }}>*</span>{" "}<span style={{ fontSize: '16px' }}>{content}</span></>

export const renderCommonBlackLabel = (content) => <label htmlFor="select_mall" className='common-label--black'>{content}</label>

export const deleteWhiteSpace = (str) => {
  return str.trim().replace(/\s/g, "")
}
export const flattenCategory = (fullCategory) => {
  const categoryLevel1 = fullCategory?.map((category) => {
    if (!category.children) {
      return category;
    } else {
      return { id: category.id, name: category.name, level: category.level };
    }
  });
  const categoryLevel2 = [];
  fullCategory
    ?.filter((category) => category.children)
    .map((category) => {
      category.children.map((child) => categoryLevel2.push(child));
    });
  const categoryLevel3 = [];
  categoryLevel2
    .filter((category) => category.children)
    .map((category) => {
      category.children.map((child) => categoryLevel3.push(child));
    });

  const result = [
    ...categoryLevel1,
    ...categoryLevel2,
    ...categoryLevel3,
  ].map((category) => {
    if (category.children) {
      delete category.children;
    }
    return category;
  });
  return result;
}

export const calculateSellingPrice = (product, discountType, percentValue, percentMaxPrice, taxType, fixedPrice, flatPrice) => {
  percentValue = parseFloat(percentValue);
  const productOriginalPriceBeforeTax = parseFloat(product?.original_price_before_tax);
  const productOriginalPriceAfterTax = parseFloat(product?.original_price_after_tax);
  const productValueTax = parseFloat(product?.rate);
  const percentMaxValue = parseFloat(percentMaxPrice);
  const fixedValue = parseFloat(fixedPrice);
  const flatValue = parseFloat(flatPrice);

  //discount flat price
  if (discountType === FLAT_PRICE && flatValue) {
    return flatValue;
  }

  // discount price after discounted percent
  const beforeTaxDiscountedPercentPrice = (productOriginalPriceBeforeTax * percentValue) / 100;
  const afterTaxDiscountedPercentPrice = (productOriginalPriceAfterTax * percentValue) / 100;

  let beforeTaxDiscountedPrice;
  let sellingPrice;

  // discount before tax
  if (taxType === BEFORE_TAX) {
    if (discountType === DISCOUNT_PERCENTAGE && percentValue) {
      if (percentMaxValue) {
        // Case price after discount <= discount percent max
        if (beforeTaxDiscountedPercentPrice <= percentMaxValue) {
          beforeTaxDiscountedPrice = productOriginalPriceBeforeTax - beforeTaxDiscountedPercentPrice;
          sellingPrice = (productValueTax && productValueTax > 0) ? beforeTaxDiscountedPrice + ((beforeTaxDiscountedPrice * productValueTax) / 100) : beforeTaxDiscountedPrice;
          return sellingPrice.toFixed(2);
        } else {
          // Case price after discount > discount percent max
          beforeTaxDiscountedPrice = productOriginalPriceBeforeTax - percentMaxValue;
          sellingPrice = (productValueTax && productValueTax > 0) ? beforeTaxDiscountedPrice + ((beforeTaxDiscountedPrice * productValueTax) / 100) : beforeTaxDiscountedPrice;
          return sellingPrice.toFixed(2);
        }
      } else {
        beforeTaxDiscountedPrice = productOriginalPriceBeforeTax - beforeTaxDiscountedPercentPrice;
        sellingPrice = (productValueTax && productValueTax > 0) ? beforeTaxDiscountedPrice + ((beforeTaxDiscountedPrice * productValueTax) / 100) : beforeTaxDiscountedPrice;
        return sellingPrice.toFixed(2);
      }
    } else if (discountType === DISCOUNT_FIXED && fixedValue) {
      beforeTaxDiscountedPrice = productOriginalPriceBeforeTax - fixedValue;
      sellingPrice = (productValueTax && productValueTax > 0) ? beforeTaxDiscountedPrice + ((beforeTaxDiscountedPrice * productValueTax) / 100) : beforeTaxDiscountedPrice;
      return sellingPrice.toFixed(2);
    }
  }

  // discount after tax
  if (taxType === AFTER_TAX) {
    if (discountType === DISCOUNT_PERCENTAGE && percentValue) {
      if (percentMaxValue) {
        // Case price after discount <= discount percent max
        if (afterTaxDiscountedPercentPrice <= percentMaxValue) {
          sellingPrice = productOriginalPriceAfterTax - afterTaxDiscountedPercentPrice;
          return sellingPrice.toFixed(2);
        } else {
          // Case price after discount > discount percent max
          sellingPrice = productOriginalPriceAfterTax - percentMaxValue;
          return sellingPrice.toFixed(2);
        }
      } else {
        sellingPrice = productOriginalPriceAfterTax - afterTaxDiscountedPercentPrice;
        return sellingPrice.toFixed(2);
      }
    } else if (discountType === DISCOUNT_FIXED && fixedValue) {
      sellingPrice = productOriginalPriceAfterTax - fixedValue;
      return sellingPrice.toFixed(2);
    }
  }
}

export const getBrandId = () =>
  localStorage.getItem('user_info') ? JSON.parse(localStorage.getItem('user_info'))?.brand_id : null;

export const getName = () =>
  localStorage.getItem('user_info') ? JSON.parse(localStorage.getItem('user_info'))?.name : null;


export const numbersOnly = (e, value) => {
  let unicode = e.charCode ? e.charCode : e.keyCode
  if (unicode != 8) {
    if (!value) {
      if (unicode < 48 || unicode > 57)
        e.preventDefault();
      return false
    } else if (value === 'dot') {
      if (unicode < 46 || unicode > 57 || unicode === 47)
        e.preventDefault();
      return false
    }
  }
}

export const formatMoney = (price) => {
  if (!price) {
    return 0
  }
  if (typeof price === 'string') {
    price = parseFloat(price).toFixed(2).replace(/\.0+$/, '')
    return price.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }
  price = price.toFixed(2).replace(/\.0+$/, '')
  return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const checkPriceValid = (products, valueTaxType, valueFixed, valueFlat) => {
  const fixedPrice = parseFloat(valueFixed);
  const flatPrice = parseFloat(valueFlat);
  let itemDisable = [];
  if (products && products.length > 0 && (fixedPrice || flatPrice)) {
    products.map((item) => {
      if (valueTaxType === BEFORE_TAX) {
        if (fixedPrice && fixedPrice > parseFloat(item?.original_price_before_tax)) {
          itemDisable.push(item?.id)
        }
      } else {
        if (fixedPrice && fixedPrice > parseFloat(item?.original_price_after_tax)) {
          itemDisable.push(item?.id)
        } else if (flatPrice && flatPrice > parseFloat(item?.original_price_after_tax)) {
          itemDisable.push(item?.id)
        }
      }
    })
    return itemDisable;
  }
}

export const isJsonString = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const formatLocationCode = (data) => {
  return data.floormap?.code + "-" + data.code;
};

export const formatLocationLabel = (data, locale) => {
  return (
    (locale === "vi" ? data.floormap?.title : data.floormap?.title_en) +
    " / " +
    data.floormap?.code +
    "-" +
    data.code
  );
};

export const stringToSlug = (str) => {
  // remove accents
  var from =
    "àáãảạăằắẳẵặâầấẩẫậèéẻẽẹêềếểễệđùúủũụưừứửữựòóỏõọôồốổỗộơờớởỡợìíỉĩịäëïîöüûñçýỳỹỵỷ",
    to =
      "aaaaaaaaaaaaaaaaaeeeeeeeeeeeduuuuuuuuuuuoooooooooooooooooiiiiiaeiiouuncyyyyy";
  for (var i = 0, l = from.length; i < l; i++) {
    str = str.replace(RegExp(from[i], "gi"), to[i]);
  }

  str = str
    .toLowerCase()
    .trim()
    .replace(/[^a-z0-9\-]/g, "-")
    .replace(/-+/g, "-");

  return str;
};

export const getDataValue = (data, list) => {
  const dataObj = filter(list, dt => dt.value === data)

  return dataObj[0]?.label || data
}

export const convertDate = (date) => {
  return moment(date).format('hh:mm:ss DD/MM/YYYY')
}