import { Tabs } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { useParams } from 'react-router-dom'
import { bindParams, getEcPermissions, getPermissions } from '../../config/function'
import {
    CUSTOMER_LIST_ADDRESS,
    CUSTOMER_LIST_ORDER,
    MEMBER_DETAIL,
    MEMBER_REDEEMS,
    MEMBER_REFERRALS,
    OTHER_POINT_HISTORY,
    POINT_HISTORY,
    TRADE_HISTORY,
} from '../../config/path'

import './MemberTab.scss'
import { CustomerPermissions, SubadminRewardsPermissions } from 'config/permissionsList'
const { TabPane } = Tabs

const MemberTab = ({ defaultActiveKey, tabBarExtraContent }) => {
    const history = useHistory()
    const { t } = useTranslation('member')
    const { id } = useParams()
    const permissions = [...getEcPermissions(), ...getPermissions()];

    return (
        <Tabs
            className="member-detail--tab"
            defaultActiveKey={defaultActiveKey}
            tabBarExtraContent={tabBarExtraContent}
            onChange={(tab) => {
                if (tab === 'member_detail') {
                    history.push(bindParams(MEMBER_DETAIL, { id }))
                }
                if (tab === 'earn_history') {
                    history.push(bindParams(POINT_HISTORY, { id }))
                }
                if (tab === 'transaction_history') {
                    history.push(bindParams(TRADE_HISTORY, { id }))
                }
                if (tab === 'events') {
                    history.push(bindParams(OTHER_POINT_HISTORY, { id }))
                }
                if (tab === 'referral_list') {
                    history.push(bindParams(MEMBER_REFERRALS, { id }))
                }
                if (tab === 'exchange_history') {
                    history.push(bindParams(MEMBER_REDEEMS, { id }))
                }
                if (tab === 'list_order') {
                    history.push(bindParams(CUSTOMER_LIST_ORDER, { id }))
                }
                if (tab === 'list_address') {
                    history.push(bindParams(CUSTOMER_LIST_ADDRESS, { id }))
                }
            }}
        >
            {[CustomerPermissions.VIEW_CUSTOMER, SubadminRewardsPermissions.CUSTOMERS_VIEW].some(element => permissions.includes(element)) && <TabPane tab={t('member_detail')} key="member_detail" />}
            <TabPane tab={t('earn_history')} key="earn_history" />
            <TabPane tab={t('transaction_history')} key="transaction_history" />
            <TabPane tab={t('events')} key="events" />
            <TabPane tab={t('referral_list')} key="referral_list" />
            <TabPane tab={t('exchange_history')} key="exchange_history" />
            {[CustomerPermissions.LIST_CUSTOMER_ORDERS, SubadminRewardsPermissions.CUSTOMERS_VIEW].some(element => permissions.includes(element)) && <TabPane tab={t('Danh sách đơn hàng')} key="list_order" />}
            {[CustomerPermissions.LIST_CUSTOMER_ADDRESSES, SubadminRewardsPermissions.CUSTOMERS_VIEW].some(element => permissions.includes(element))&& <TabPane tab={t('Sổ địa chỉ')} key="list_address" />}
        </Tabs>
    )
}

export default MemberTab
