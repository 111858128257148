import React, { useEffect, useState } from "react";
import PrivateLayout from "../../layout/PrivateLayout";
import {
  Table,
  Layout,
  Button,
  Input,
  Form,
  Checkbox,
  Popover,
  Empty,
  notification,
  Popconfirm,
  message,
} from "antd";
import { Link, useHistory } from "react-router-dom";
import {
  CUSTOMER_DETAIL,
  CUSTOMER_PATH,
  MEMBERSHIP_REGISTER,
  MEMBER_DETAIL,
  MEMBER_UPDATE,
} from "../../config/path";
import qs from "qs";
import moment from "moment";
import { useTranslation } from "react-i18next";
import CustomerTab from "./CustomerTab";
import {
  bindParams,
  getCurrentLanguage,
  getEcPermissions,
  getPermissions,
  getRole,
  isJsonString,
  openNotification,
  setPageLimit,
} from "../../config/function";
import { KeyOutlined, SearchOutlined } from "@ant-design/icons";
import IconMoreInfo from "../../common/Icon/IconMoreInfo";
import IconEdit from "../../common/Icon/IconEdit";
import IconLock from "../../common/Icon/IconLock";
import FilterCustomer from "./items/FilterCustomer";
import useCustomerQuery, {
  filterToParams,
  initialFilter,
} from "../../hooks/useCustomerQuery";
import EmptyCommon from "../../common/EmptyCommon";
import IconEmptyTable from "../../common/Icon/IconEmptyTable";
import { getAxios, putAxios } from "../../Http";
import { API_BASE_LOYALTY, API_CUSTOMER, API_CUSTOMER_LOCK } from "../../config/endpointApi";
import IconViewDetal from "../../common/Icon/IconViewDetal";
import { ROLE_ADMIN, ROLE_MALL, STATUS, VI } from "../../config/const";
import useDownloadableRecord from "../../hooks/useDownloadableRecord";
import EnterMailExportCommon from "../../common/ExportMailCommon";
import "./Customer.scss";
import PreventReRegisterModal from "../../common//PreventModal";
import useCheckRole from "../../hooks/useCheckRole";
import { useQueryClient } from "react-query";
import ModalNoti from "./items/ModalNoti";
import ModalConfirmLockUnlock from "./items/ModalConfirmLockUnlock";
import { CustomerPermissions, SubadminRewardsPermissions } from "config/permissionsList";

const FormItem = Form.Item;

const CustomerList = () => {
  const history = useHistory();
  const { t } = useTranslation("member");
  const [isExporting, setIsExporting] = useState(false);
  const [form] = Form.useForm();
  const language = getCurrentLanguage();
  const role = getRole()
  const [visibleLocking, setVisibleLocking] = useState([]);
  const queryClient = useQueryClient();
  const [popoverVisible, setPopoverVisible] = useState();
  const {
    isLoading,
    isError,
    data: customers,
    setFilters,
    filters,
    isFetching,
    resetFilters,
  } = useCustomerQuery();
  const data = customers?.data;
  const total = customers?.pagination?.total || 0;
  const { data: downloadable } = useDownloadableRecord();
  const downloadableRecord = downloadable?.data || 0;
  const [isVisible, setIsVisible] = useState(false);
  const [accountRole, setAccountRole] = useState(undefined);
  const ROLE_EXPORT = useCheckRole('export');
  const ROLE_CRUD = useCheckRole('crud');
  const [confirmLoading, setConfirmLoading] = useState(false);
  const onSearch = (values) => {
    const filters = setFilters({
      keyword: values.keyword || "",
      page: 1,
      is_exact: !!values.is_exact,
    });
    pushHistory(filters);
    localStorage.setItem("customer_search_exact", !!values.is_exact);
  };

  const [modalConfirm, setModalConfirm] = useState(false);
  const [modalError, setModalError] = useState(false);
  const permissions = [...getEcPermissions(), ...getPermissions()];

  const togglePopoverVisibility = (index) => {
    console.log(index);
    const updatedVisibility = popoverVisible ? [...popoverVisible] : [];
    updatedVisibility[index] = !updatedVisibility[index];
    setPopoverVisible(updatedVisibility);
  };

  useEffect(() => {
    setFilters({
      ...filters,
      page: 1,
      is_exact: false,
    });
    pushHistory({});
    const stringUserInfo = localStorage.getItem("user_info");
    const userInfo = isJsonString(stringUserInfo)
      ? JSON.parse(stringUserInfo)
      : null;
    setAccountRole(userInfo?.rk_role_id);
  }, []);

  useEffect(() => {
    if (form.getFieldValue("is_exact") && data?.length === 1) {
      history.push(
        bindParams(
          data[0].loyalty_card_number !== null
            ? MEMBER_DETAIL
            : CUSTOMER_DETAIL,
          {
            id: data[0].customer_id,
            is_exact: true,
          }
        )
      );
    }
  }, [data, localStorage.getItem("customer_search_exact")]);

  const pushHistory = (params) => {
    history.push({
      pathname: CUSTOMER_PATH,
      search: qs.stringify(filterToParams(params)),
    });
  };

  const onChangePage = (page, limit) => {
    const params = {};
    if (filters.page !== page) {
      params.page = page;
    }

    if (filters.limit !== limit) {
      params.limit = limit;
      params.page = 1;
      setPageLimit("customers", limit);
    }

    const f = setFilters(params);
    pushHistory(f);
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0;
  };

  const onChangeFilter = (data) => {
    data.page = 1;
    const filters = setFilters(data);
    pushHistory(filters);
  };

  const onResetFilter = () => {
    resetFilters();
    form.setFieldsValue({ keyword: "" });
    pushHistory({});
  };

  const onFinishExport = (data) => {
    setIsExporting(true);
    getAxios(
      API_CUSTOMER,
      {
        ...filterToParams(filters),
        is_export: 1,
        email_receives: data?.email,
      },
      {
        paramsSerializer: (params) => {
          return qs.stringify(params);
        },
      }
    )
      .then((response) => {
        if (response?.status === 1) {
          notification.success({ message: response?.message });
        } else {
          if (response?.code === 422) {
            notification.error({
              message:
                t("message_error_export") +
                `(${response?.errors?.email_receives || "Server error"})`,
            });
          } else
            notification.error({
              message:
                t("message_error_export") +
                `(${response?.message || "Server error"})`,
            });
        }
      })
      .catch((error) => {
        notification.error({ message: t("message_error_export") });
      })
      .then(() => handleCancel());
  };

  const handleCancel = () => {
    setIsVisible(false);
    setIsExporting(false);
  };

  const exportCSV = () => {
    setIsExporting(true);
    getAxios(
      API_CUSTOMER,
      {
        ...filterToParams(filters),
        is_export: 1,
      },
      {
        paramsSerializer: (params) => {
          return qs.stringify(params);
        },
        responseType: "blob",
      }
    )
      .then((response) => {
        const blob = new Blob([response], {
          type: "application/vnd.ms-excel",
        });
        const downloadUrl = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = downloadUrl;
        a.download = `customers${moment().format("YYYY-MM-DD_HHmmss")}.xlsx`;
        document.body.appendChild(a);
        a.click();
        notification.success({
          message: t("message_export_successful"),
        });
      })
      .catch((error) => {
        notification.error({ message: t("message_error_export") });
      })
      .then(() => setIsExporting(false));
  };

  const exportMail = () => {
    setIsVisible(true);
  };

  const onCell = (record, rowIndex) => {
    return {
      onClick: (event) => {
        if (record.loyalty_card_number !== null)
          history.push(
            bindParams(MEMBER_DETAIL, {
              id: record.customer_id,
              is_exact: false,
            })
          );
        else {
          history.push(
            bindParams(CUSTOMER_DETAIL, {
              id: record.customer_id,
              is_exact: false,
            })
          );
        }
      },
    };
  };

  const handleToggleLockAccount = async (record) => {
    let params = {
      is_banned: record?.is_banned === STATUS.ACTIVE ? STATUS.INACTIVE : STATUS.ACTIVE,
    };
    setConfirmLoading(true);
    try {
      const res = await putAxios(
        bindParams(API_CUSTOMER_LOCK, { id: record.customer_id }),
        params
      );
      if (res.code === 200 && !res?.message) {
        notification.success({
          description:record?.is_banned === STATUS.INACTIVE ? t('msg_lock_success') : t('msg_unlock_success'),
        });
        queryClient.invalidateQueries("customers");
      } else {
        notification.error({
          description: res?.message || t("common:edit_fail_msg"),
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setVisibleLocking([]);
      setConfirmLoading(false);
      setPopoverVisible([])
    }
  };

  const columns = [
    {
      title: "#",
      dataIndex: "key",
      key: "key",
      width: "5%",
      render: (value, data, index) => {
        return (Number(filters.page) - 1) * Number(filters.limit) + index + 1;
      },
      onCell,
    },
    {
      title: t("name"),
      dataIndex: "full_name",
      width: "10%",
      ellipsis: "true",
      align: "left",
      onCell,
    },
    {
      title: t("phone"),
      dataIndex: "phone",
      width: "10%",
      onCell,
    },
    {
      title: t("account_type"),
      width: "10%",
      render: (record) => {
        return (
          <>
            {record.fb_id ? (
              !record.gg_person_id ? (
                <>Facebook</>
              ) : (
                <>Facebook, Google</>
              )
            ) : record.gg_person_id ? (
              <>Google</>
            ) : (
              <>Email</>
            )}
          </>
        );
      },
      onCell,
    },
    {
      title: t("email"),
      dataIndex: "email",
      width: "15%",
      ellipsis: "true",
      key: "email",
      onCell,
    },
    {
      title: t("TTTM đăng ký"),
      width: "18%",
      ellipsis: "true",
      dataIndex: "first_mall",
      render: (first_mall) =>
        language === VI ? first_mall?.name : first_mall?.name_en,
      onCell,
    },
    {
      title: t("TTTM hiện tại"),
      width: "18%",
      ellipsis: "true",
      dataIndex: "mall",
      render: (mall) => (language === VI ? mall?.name : mall?.name_en),
      onCell,
    },
    {
      title: t("member"),
      dataIndex: "loyalty_card_number",
      align: "center",
      width: "10%",
      key: "loyalty_card_number",
      render: (loyalty_card_number) => {
        if (loyalty_card_number !== null) {
          return (
            <Checkbox className="customer_list__member" checked color="pink" />
          );
        }
      },
      onCell,
    },
    {
      title: t("active_status"),
      dataIndex: "loyalty_card_number",
      align: "center",
      width: "10%",
      key: "loyalty_card_number",
      render: (val, record) => <>{record?.is_deleted === 1 ? t('deleted') : record?.is_banned === 1 ? t('banned_status') : t('normal_status')}</>,
      onCell,
    },
    {
      title: "",
      dataIndex: "loyalty_card_number",
      width: "5%",
      render: (loyalty_card_number, record,index) => {
        var id = record.customer_id;
        if (loyalty_card_number == null) {
          let popupContent = (
            <>
              <ul className="custom-popup-menu">
                {[CustomerPermissions.VIEW_CUSTOMER, SubadminRewardsPermissions.CUSTOMERS_VIEW].some(element => permissions.includes(element)) && <li>
                  <Link
                    to={bindParams(CUSTOMER_DETAIL, {
                      id: id,
                      is_exact: false,
                    })}
                  >
                    <div className="popupMore">
                      <IconViewDetal />
                      <span className="popupMore-span">
                        {t("Xem chi tiết")}
                      </span>
                    </div>
                  </Link>
                </li>}
               {record?.is_deleted !== 1 && <li>
                  {(ROLE_CRUD) ? null : <Link to={bindParams(MEMBERSHIP_REGISTER, { id: id })}>
                    <div className="popupMore">
                      <IconEdit className="popupMore-icon" />
                      <span className="popupMore-span">
                        {t("Tạo thành viên")}
                      </span>
                    </div>
                  </Link>}
                </li>}
                <li>
                  {[ROLE_ADMIN, ROLE_MALL].includes(role) &&  <Popconfirm
          title={
            record.is_banned == STATUS.INACTIVE
              ? t("common:msg_lock_staff")
              : t("common:msg_unlock_staff")
          }
          visible={visibleLocking.length > 0 && visibleLocking[0] === record.id}
          onConfirm={(e) => {
            e.stopPropagation();
            handleToggleLockAccount(record);
          }}
          okButtonProps={{ loading: confirmLoading }}
          onCancel={(e) => {
            e.stopPropagation();
            setVisibleLocking([]);
          }}
        >
         {record?.is_deleted !== 1 && <div
            className="d-flex align-items-center pointer"
            onClick={(e) => {
              e.stopPropagation();
              setVisibleLocking([record.id]);
            }}
          >
            <IconLock />
            <div className="pl-1">
              {record.is_banned !== STATUS.ACTIVE ? t("lock") : t("unlock")}
            </div>
          </div>}
        </Popconfirm>}
                </li>
              </ul>
            </>
          );
          return (
            <Popover
             placement="bottom" content={popupContent} trigger="click"
             open={(popoverVisible && popoverVisible[index]) || false}
             >
              <div className="moreIcon"
                onClick={(e) => {
                e.stopPropagation();
                togglePopoverVisibility(index);
              }}
              >
                <IconMoreInfo />
              </div>
            </Popover>
          );
        } else {
          let popupContent = (
            <ul className="custom-popup-menu">
              {[CustomerPermissions.VIEW_CUSTOMER, SubadminRewardsPermissions.CUSTOMERS_VIEW].some(element => permissions.includes(element)) && <li>
                <Link
                  to={bindParams(MEMBER_DETAIL, { id: id, is_exact: false })}
                >
                  <div className="popupMore">
                    <IconViewDetal />
                    <span className="popupMore-span">{t("Xem chi tiết")}</span>
                  </div>
                </Link>
              </li>}
              { ([CustomerPermissions.EDIT_CUSTOMER, SubadminRewardsPermissions.CUSTOMERS_CRUD].some(element => permissions.includes(element)) && record?.is_deleted !==1) && <li>
                {ROLE_CRUD ? null : <Link to={bindParams(MEMBER_UPDATE, { id: id })}>
                  <div className="popupMore">
                    <IconEdit className="popupMore-icon" />
                    <span className="popupMore-span">{t("Chỉnh sửa")}</span>
                  </div>
                </Link>}
              </li>}
              <li>
                  {[ROLE_ADMIN, ROLE_MALL].includes(role) && <Popconfirm
          title={
            record.is_banned == STATUS.INACTIVE
              ? t("common:msg_lock_staff")
              : t("common:msg_unlock_staff")
          }
          visible={visibleLocking.length > 0 && visibleLocking[0] === record.id}
          onConfirm={(e) => {
            e.stopPropagation();
            handleToggleLockAccount(record);
          }}
          okButtonProps={{ loading: confirmLoading }}
          onCancel={(e) => {
            e.stopPropagation();
            setVisibleLocking([]);
          }}
        >
          {record.is_deleted !== 1 && <div
            className="d-flex align-items-center pointer"
            onClick={(e) => {
              e.stopPropagation();
              setVisibleLocking([record.id]);
            }}
          >
            <IconLock />
            <div className="pl-1">
              {record.is_banned !== STATUS.ACTIVE ? t("lock") : t("unlock")}
            </div>
          </div>}
        </Popconfirm>}
                </li>
            </ul>
          );
          return (
            <Popover
             placement="bottom" content={popupContent} trigger="click"
             open={(popoverVisible && popoverVisible[index]) || false}
             >
              <div className="moreIcon"
              onClick={(e) => {
                e.stopPropagation();
                togglePopoverVisibility(index);
              }}
              >
                <IconMoreInfo />
              </div>
            </Popover>
          );
        }
      },
    },
  ];

  const [isOpenModal, setIsOpenModal] = useState(false);
  const openPreventReRegisterModal = () => {
    setIsOpenModal(true);
  };
  const [isPreventLoading, setPreventLoading] = useState(false);
  const preventModalSubmit = (values) => {
    setPreventLoading(true);
    putAxios(`${API_BASE_LOYALTY}/malls/update-config-delete-date`, values)
      .then((res) => {
        if (res.code === 200) {
          setIsOpenModal(false);
          openNotification(t("Thành công"), "success");
        } else {
          openNotification(t(res.message), "error");
        }
      })
      .catch((error) => {
        openNotification(error.message, "error");
      })
      .finally(() => {
        setPreventLoading(false);
      });
  };
  const closePreventModal = () => {
    setIsOpenModal(false);
  };
  const tabBarExtraContent = (
    <div
      style={{
        display: "flex",
        gap: "20px",
      }}
    >
      {accountRole === ROLE_ADMIN && (
        <Button
          size="large"
          className="btn btn-common"
          onClick={openPreventReRegisterModal}
          loading={isExporting || isFetching}
        >
          {t("Chặn đăng kí lại")}
        </Button>
      )}
      <Button
        disabled={ROLE_EXPORT}
        size="large"
        className="btn btn-common"
        onClick={total <= downloadableRecord ? exportCSV : exportMail}
        loading={isExporting || isFetching}
      >
        {t("Xuất ra XLSX")}
      </Button>
    </div>
  );

  const locale = {
    emptyText: (
      <Empty imageStyle={{ display: "none" }} description="">
        <div className="customer_list--empty">
          <h2>{t("Không tìm thấy kết quả nào")}</h2>
          <p>{t("Bạn thử tắt điều kiện lọc và tìm lại nhé?")}</p>
          <div className="customer_list--empty-icon">
            <IconEmptyTable />
          </div>

          <Button
            size="large"
            className="btn btn-common"
            onClick={onResetFilter}
          >
            {t("Xóa bộ lọc")}
          </Button>
        </div>
      </Empty>
    ),
  };

  return (
    <PrivateLayout breadcrumbs={[t("customer_title")]}>
      <Layout className="customer_list customer_list-wrap">
        <CustomerTab
          defaultActiveKey={"customer_list"}
          tabBarExtraContent={tabBarExtraContent}
        />
        <div className="site-layout-background">
          <div className="customer_list--head">
            <div className="customer_list--title">
              {t("Danh sách Khách hàng")}
            </div>
            <Form
              className="tabbar-form"
              onFinish={onSearch}
              initialValues={{
                keyword: filters.keyword,
                is_exact: filters.is_exact,
              }}
              layout="inline"
              form={form}
            >
              <Form.Item
                name="is_exact"
                valuePropName={"checked"}
                wrapperCol={{ offset: 0, span: 24 }}
              >
                <Checkbox>{t("Tìm kiếm chính xác")}</Checkbox>
              </Form.Item>
              <FormItem name={"keyword"}>
                <Input
                  placeholder={t("Tên, số điện thoại, email")}
                  size={"large"}
                  className="Point-History-Filter-form-input-search"
                />
              </FormItem>
              <Button
                loading={isLoading || isFetching}
                icon={<SearchOutlined />}
                className="btn-common search-btn"
                htmlType="submit"
                size={"large"}
              />
            </Form>
          </div>
          <FilterCustomer onChangeFilter={onChangeFilter} filters={filters} />
          {isError ? (
            <EmptyCommon text={"Error"} />
          ) : (
            <Table
              locale={locale}
              className="custom-table"
              columns={columns}
              dataSource={data}
              scroll={{ x: 1400 }}
              loading={isLoading || isFetching}
              rowKey="customer_id"
              pagination={{
                total: total,
                onChange: onChangePage,
                pageSizeOptions: [10, 20, 30],
                defaultPageSize: filters.limit,
                current: filters.page,
                showSizeChanger: true,
                showQuickJumper: true,
                className:
                  "common-table-pagination ant-table-pagination ant-table-pagination-right",
              }}
            />
          )}
        </div>
        <EnterMailExportCommon
          isExport={isExporting}
          visible={isVisible}
          onFinish={onFinishExport}
          handleCancel={handleCancel}
        />
        <PreventReRegisterModal
          isOpenModal={isOpenModal}
          preventModalSubmit={preventModalSubmit}
          isPreventLoading={isPreventLoading}
          closePreventModal={closePreventModal}
        />
        {modalError && <ModalNoti
          visible={modalError}
          onCancel={() => {
            setModalError(false);
          }}
          width={414}
          message={t('msg_error')}
        />}
        {modalConfirm && <ModalConfirmLockUnlock
          visible={modalConfirm}
          onCancel={() => {
            setModalConfirm(false);
          }}
          width={580}
          message={true ? t('msg_confirm_lock') : t('msg_confirm_unlock')}
        />}
      </Layout>
    </PrivateLayout>
  );
};

export default CustomerList;
