import { getAxios } from '../Http'
import { DOWNLOADABLE_RECORD } from '../config/endpointApi'
import { useQuery } from 'react-query'

const getDownloadableRecord = async () => {
    return await getAxios(DOWNLOADABLE_RECORD)
}

function useDownloadableRecord() {
    return useQuery(['download_record'], () => getDownloadableRecord(), {
        refetchOnWindowFocus: false,
    })
}

export default useDownloadableRecord
