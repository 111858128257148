import React from "react";
import { Form } from "antd";
import { checkIsJoinEc, getEcPermissions, getPermissions, getRole } from "config/function";
import { SubadminTenantPermissions, TenantPermissions } from "config/permissionsList";
import {
  TENANT_DETAIL_PATH,
  TENANT_PATH,
  TENANT_TRADE_LIST,
  TENANT_LIST_COIN,
  TENANT_REPORT_PATH,
  TENANT_SETTING_DELIVERY,
  TENANT_SETTING_TAX,
  TENANT_SETTING_BANNER,
  TENANT_SETTING_COLOR,
  TENANT_SETTING_WAREHOUSE,
  TENANT_SETTING_TOS,
  TENANT_SETTING_OPERATING_TIME,
} from "config/path";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { ROLE_HEAD_SHOP, ROLE_STAFF } from "config/const";
import "./styleTenantTab.scss";

const TenantTabData = (props) => {
  const { t } = useTranslation();
  const getPermission = [...getEcPermissions(), ...getPermissions()];
  const role = getRole()
  const { mall_id, tenant_id } = useParams();
  const join_ec = checkIsJoinEc()

  const permissions = {
    isTenantDetail: [TenantPermissions.VIEW_TENANT_DETAIL, ...SubadminTenantPermissions.ALL].some(element => getPermission.includes(element)),
    isTenantDelivery: [TenantPermissions.VIEW_TENANT_DELIVERY, ...SubadminTenantPermissions.ALL].some(element => getPermission.includes(element)),
    isTenantAccountTax: [TenantPermissions.CREATE_ACCOUNTANT_TAX_REPORT, ...SubadminTenantPermissions.ALL].some(element => getPermission.includes(element)),
    isTenantTax: [TenantPermissions.CREATE_TENANT_TAX_REPORT, ...SubadminTenantPermissions.ALL].some(element => getPermission.includes(element)),
    isTenantViewTax: [TenantPermissions.VIEW_TENANT_TAX_REPORT, ...SubadminTenantPermissions.ALL].some(element => getPermission.includes(element)),
    isTenantStaffList: [TenantPermissions.MANAGE_ACCOUNT, ...SubadminTenantPermissions.ALL].some(element => getPermission.includes(element)),
    isTenantReport: [TenantPermissions.VIEW_TENANT_REPORT, ...SubadminTenantPermissions.ALL].some(element => getPermission.includes(element)),
    isTenantTrade: [TenantPermissions.LIST_TENANT_TRANSACTIONS, ...SubadminTenantPermissions.ALL].some(element => getPermission.includes(element)),
    isTenantWareHouse: [TenantPermissions.VIEW_WAREHOUSE_ADDRESS, ...SubadminTenantPermissions.ALL].some(element => getPermission.includes(element)),
    isTenantColor: [TenantPermissions.MANAGE_TENANT_COLOR, ...SubadminTenantPermissions.ALL].some(element => getPermission.includes(element)),
    isTenantBanner: [TenantPermissions.MANAGE_TENANT_BANNER, ...SubadminTenantPermissions.ALL].some(element => getPermission.includes(element)),
    isTenantCoin: [TenantPermissions.VIEW_TENANT_TRANSACTIONS_REPORT, ...SubadminTenantPermissions.ALL].some(element => getPermission.includes(element)),
    isTenantCreateTos: [TenantPermissions.CREATE_TENANT_TOS, SubadminTenantPermissions.TENANTS_CRUD].some(element => getPermission.includes(element)),
    isTenantViewTos: [TenantPermissions.VIEW_TENANT_TOS, ...SubadminTenantPermissions.ALL].some(element => getPermission.includes(element)),
    isTenantOperatingTime: [TenantPermissions.VIEW_CREATE_UPDATE_ACTIVE_TIME, ...SubadminTenantPermissions.ALL].some(element => getPermission.includes(element)),
  };

  const tabValid = [
    {
      key: "tenant_details",
      label: t("common:info_store"),
      visible: permissions.isTenantDetail,
      path: TENANT_DETAIL_PATH,
    },
    {
      key: "tenant_shipping",
      label: t("common:setting_delivery"),
      visible: permissions.isTenantDelivery,
      path: TENANT_SETTING_DELIVERY,
    },
    {
      key: "tenant_tax",
      label: t("common:tax_information"),

      visible:
        permissions.isTenantAccountTax ||
        permissions.isTenantTax ||
        permissions.isTenantViewTax,
      path: TENANT_SETTING_TAX,
    },
    {
      key: "staff_list",
      label: t("common:staff_list"),
      visible: permissions.isTenantStaffList,
      path: `${TENANT_PATH}/${mall_id}/${tenant_id}/staffs`,
    },
    {
      key: "tenant_report",
      label: t("common:tenant_report"),
      visible: permissions.isTenantReport,
      path: TENANT_REPORT_PATH,
    },
    {
      key: "list_trade",
      label: t("common:list_trade"),
      visible: permissions.isTenantTrade,
      path: TENANT_TRADE_LIST,
    },
    {
      key: "set_up_warehouse",
      label: t("common:set_up_warehouse"),
      visible:(([ROLE_HEAD_SHOP, ROLE_STAFF].includes(role) ? join_ec : true) && permissions.isTenantWareHouse),
      path: TENANT_SETTING_WAREHOUSE,
    },
    {
      key: "set_up_colors",
      label: t("common:set_up_colors"),
      visible: (([ROLE_HEAD_SHOP, ROLE_STAFF].includes(role) ? join_ec : true) && permissions.isTenantColor),
      path: TENANT_SETTING_COLOR,
    },
    {
      key: "set_up_banners",
      label: t("common:set_up_banners"),
      visible: (([ROLE_HEAD_SHOP, ROLE_STAFF].includes(role) ? join_ec : true) && permissions.isTenantBanner),
      path: TENANT_SETTING_BANNER,
    },
    {
      key: "coin",
      label: t("common:transaction_reports"),
      visible: (([ROLE_HEAD_SHOP, ROLE_STAFF].includes(role) ? join_ec : true) && permissions.isTenantCoin),
      path: TENANT_LIST_COIN,
    },
    {
      key: "tos_fee",
      label: t("common:tos_fee"),
      visible: (([ROLE_HEAD_SHOP, ROLE_STAFF].includes(role) ? join_ec : true) && (permissions.isTenantCreateTos || permissions.isTenantViewTos)),
      path: TENANT_SETTING_TOS,
    },
    {
      key: "set_up_operating_time",
      label: <div className="left-aligned-text">{t("common:set_up_operating_time")}</div> ,
      visible:(([ROLE_HEAD_SHOP, ROLE_STAFF].includes(role) ? join_ec : true) && permissions.isTenantOperatingTime),
      path: TENANT_SETTING_OPERATING_TIME,
    },
  ].filter((item) => item?.visible);


  return tabValid;
};

export default TenantTabData;
