import PrivateLayout from "layout/PrivateLayout";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";
import { Empty, Pagination, Spin } from "antd";
import NotificationItem from "../notificationItem";
import clsx from "clsx";
import { bindParams } from "config/function";
import { useHistory } from "react-router-dom";
import {
    PER_PAGE,
    useNotification,
} from "context/notifications/NotificationContext";
import { convertDate } from "config/function";

const defaultStyles = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "500px",
};

function NotificationList() {
    const history = useHistory();

    const { t } = useTranslation("notification");
    const {
        loadingNotification,
        page,
        setPage,
        total,
        notifications,
        readAllNotification,
        handleViewDetailNotification,
    } = useNotification();

    const handleReadAllNotification = () => {
        readAllNotification();
    };

    if (notifications.length <= 0) {
        return (
            <PrivateLayout breadcrumbs={[t("notification")]}>
                <div style={{ ...defaultStyles }}>
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                </div>
            </PrivateLayout>
        );
    }

    const renderPagination = () => {
        return (
            <div className={clsx(styles.pagination)}>
                <Pagination
                    current={page}
                    showTotal={(total) => `${t("all")} ${total} ${t("notification").toLowerCase()}`}
                    onChange={(page) => setPage(page)}
                    total={total}
                    pageSize={PER_PAGE}
                    showSizeChanger={false}
                />
            </div>
        );
    };

    return (
        <PrivateLayout breadcrumbs={[t("notification")]}>
            {loadingNotification ? (
                <div style={{ ...defaultStyles }}>
                    <Spin />
                </div>
            ) : (
                <div className={styles.wrapper_notification}>
                    <div className={styles.header} onClick={handleReadAllNotification}>
                        <p>{t("mark_read_all")}</p>
                    </div>

                    <div className={styles.wrapper_notifications}>
                        {notifications.map((notification, index) => (
                            <NotificationItem
                                key={index}
                                isActivated={!notification.is_read}
                                title={notification?.title}
                                des={notification?.content}
                                img={""}
                                time={convertDate(notification.created_at)}
                                viewDetailNotification={async () => {
                                    const { res, url } = await handleViewDetailNotification(
                                        notification
                                    );

                                    if (res.code === 200 && url.path && url.params) {
                                        history.push(bindParams(url.path, url.params));
                                    }
                                }}
                            />
                        ))}
                    </div>

                    {renderPagination()}
                </div>
            )}
        </PrivateLayout>
    );
}

export default NotificationList;
