import React from "react";

const IconBag = () => {
  return (
    <svg
      width="22"
      height="24"
      viewBox="0 0 22 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.5463 23.2111L19.7222 5.8405C19.6839 5.47686 19.3774 5.20073 19.0117 5.20073H15.5833V4.41906C15.5833 1.98248 13.6008 0 11.1642 0C8.72743 0 6.74495 1.98248 6.74495 4.41906V5.20073H3.31649C2.95083 5.20073 2.64432 5.47686 2.60605 5.8405L0.781954 23.2111C0.760897 23.4124 0.826266 23.6132 0.96158 23.7637C1.09708 23.9141 1.29007 24 1.4924 24H20.8356C21.0381 24 21.2311 23.9141 21.3664 23.7637C21.5021 23.6132 21.5673 23.4124 21.5463 23.2111ZM8.17354 4.41906C8.17354 2.7702 9.51515 1.42859 11.1642 1.42859C12.8131 1.42859 14.1547 2.7702 14.1547 4.41906V5.20073H8.17354V4.41906ZM2.28561 22.5714L3.95974 6.62932H6.74495V8.20347C6.74495 8.59788 7.06484 8.91777 7.45925 8.91777C7.85366 8.91777 8.17354 8.59788 8.17354 8.20347V6.62932H14.1547V8.20347C14.1547 8.59788 14.4746 8.91777 14.869 8.91777C15.2634 8.91777 15.5833 8.59788 15.5833 8.20347V6.62932H18.3685L20.0426 22.5714H2.28561Z"
        fill="#828282"
      />
    </svg>
  );
};

export default IconBag;
