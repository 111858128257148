import React from 'react';
import PropTypes from 'prop-types';
import logoBinhduong from "../../assets/img/logo-aeonmall-tanphu.png"
const IconTanPhu = props => {
    const {onClick} = props
    return <img src={logoBinhduong} alt={''} onClick={onClick}/>
};

IconTanPhu.defaultProps = {
}

IconTanPhu.propTypes = {
    onClick: PropTypes.func
};

export default IconTanPhu;