import React, { createContext, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useParams } from 'react-router-dom'
import { useQuery } from "react-query"
import { Form } from 'antd'
import qs from 'qs'
import { DEFAULT_LANGUAGE, EN } from '../../config/const'
import { getAxios } from '../../Http'
import { API_EARNING_RULE } from '../../config/endpointApi'
import { bindParams, getCurrentLanguage } from '../../config/function'
import { CHALLENGE_CREATE, CHALLENGE_EDIT, ESURVEY_CREATE_PATH, ESURVEY_DETAIL_PATH } from '../../config/path'

export const ChallengeFormContext = createContext()

export const useChallengeForm = () => useContext(ChallengeFormContext)

const ChallengeFormContextProvider = ({ children }) => {
    const { id } = useParams()
    const { pathname } = useLocation()

    const [form] = Form.useForm()
    const { t } = useTranslation("challenge")

    const [languages, setLanguages] = useState([])
    const [currentLanguage, setCurrentLanguage] = useState(DEFAULT_LANGUAGE)

    const [campaigns, setCampaigns] = useState([])
    const [selectedCampaigns, setSelectedCampaigns] = useState([])

    const [targetId, setTargetId] = useState(0)
    const [selectedTargets, setSelectedTargets] = useState([{ targetId, countChildren: 0 }])

    const [rewardTypes, setRewardTypes] = useState({})

    const [deletedTargets, setDeletedTargets] = useState([]) // to pass into API
    const [deletedParentTargets, setDeletedParentTargets] = useState([]) // array
    const [deletedTeamTargets, setDeletedTeamTargets] = useState([])// array
    const [deletedSubTargets, setDeletedSubTargets] = useState({})  // object

    const updateDefault = {
        previewVisible: false,
        previewImage: "",
        previewTitle: "",
        isFileValidFormat: true,
        fileList: [],
    }
    const [uploadState, setUploadState] = useState(updateDefault)
    const [deletedFile, setDeletedFile] = useState(0)

    const [selectedTargetVouchers, setSelectedTargetVouchers] = useState([])
    const [selectedSubVouchers, setSelectedSubVouchers] = useState([])

    const fetchEarningRules = async () => {
        const { data } = await getAxios(API_EARNING_RULE,
            { type: 1 },    // get list earning rules of custom events & no pagination
            {
                paramsSerializer: (params) => qs.stringify(params),
            })
        return data
    }

    const { data = [] } = useQuery(['earning-rules'], fetchEarningRules, {
        enabled: [CHALLENGE_CREATE,ESURVEY_CREATE_PATH, ESURVEY_DETAIL_PATH,bindParams(ESURVEY_DETAIL_PATH, {id}), bindParams(CHALLENGE_EDIT, {id})]?.includes(pathname),
        refetchOnWindowFocus: false,
        keepPreviousData: false,
    })
    const listEarningRules = data?.map(item => ({
        ...item,
        label: (
            <p
                className='w-100 d-flex justify-space-between align-items-center m-0'
                title={item?.translations[currentLanguage]?.name +
                    ` (+${item?.value} ${(item?.value > 1 && getCurrentLanguage() === EN) ? t('coin') + 's' : t('coin')})`}
            >
                <span>{item?.translations[currentLanguage]?.name}</span>
                <i>
                    {`(+${item?.value} ${(item?.value > 1 && getCurrentLanguage() === EN) ? t('coin') + 's' : t('coin')})`}
                </i>
            </p>
        ),
        value: item?.id,
        coinsAmount: item?.value
    }))

    return (
        <ChallengeFormContext.Provider
            value={{
                form, languages, setLanguages,
                currentLanguage, setCurrentLanguage,
                targetId, setTargetId,
                selectedTargets, setSelectedTargets,
                deletedTargets, setDeletedTargets,
                rewardTypes, setRewardTypes,
                uploadState, setUploadState,
                deletedFile, setDeletedFile,
                campaigns, setCampaigns,
                selectedCampaigns, setSelectedCampaigns,
                listEarningRules,
                selectedTargetVouchers, setSelectedTargetVouchers,
                selectedSubVouchers, setSelectedSubVouchers,
                deletedTeamTargets, setDeletedTeamTargets,
                deletedSubTargets, setDeletedSubTargets,
                deletedParentTargets, setDeletedParentTargets,
            }}
        >
            {children}
        </ChallengeFormContext.Provider>
    )
}

export default ChallengeFormContextProvider
